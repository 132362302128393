import { CountValueFacet } from "./count-value-facet.model";
import { CountValueFacetItem } from "./facet.model";

export class RadioFacet extends CountValueFacet {
  selectedValue: string = "All";
  constructor(name: string, facets: CountValueFacetItem[]) {
    super(name, facets);
    this.facets.unshift({ value: "All" } as CountValueFacetItem);
  }

  get selected(): boolean {
    return this.selectedValue !== "All";
  }

  clear = () => {
    this.facets = [];
    this.uncheck();
  };

  uncheck = () => {
    this.selectedValue = "All";
  };

  update = (facets: CountValueFacetItem[]) => {
    // if this is selected do not update
    if (this.selected) {
      return;
    }

    this.facets = facets;
    if (!this.facets.find(d => d.value === "All")) {
      this.facets.unshift({ value: "All", label: "All" } as CountValueFacetItem);
    }
  };

  filter(urlParams: URLSearchParams): string {
    const param = urlParams.get(this.name);
    if (!param || this.facets.length === 0) {
      this.uncheck();
      return null;
    }
    this.selectedValue = param;

    return this.selectedValue === "All" ? null : `${this.name} eq '${this.selectedValue}'`;
  }

  toQueryParam = (): string => {
    if (!this.selected) {
      return undefined;
    }

    return this.selectedValue;
  };
}
