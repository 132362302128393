<div class="my-2" rt="edit-entity-header">
  <div class="d-md-flex align-items-center" [class.mb-3]="dateCreated">
    <h3 class="mr-auto" rt="entity-header-title">
      {{ title }} <span class="text-grey">{{ subTitle }}</span>
    </h3>
    <a *ngIf="externalUrl != null" class="mr-4" [href]="externalUrl" target="_blank">{{ externalText }} <i class="icon-listing ml-1"></i></a>

    <div rt="entity-header-secondary-label">
      <div *ngIf="secondaryLabelText" class="text-grey font-weight-light mt-2 mr-2 secondary-call-out">
        {{ secondaryLabelText }}
      </div>
    </div>

    <div rt="entity-header-status" class="mt-2 mt-md-0">
      <common-status-label-dropdown
        *ngIf="dropDownOrNot"
        [status]="status"
        (statusChange)="statusChanged($event)"
        [dropDownItems]="availableStatusList"
      ></common-status-label-dropdown>
      <common-status-label *ngIf="!dropDownOrNot" labelStyle="status-pill" [status]="status"> </common-status-label>
    </div>
  </div>
  <div class="d-flex align-items-center">
    <div class="text-grey paragraph-text ml-auto" rt="entity-header-date-created" *ngIf="dateCreated">Date created: {{ dateCreated | date }}</div>
  </div>
</div>
