<div class="card mb-4 box-shadow border-0" rt="machine-card">
  <div class="card-image">
    <ngb-carousel *ngIf="machineImages.length" (slide)="slideEvent($event)">
      <ng-template ngbSlide *ngFor="let image of machineImages">
        <a
          [routerLink]="['/equipment-rental', machine.primaryType, machine.make, machine.model]"
          [queryParams]="{ id: machine.id }"
          [commonEventTracker]="MarketTrackingEvents.SearchResult.Click"
          [properties]="{ inventoryId: machine.id }"
        >
          <img class="card-img-top" *ngIf="imagesToLoad.includes(image)" [src]="image" />
        </a>
      </ng-template>
    </ngb-carousel>

    <div class="top-owner-container" *ngIf="machine.isTopOwner">
      <common-top-owner [showTopOwner]="machine.isTopOwner"></common-top-owner>
    </div>
    <div class="bottom-right-container">
      <div class="left-triangle"></div>
      <div class="price-container">
        <h2 class="price"><span class="currency-symbol align-text-top">$</span>{{ machine.rentalRate | thousandSuffix: 1 }}</h2>
        <p class="per-month-text">per month</p>
      </div>
    </div>
  </div>
  <a
    class="text-dark"
    [routerLink]="['/equipment-rental', machine.primaryType, machine.make, machine.model]"
    [queryParams]="{ id: machine.id }"
    [commonEventTracker]="MarketTrackingEvents.SearchResult.Click"
    [properties]="{ inventoryId: machine.id }"
  >
    <div class="card-body">
      <div class="d-flex align-items-baseline">
        <h2 class="machine-title mr-2">
          {{ machine.make }}
        </h2>
        <div class="machine-model teko">{{ machine.model }}</div>
      </div>

      <div class="machine-sub-title">{{ machine.year }} {{ machine.primaryType }}</div>

      <!-- <app-star-rating [numberOfStars]="machine.rating" [disableTooltip]="true"></app-star-rating> -->
      <p class="hours mt-2">
        {{ machine?.location | location: "cityState":false }}
        <span class="mx-1">|</span>
        {{ machine.usage | number: "1.0-0" }} {{ machine.usageAbbreviation }}
      </p>
    </div>
  </a>
  <common-machine-card-attachments [attachments]="machine.relatedAttachments"></common-machine-card-attachments>
</div>
