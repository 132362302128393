import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { AuthenticationService } from "./authentication.service";
import { EnvironmentConfig } from "../models/environment-config.model";
import { ToastrService } from "ngx-toastr";
import { LogService } from "./log.service";

declare var environment: EnvironmentConfig;

@Injectable({
  providedIn: "root",
})
export class ErrorsHandler implements ErrorHandler {
  constructor(private injector: Injector, private logService: LogService) {}

  private get authenticationService() {
    return this.injector.get(AuthenticationService);
  }

  handleError(error: Error, componentOrServiceName: string = "") {
    if (error instanceof HttpErrorResponse) {
      // Server or connection error happened
      if (!navigator.onLine) {
        console.error("you are offline my dude");
        return;
      }

      // redirect user to login if he is unauthenticated
      if (error.status === 401) {
        this.authenticationService.login();
        return;
      }

      const validationErrors = [];
      for (const fieldName in error.error) {
        if (error.error.hasOwnProperty(fieldName) && Array.isArray(error.error[fieldName])) {
          validationErrors.push(error.error[fieldName][0]);
        }
      }

      if (validationErrors.length === 0 && typeof error.error === "string") {
        validationErrors.push(error.error);
      }

      for (const fieldName in error.error?.errors) {
        if (error.error.errors.hasOwnProperty(fieldName) && Array.isArray(error.error.errors[fieldName])) {
          validationErrors.push(error.error.errors[fieldName][0]);
        }
      }

      const toastrService = this.injector.get(ToastrService);
      if (error.status === 403) {
        toastrService.error("You are not authorized to access this resource. Contact your platform administrator.", "", { enableHtml: true });
        return;
      }

      // for admin we want to always show errors even if they are ugly
      if (environment.clientId === "rubbl.admin" || environment.clientId === "rubbl.transportation") {
        toastrService.error(validationErrors.join("<br/><br/>"), `${error.status} - ${error.statusText}`, {
          enableHtml: true,
        });
      }
    }

    this.logService.error(componentOrServiceName, error);
  }
}
