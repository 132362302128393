import { CountValueFacetItem, Facet } from "./facet.model";
import { Location } from "../../../core/core";
import { DropDownItem } from "../../../forms/forms";

export class LocationFacet extends Facet {
  location: Location;

  selectedRadius: string = "1000";

  radiusOptions: DropDownItem[] = [
    {
      label: "Within 50 miles",
      value: "50",
      action: () => {
        this.selectedRadius = "50";
      },
    },
    {
      label: "Within 100 miles",
      value: "100",
      action: () => {
        this.selectedRadius = "100";
      },
    },
    {
      label: "Within 250 miles",
      value: "250",
      action: () => {
        this.selectedRadius = "250";
      },
    },
    {
      label: "Within 500 miles",
      value: "500",
      action: () => {
        this.selectedRadius = "500";
      },
    },
    {
      label: "Within 1,000 miles",
      value: "1000",
      action: () => {
        this.selectedRadius = "1000";
      },
    },
  ];

  constructor(name: string, urlParams?: URLSearchParams) {
    super(name, []);
    // if we have url params when creating the class, we need to "filter" so that we can get the location from paarams
    if (urlParams) {
      this.filter(urlParams);
    }
  }

  get selected(): boolean {
    return this.location != null;
  }

  filter(urlParams: URLSearchParams): string {
    const param = urlParams.get(this.name);
    if (!param) {
      return null;
    }

    const [cityState, longlat, radius] = param.split(":");
    const [city, state] = cityState.split(",");
    const [long, lat] = longlat.split(",");

    this.location = new Location();
    this.location.address.city = city;
    this.location.address.stateProvince = state;
    this.location.longitude = +long;
    this.location.latitude = +lat;
    this.selectedRadius = radius;

    // reason for math.ceil is azure search does not like a lot of digits
    // dividing by .62 give you the km. azure map filters in km and not miles
    return `(geo.distance(location/point, geography'POINT(${this.location.longitude}
               ${this.location.latitude})') le ${Math.ceil(+radius / 0.62137)})`;
  }

  uncheck = () => {
    this.location = null;
    this.selectedRadius = "250";
  };

  clear = () => {
    this.location = null;
  };

  update = (facets: CountValueFacetItem[]) => {
    // if this is selected do not update
    if (this.selected) {
      return;
    }
    console.error("need to update this logic");
    this.facets = facets;
  };

  toCityState = (): string => {
    if (!this.selected) {
      return "";
    }

    return `${this.location.address.city}, ${this.location.address.stateProvince}`;
  };

  toQueryParam = (): string => {
    if (!this.selected) {
      return undefined;
    }

    return `${this.location.address.city},${this.location.address.stateProvince}:${this.location.longitude},${this.location.latitude}:${this.selectedRadius}`;
  };
}
