import { Injectable } from '@angular/core';
import { BaseApiService, AuthenticationService, EnvironmentConfig } from '../../core/core';
import { HttpClient } from '@angular/common/http';

declare var environment: EnvironmentConfig;

@Injectable({
    providedIn: 'root'
})
export class TruckService extends BaseApiService<any> {
    constructor(httpClient: HttpClient, authenticationService: AuthenticationService) {
        super(httpClient, environment.baseUrl, "/api/transportation/v1/truck", true, authenticationService);
    }
}
