import { Component, ViewChild, Input, EventEmitter, Output, OnInit, ElementRef } from "@angular/core";
import { NgbDate, NgbCalendar, NgbDateStruct, NgbDatepickerConfig } from "@ng-bootstrap/ng-bootstrap";

import { DateRange, EnvironmentConfig } from "../../../core/core";
declare var environment: EnvironmentConfig;
@Component({
  selector: "common-date-picker",
  templateUrl: "./date-picker.component.html",
  styleUrls: ["./date-picker.component.scss"],
})
export class DatePickerComponent implements OnInit {
  @ViewChild("datepicker") datepicker;
  @ViewChild("datePickerInput") datePickerInput;
  @ViewChild("maxDateTooltip") maxDateTooltip;

  @Input() placeHolder: string;
  @Input() customClass: string = "";
  @Input() showCarrot: boolean = true;
  @Input() showErrorState: boolean;
  @Input() showFocusBorder: boolean;
  @Input() numberOfMonthsToShow: number = 1;
  @Input() numberOfDaysToBlockOff: number = 0;
  @Input() date: NgbDate | string | any;
  @Input() minDate: NgbDate;
  @Input() maxDate: NgbDate;
  @Input() maxNumberOfDays: number;
  @Input() useAsInput: boolean = true;
  @Output() closeDatePickerEvent = new EventEmitter<NgbDate>();
  @Output() dateSelected = new EventEmitter<NgbDate>();

  inputValue = "";
  valid: boolean = true;
  isOpen: boolean;
  today: NgbDate;
  todayAsDate: Date;
  limitBlockedDate: Date;
  outsideDays: string = "collapsed";
  hoveredDate: any;
  baseImagePath = environment.baseImagePath;

  constructor(private config: NgbDatepickerConfig, private calendar: NgbCalendar, private elRef: ElementRef) {
    this.today = calendar.getToday();
    this.todayAsDate = new Date(this.today.year, this.today.month, this.today.day);
  }

  ngOnInit() {
    if (!this.minDate) {
      this.minDate = this.calendar.getNext(this.today, "d", this.numberOfDaysToBlockOff);
    }

    if (this.date) {
      if (typeof this.date === "string") {
        // its a javascript date string lets parse it into a ngb date
        this.date = DateRange.createNgbDateFromString(this.date);
      }
      this.inputValue = `${DateRange.monthNames[this.date.month]} ${this.date.day}, ${this.date.year}`;
    }
    if (this.numberOfDaysToBlockOff > 0) {
      this.limitBlockedDate = DateRange.createDateFromNgbDate(this.minDate);
    }
    if (this.maxNumberOfDays) {
      this.maxDate = this.calendar.getNext(this.today, "d", this.maxNumberOfDays);
    }
  }

  // we will need to use this function for machine availability so we can disable the days its not available
  isDisabled(date: NgbDateStruct) {
    return false;
  }

  // this is for the blocking the first few days for order processing
  isBlockedOff(date: NgbDateStruct) {
    const yesterday = this.calendar.getNext(this.today, "d", -1);
    if (this.numberOfDaysToBlockOff > 0 && this.minDate.after(date) && yesterday.before(date)) {
      return true;
    }
    return false;
  }

  isMaxDate(date: NgbDateStruct) {
    // we do not have a max date so return false
    if (!this.maxDate) {
      return false;
    }
    const dateToCheck = NgbDate.from(date);
    if (dateToCheck.after(this.maxDate)) {
      return true;
    }
    return false;
  }

  openDatePicker() {
    this.isOpen = !this.isOpen;
    this.isOpen ? this.datepicker.open() : this.datepicker.close();
  }

  closeDatePicker() {
    this.datepicker?.close && this.datepicker.close();
  }

  onClosed() {
    this.isOpen = false;
    this.closeDatePickerEvent.emit(this.date);
  }

  // keeping this from now
  clearDatePicker() {
    this.date = null;
    this.inputValue = "";
  }

  isDateSelected(date: NgbDate) {
    return this.date != null && this.date.equals(date);
  }

  onDateSelection(date: NgbDate) {
    if (this.date != null && this.date.equals(date)) {
      this.verifyInputValue();
      this.closeDatePicker();
      return;
    }
    this.date = date;
    this.inputValue = `${DateRange.monthNames[this.date.month]} ${this.date.day}, ${this.date.year}`;
    this.closeDatePicker();
    this.onClosed();

    this.dateSelected.emit(this.date);
  }

  // this is to verify that the input value is showing the formatted date
  // there is a case when you go to a page and already have a date preselected
  // if you then select the same exact date it does not format it properly
  verifyInputValue() {
    if (this.datePickerInput && this.datePickerInput.nativeElement?.value !== this.inputValue) {
      this.datePickerInput.nativeElement.value = this.inputValue;
    }
  }
  // since we are using a custom day template
  // we need to determine if each day is an outside day
  // if it is an outside day lets add the outside-day css class
  isOutsideDay(currentMonthShown: number, date: NgbDate) {
    return currentMonthShown !== date.month;
  }

  // really not happy with this hack...
  // this is to fix the fancy arrow when we used the 'collapsed' for outside days for ng bootstrap
  // since we dont want a lot of spacing in the date picker we have to do this hack of adding a class depending on the height of the monht
  // because we are using the collapsed date picker. if we did not do this, the fancy arrow would be in the wrong place.
  monthsToFixHeight = [
    { year: 2021, month: 1, class: "small-height" },
    { year: 2021, month: 5, class: "small-height" },
    { year: 2021, month: 10, class: "small-height" },
    { year: 2022, month: 1, class: "small-height" },
    { year: 2022, month: 7, class: "small-height" },
    { year: 2022, month: 10, class: "small-height" },
    { year: 2023, month: 4, class: "small-height" },
    { year: 2023, month: 7, class: "small-height" },
    { year: 2023, month: 12, class: "small-height" },
    { year: 2024, month: 3, class: "small-height" },
    { year: 2024, month: 6, class: "small-height" },
    { year: 2024, month: 3, class: "small-height" },
    { year: 2024, month: 6, class: "small-height" },
    { year: 2025, month: 3, class: "small-height" },
    { year: 2025, month: 8, class: "small-height" },
    { year: 2025, month: 11, class: "small-height" },
    { year: 2026, month: 2, class: "super-small-height" },
    { year: 2026, month: 5, class: "small-height" },
    { year: 2026, month: 8, class: "small-height" },
    { year: 2027, month: 1, class: "small-height" },
    { year: 2027, month: 5, class: "small-height" },
    { year: 2027, month: 10, class: "small-height" },
    { year: 2028, month: 1, class: "small-height" },
    { year: 2028, month: 4, class: "small-height" },
    { year: 2028, month: 7, class: "small-height" },
    { year: 2028, month: 12, class: "small-height" },
    { year: 2029, month: 9, class: "small-height" },
    { year: 2029, month: 12, class: "small-height" },
    { year: 2030, month: 3, class: "small-height" },
    { year: 2030, month: 6, class: "small-height" },
  ];

  navigate(event) {
    if (!event.next) {
      return;
    }
    const html = this.elRef.nativeElement;
    const arrow = html.querySelector(".dropdown-arrow") as HTMLElement;
    const mapping = this.monthsToFixHeight.find((d) => d.year == event.next.year && d.month === event.next.month);
    if (mapping) {
      arrow?.classList.add(mapping.class);
    } else {
      arrow?.classList.remove("small-height");
      arrow?.classList.remove("super-small-height");
    }
  }
  // the reason for doing the arrow loaded is to do it on the first time the date picker is opened
  // all other consecutive navigation occurs in the navigate call back
  arrowLoaded() {
    const event = { next: this.date != null ? this.date : this.today };
    this.navigate(event);
  }
}
