import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PaymentsModule } from "./payments/payments.module";

@NgModule({
  declarations: [],
  imports: [CommonModule, PaymentsModule],
  exports: [PaymentsModule],
})
export class BillingModule {}
