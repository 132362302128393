import { Component, OnInit } from "@angular/core";
import { AzureSuggestsResult } from "../../../azure-search/models/azure-suggests-result.model";
import { SearchRequest, SearchResultsService } from "../../../search-results/search-results";
import { Location, LogService, MarketTrackingEvents } from "../../../core/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-header-search",
  templateUrl: "./header-search.component.html",
  styleUrls: ["./header-search.component.scss"],
})
export class HeaderSearchComponent implements OnInit {
  searchRequest: SearchRequest;

  constructor(private router: Router, private searchResultsService: SearchResultsService, private logService: LogService) {}

  ngOnInit() {
    this.searchRequest = this.searchResultsService.searchRequest;
  }

  suggestionSelected($event: AzureSuggestsResult) {
    this.searchRequest.searchTerm = $event.value;
    this.searchRequest.selectedSuggestion = $event;

    this.onEnter();
  }

  locationSelected(event: Location) {
    this.searchRequest.location.location = event;
    this.searchResultsService.filterResults();
  }

  onEnter(ownerSearch: boolean = false) {
    this.router.navigate([this.searchRequest.searchResultsUrl(ownerSearch)], { queryParams: this.searchRequest.queryParams });
    this.logService.trackEvent(
      MarketTrackingEvents.Search.Header,
      {
        event: "rentalSearch",
        searchTerm: this.searchRequest.searchTerm,
        location: this.searchRequest.location.toCityState(),
      },
      true,
    );
  }
}
