<div class="form-group">
  <common-multi-dropdown
    [dropDownItems]="dropdownItems"
    [multiSelectedText]="facet.label"
    [emptyMessage]="facet.label"
    [enableSearch]="true"
    (applyChange)="selectionChanged($event)"
    customClass="bg-white"
  >
  </common-multi-dropdown>
</div>
<!-- 
[(entityProperty)]="associationItemsSelected"
(entityPropertyChange)="selectionChanged($event)" -->
