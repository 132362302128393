import { Component, OnInit, Input } from "@angular/core";
import { Validators, FormBuilder } from "@angular/forms";
import { NgbActiveModal, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { RentalRequestItem } from "../../rentals/models/rental-request-item.model";
import { RentalItemStateService } from "../../rentals/services/rental-item-state.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: "common-update-rental-item-status",
  templateUrl: "./update-rental-item-status.component.html",
  styleUrls: ["./update-rental-item-status.component.scss"],
})
export class UpdateRentalItemStatusComponent implements OnInit {
  rentalRequestId: string;
  rentalRequestItem: RentalRequestItem;
  status: string;
  reasons: any[];

  statusUpdateForm = this.fb.group({
    reasonForUpdate: ["", Validators.compose([Validators.required])],
    addInfo: ["", Validators.compose([Validators.required])],
    targetDate: {},
  });

  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal, public rentalItemStateService: RentalItemStateService) {}

  ngOnInit(): void {
    //set applicable rental stage options
    switch (this.status) {
      case "OnRent": {
        this.reasons = [
          { label: "No telematics device installed", value: "No telematics device installed" },
          { label: "No telematics connectivity", value: "No telematics connectivity" },
          { label: "Transport provided separately", value: "Transport provided separately" },
          { label: "Other", value: "Other" },
        ];
        break;
      }

      case "ReturnFulfillment": {
        this.reasons = [
          { label: "No telematics device installed", value: "No telematics device installed" },
          { label: "No telematics connectivity", value: "No telematics connectivity" },
          { label: "Transport provided separately", value: "Transport provided separately" },
          { label: "Other", value: "Other" },
        ];
        break;
      }

      case "Cancelled": {
        this.reasons = [
          { label: "Renter: Better Price", value: "Renter-Price" },
          { label: "Renter: Job Delayed/Cancelled", value: "Renter-Job" },
          { label: "Renter: Transportation Cost High", value: "Renter-Transpo-Cost" },
          { label: "Renter: Other", value: "Renter-Other" },
          { label: "Owner: Machine Unavailable", value: "Machine-Unavailable" },
          { label: "Owner: Machine Failed Inspection", value: "Machine-Failed-Inspection" },
          { label: "Owner: Machine Configuration Not Available", value: "Machine-Configuration-Not-Available" },
          { label: "Rubbl: Renter Not Qualified", value: "Rubbl-Renter-Not-Qualified" },
        ];
        break;
      }
    }
  }

  datePickerClosed(date: NgbDate) {
    let fullDateString = new Date(date.year, date.month - 1, date.day);
    let formattedDate = new DatePipe("en").transform(fullDateString, "yyy-MM-dd");
    this.statusUpdateForm.patchValue({ targetDate: formattedDate });
  }

  onReasonSelected(reason) {
    this.statusUpdateForm.patchValue({ reasonForUpdate: reason });
  }

  doTextareaValueChange(event) {
    this.statusUpdateForm.patchValue({ addInfo: event.target.value });
  }

  onModalCancel() {
    //use modal dismiss for caller to detect cancel
    this.activeModal.dismiss();
  }

  save(event) {
    event.preventDefault();

    let targetDate = this.statusUpdateForm.get("targetDate").value;
    let reason = this.statusUpdateForm.get("reasonForUpdate").value;
    let notes = this.statusUpdateForm.get("addInfo").value;

    let method = null;
    switch (this.status) {
      case "OnRent": {
        method = this.rentalItemStateService.startRent(this.rentalRequestId, this.rentalRequestItem.inventoryId, targetDate, reason, notes);
        break;
      }
      case "ReturnFulfillment":
        method = this.rentalItemStateService.returnMachine(this.rentalRequestId, this.rentalRequestItem.inventoryId, this.statusUpdateForm.value);
        break;
      case "Cancelled": {
        method = this.rentalItemStateService.cancelRentalItem(this.rentalRequestId, this.rentalRequestItem.inventoryId, reason, notes);
        break;
      }
      case "Completed": {
        method = this.rentalItemStateService.completeRentalItem(this.rentalRequestId, this.rentalRequestItem.inventoryId, targetDate, notes);
      }
    }

    method?.subscribe(
      (result) => {
        this.activeModal.close();
      },
      (error) => {
        this.activeModal.dismiss();
      },
    );
  }
}
