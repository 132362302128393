import { Image } from '../../core/core';

export interface TypeDefinition {
    categories: any[];
    code: string;
    compatibleWith: TypeDefinition[];
    displayName: string;
    defaultImage?: any;
    definitionType: string;
    id: string;
    indexKey: string;
    isActive: boolean;
    itemType?: string[];
    name: string;
    parentTypeDefinitionCode?: any;
    parentTypeDefinitionId?: string;
    parentTypeDefinitionName: string;
    partitionKey?: string;
    primaryType: string;
    specifications: Specification[];
    status: TypeDefinitionStatus;
}

export enum TypeDefinitionStatus {
    Draft = "Draft",
    Published = "Published",
    Archived = "Archived"
}

export interface MachineType extends TypeDefinition {
    attachmentPositions: string[];
    pmRate: number;
    stockImage: Image;
    usageMeasurement: UnitOfMeasure;
}

export interface AttachmentType extends TypeDefinition {
    primarySpecification: string;
    displayName: string;
    standard: boolean;
    position: string;
}

export interface Specification {
    specificationType: SpecificationType;
    allowedValues?: string[];
    standardMeasurement?: StandardMeasurement;
    metricMeasurement?: StandardMeasurement;
    name: string;
    category?: any;
    isRequired: boolean;
    value?: any;
    isTypeDefinitionSpec: boolean;
    isPublic: boolean;
    modified: boolean;
    replaced: boolean;
    isPrimary: boolean;
    displayOnCard?: boolean;
}

export enum SpecificationType {
    Description = "Description",
    Measurement = "Measurement",
    Enumeration = "Enumeration"
}

export interface StandardMeasurement {
    value: number;
    unitOfMeasure: UnitOfMeasure;
}

export interface UnitOfMeasure {
    id?: string;
    name: string;
    system?: string;
    primaryAbbreviation?: string;
    alternateAbbreviations?: string[];
    domain?: string;
}

