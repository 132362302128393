<div class="p-4">
  <label>Select start date</label>
  <p class="font-weight-light mb-3">Must be within 14 days of today's date.</p>
  <div class="d-flex justify-content-center mb-3">
    <common-date-picker
      [useAsInput]="false"
      [numberOfDaysToBlockOff]="1"
      [maxNumberOfDays]="14"
      #datepicker
      (dateSelected)="onStartDateSelected($event)"
      [date]="startDate"
    ></common-date-picker>
  </div>
  <label>Select rental duration</label>
  <p class="font-weight-light">This is only an estimate. Keep the machine as long as needed.</p>
  <select class="form-control" [ngModel]="duration" (change)="onDurationSelected($event.target.value)">
    <option value="0">Select</option>
    <option value="7">1 week</option>
    <option value="14">2 weeks</option>
    <option value="28">1+ month</option>
    <option value="84">
      3+ months <span *ngIf="threeMonthDiscount">(Save {{ threeMonthDiscount | currency: "USD":"symbol":"1.0-0" }})</span>
    </option>
    <option value="168">
      6+ months <span *ngIf="sixMonthDiscount">(Save {{ sixMonthDiscount | currency: "USD":"symbol":"1.0-0" }})</span>
    </option>
  </select>
</div>
