<!-- (indexChange)="onIndexChange($event)"
    (swiperTransitionStart)="onSwiperEvent('transitionStart')" (swiperTransitionEnd)="onSwiperEvent('transitionEnd')"-->
<div class="swiper-container" [swiper]="config">
  <div class="d-flex carousel-header-container align-items-center">
    <div class="carousel-header mr-auto">
      {{header}}
    </div>
    <div class="carousel-actions d-flex">
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
  <div id="swiper-container" class="swiper-wrapper">
    <ng-template #swiperContainer></ng-template>
  </div>
</div>