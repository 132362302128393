import { Component, OnInit, Input, EventEmitter, Output, ElementRef, SimpleChanges, OnChanges } from "@angular/core";
import { Options } from "@angular-slider/ngx-slider";
import { RangeSlider } from "./range-slider.model";

@Component({
  selector: "app-range-slider",
  templateUrl: "./range-slider.component.html",
  styleUrls: ["./range-slider.component.scss"],
})
export class RangeSliderComponent implements OnInit, OnChanges {
  @Input() rangeSlider: RangeSlider;
  @Input() useCommas: boolean;
  @Output() rangeSliderChange = new EventEmitter<RangeSlider>();

  options: Options;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.options = {
      floor: this.rangeSlider.floor,
      ceil: this.rangeSlider.ceil,
      step: this.rangeSlider.step,
      noSwitching: true,
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    let rangeSlider = changes.rangeSlider.currentValue;
    if (rangeSlider) {
      this.options = {
        floor: rangeSlider.floor,
        ceil: rangeSlider.ceil,
        step: rangeSlider.step,
        noSwitching: true,
      };
    }
  }

  onUserChangeEnd(): void {
    let sliderPoints = this.elementRef.nativeElement.querySelectorAll(".ngx-slider-pointer");
    for (let sliderPoint of sliderPoints) {
      this.rangeSlider.sliderSelected === true ? sliderPoint.classList.add("active") : sliderPoint.classList.remove("active");
    }

    const middleSelection = this.elementRef.nativeElement.querySelector(".ngx-slider-selection");
    this.rangeSlider.sliderSelected === true ? middleSelection.classList.add("active") : middleSelection.classList.remove("active");

    this.rangeSliderChange.emit(this.rangeSlider);
  }
}
