import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { MultiDropDownItem } from "../../../forms/forms";
import { CountValueFacet, CountValueFacetItem } from "../../../search-results/search-results";

@Component({
  selector: "common-index-table-facet",
  templateUrl: "./index-table-facet.component.html",
})
export class IndexTableFacetComponent implements OnChanges {
  @Input() facet: CountValueFacet;
  @Input() facetItems: CountValueFacetItem;

  @Output() apply = new EventEmitter<CountValueFacet>();

  dropdownItems: MultiDropDownItem[];
  constructor() {}

  ngOnChanges() {
    this.dropdownItems = this.facet.facets.map((d) => {
      return { label: `${d.value} (${d.count})`, checked: d.checked, value: d.value };
    });
  }

  selectionChanged(valuesSelected: string[]) {
    this.facet.facets.map((d) => (d.checked = false));
    for (let value of valuesSelected) {
      this.facet.facets.find((d) => d.value === value).checked = true;
    }
    this.apply.emit(this.facet);
  }
}
