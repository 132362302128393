import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

import { SearchContext } from "../models/search-context.model";
import { AzureSearchResult } from "../models/azure-search-result.model";
import { SearchResultItem } from "../models/search-result-item";
import { EnvironmentConfig } from "../../core/models/environment-config.model";
import { AzureSuggestsRequest } from "../models/azure-suggests-request.model";
import { AzureSuggestsResult } from "../models/azure-suggests-result.model";
import { AzureSearchRequest } from "../models/azure-search-request.model";

declare let environment: EnvironmentConfig;

@Injectable({
  providedIn: "root",
})
export class AzureSearchService {
  private environment: EnvironmentConfig;
  private searchUrl: string;
  private searchKey: string;

  constructor(private http: HttpClient) {
    this.environment = environment;
    this.searchUrl = this.environment.azureSearchUrl;
    this.searchKey = this.environment.azureSearchKey;
  }

  public suggestions(model: AzureSuggestsRequest, index: string): Observable<AzureSuggestsResult[]> {
    const headers = new HttpHeaders().set("api-key", this.searchKey);
    return this.http.post(`${this.searchUrl}/indexes/${index}/docs/suggest?api-version=2020-06-30`, model, { headers }).pipe(
      map(response => {
        const results: AzureSuggestsResult[] = [];
        for (const value of response["value"]) {
          results.push(new AzureSuggestsResult(value["@search.text"], value["displayText"], value["autoFacetFields"], value["autoFacetValues"]));
        }
        return results;
      }),
    );
  }

  // the inital filter is used to filter by inherited types, such as machine type and attachment type
  // machine type and attachment type inherit the type definitional model
  // when on the machine type list, the inital filter will be "definitionType eq 'MachineType'"
  public search<T>(searchRequest: AzureSearchRequest, index: string, inheritedTypeFilter: string = ""): Observable<AzureSearchResult<T>> {
    const headers = new HttpHeaders().set("api-key", this.searchKey);

    if (searchRequest.filter !== "" && inheritedTypeFilter !== "") {
      inheritedTypeFilter = ` and ${inheritedTypeFilter}`;
    }

    // check for guid
    const regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
    if (regexGuid.test(searchRequest.search.replace("*", ""))) {
      searchRequest.search = `"${searchRequest.search}"`;
    } else if (searchRequest.search.includes("-")) {
      // check for partial guid
      searchRequest.search = searchRequest.search.replace(/[#-]/g, " ");
    }

    return this.http
      .post(
        `${this.searchUrl}/indexes/${index}/docs/search?api-version=2020-06-30`,
        { ...searchRequest, filter: `${searchRequest.filter}${inheritedTypeFilter}` },
        { headers },
      )
      .pipe(
        map(x => {
          const resp = new AzureSearchResult<T>();

          resp.search = new SearchContext();
          resp.odata = {} as any;
          if (x["@search.facets"] != null) {
            resp.search.facets = x["@search.facets"];
          }

          if (x["@search.nextPageParameters"] != null) {
            resp.search.nextPageParameters = x["@search.nextPageParameters"];
          }

          if (x["@odata.context"] != null) {
            resp.odata.context = x["@odata.context"];
          }

          if (x["@odata.count"] != null) {
            resp.odata.count = x["@odata.count"];
          }

          if (x["value"] != null) {
            for (const item of x["value"]) {
              item.search = new SearchResultItem();
              if (item["@search.score"] != null) {
                item.search.score = item["@search.score"];
              }

              if (item["@search.highlights"] != null) {
                item.search.highlights = item["@search.highlights"];
              }
              resp.value.push(item);
            }
          }

          return resp;
        }),
      );
  }

  getOne<T>(key: string, index: string): Observable<T> {
    const headers = new HttpHeaders().set("api-key", this.searchKey);
    return this.http.get<T>(`${this.searchUrl}/indexes/${index}/docs/${key}?api-version=2017-11-11`, { headers });
  }
}
