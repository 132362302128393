<div [class.d-flex]="rightSideText">
  <div rt="top-bar-navigation-container" class="top-bar-navigation-container" [style.background-color]="backgroundColor">
    <div class="container swiper-container px-0" [swiper]="config" [index]="swiperIndex">
      <div class="swiper-wrapper">
        <div
          class="d-inline-flex align-items-center navigation-item-container swiper-slide"
          *ngFor="let item of navigationItems; index as i"
          [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="routerLinkActiveOptions"
        >
          <a
            class="navigation-item text-grey"
            *ngIf="item.route && item.children == null"
            href=""
            [routerLink]="item.route"
            [routerLinkActive]="['text-dark-grey']"
            [queryParams]="item.queryParams ? item.queryParams : null"
            [queryParamsHandling]="preserveQueryParams === true ? 'preserve' : null"
            [routerLinkActiveOptions]="routerLinkActiveOptions"
            (click)="swiperIndex = i"
            >{{ item.label }}</a
          >

          <div
            class="nav-item"
            ngbDropdown
            container="body"
            *ngIf="item.children"
            [routerLinkActive]="['children-active']"
            [routerLinkActiveOptions]="routerLinkActiveOptions"
          >
            <a class="nav-link navigation-item text-grey cursor-pointer" ngbDropdownToggle>
              {{ item.label }} <i class="icon-carrot text-grey small ml-1"></i>
            </a>
            <div ngbDropdownMenu class="dropdown-menu">
              <a
                class="dropdown-item"
                *ngFor="let children of item.children"
                href=""
                [routerLink]="children.route"
                [queryParams]="children.queryParams ? children.queryParams : null"
                [queryParamsHandling]="preserveQueryParams === true ? 'preserve' : null"
                (click)="swiperIndex = i"
                >{{ children.label }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div rt="right-side-text" *ngIf="rightSideText" [ngClass]="rightsideTextClass">
    {{ rightSideText }}
  </div>
</div>
