import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "common-validation-card",
  templateUrl: "./validation-card.component.html",
  styleUrls: ["./validation-card.component.scss"],
})
export class ValidationCardComponent {
  // can use regular bootstrap classes for this such as `border-secondary` `border-warning`
  // you can also add `small card` to fit the height of admin buttons
  @Input() cardClass: string = "border-primary";
  @Input() messages: string[] = [];
  @Input() showX: boolean = false;
  @Input() iconClass: string;
  @Input() rightSideText: string;
  @Input() rightSideTextClass: string = "text-green";
  @Input() rightSideToolTipText: string = "";
  @Input() cardBodyClass: string;

  @Output() rightSideTextClick = new EventEmitter<void>();

  constructor() {}

  rightSideClicked() {
    this.rightSideTextClick.emit();
  }
}
