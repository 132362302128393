<div class="d-flex flex-wrap align-items-baseline">
  <span class="badge badge-pill badge-warning mb-2 mr-3" [class.mr-2]="!isLast" *ngFor="let attachment of firstTwoAttachments; last as isLast">
    <span *ngIf="attachment.cost != 0">$</span> {{ attachment.displayName }}</span
  >
  <app-questionmark-tooltip
    *ngIf="tooltipData"
    [tableRows]="tooltipData"
    [displayLearnMore]="false"
    linkText="See all options"
    placement="left"
    mobilePlacement="top"
    [hideTableValueOnMobile]="true"
  ></app-questionmark-tooltip>
</div>
