import { CurrencyPipe, PercentPipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { generate, observe } from "fast-json-patch";
import { BaseEntityDisplay, ModalService } from "../../../core/core";
import { ToastrService } from "ngx-toastr";
import { of } from "rxjs";
import { InventoryItem } from "../../models/inventory-item.model";
import { ComparisonTable } from "../../../tables/tables";
import { InventoryService } from "../../services/inventory.service";

@Component({
  selector: "common-machine-item-rpo",
  templateUrl: "./machine-item-rpo.component.html",
  styleUrls: ["./machine-item-rpo.component.scss"],
  providers: [CurrencyPipe, PercentPipe],
})
export class MachineItemRpoComponent extends BaseEntityDisplay implements OnInit {
  entity: InventoryItem;

  comparisonTable: ComparisonTable = {
    headers: [],
    rows: [],
  };

  comparisonTable2: ComparisonTable = {
    headers: [],
    rows: [],
  };

  comparisonTable3: ComparisonTable = {
    headers: [],
    rows: [],
  };

  observer: any;
  constructor(
    private inventoryService: InventoryService,
    toastrService: ToastrService,
    router: Router,
    activatedRoute: ActivatedRoute,
    public modalService: ModalService,
    private currencyPipe: CurrencyPipe,
    private percentPipe: PercentPipe,
  ) {
    super(inventoryService, toastrService, router, activatedRoute, modalService, null, "id", true);
  }

  setupView(entity: InventoryItem) {
    this.entity = entity;
    this.onPurchasePriceChange(entity?.purchasePrice ?? "0");

    this.observer = observe(this.entity);
    return of(entity);
  }

  onPurchasePriceChange(purchasePrice: string | any) {
    if (typeof purchasePrice === "string") {
      this.entity.purchasePrice = parseFloat(purchasePrice.replace(/,/g, ""));
    } else {
      this.entity.purchasePrice = purchasePrice;
    }

    const rentalDeductionRate = 0.8;
    const conversionFee = 0.075;

    const rentDeduction2 = this.entity.rentalRate * rentalDeductionRate * 2;
    const rentDeduction4 = this.entity.rentalRate * rentalDeductionRate * 4;
    const rentDeduction6 = this.entity.rentalRate * rentalDeductionRate * 6;

    const adjustedPrice2 = this.entity.purchasePrice - rentDeduction2;
    const adjustedPrice4 = this.entity.purchasePrice - rentDeduction4;
    const adjustedPrice6 = this.entity.purchasePrice - rentDeduction6;

    // tool tip it, we give you half of the rent we collected
    const rubblCredit2 = this.entity.rentalRate * 0.1 * 2;
    const rubblCredit4 = this.entity.rentalRate * 0.1 * 4;
    const rubblCredit6 = this.entity.rentalRate * 0.1 * 6;

    const conversionFee2 = (this.entity.purchasePrice - rentDeduction2) * conversionFee;
    const conversionFee4 = (this.entity.purchasePrice - rentDeduction4) * conversionFee;
    const conversionFee6 = (this.entity.purchasePrice - rentDeduction6) * conversionFee;

    const total2 = adjustedPrice2 - conversionFee2 + rubblCredit2;
    const total4 = adjustedPrice4 - conversionFee4 + rubblCredit4;
    const total6 = adjustedPrice6 - conversionFee6 + rubblCredit6;

    const rubblTotal2 = adjustedPrice2 - total2;
    const rubblTotal4 = adjustedPrice4 - total4;
    const rubblTotal6 = adjustedPrice6 - total6;

    const rubblPercentage2 = rubblTotal2 / adjustedPrice2;
    const rubblPercentage4 = rubblTotal4 / adjustedPrice4;
    const rubblPercentage6 = rubblTotal6 / adjustedPrice6;

    let firstRows = [
      {
        columns: [
          { label: "Purchase Price" },
          { label: this.currencyPipe.transform(this.entity.purchasePrice, "USD", "symbol", "1.0-0") },
          { label: this.currencyPipe.transform(this.entity.purchasePrice, "USD", "symbol", "1.0-0") },
          { label: this.currencyPipe.transform(this.entity.purchasePrice, "USD", "symbol", "1.0-0") },
        ],
      },
      {
        columns: [
          { label: "Rental Deduction (80/20)" },
          { label: `<span class='text-primary'>(${this.currencyPipe.transform(rentDeduction2, "USD", "symbol", "1.0-0")})</span>` },
          { label: `<span class='text-primary'>(${this.currencyPipe.transform(rentDeduction4, "USD", "symbol", "1.0-0")})</span>` },
          { label: `<span class='text-primary'>(${this.currencyPipe.transform(rentDeduction6, "USD", "symbol", "1.0-0")})</span>` },
        ],
      },
      {
        columns: [
          { label: "<b>Payoff Price</b>" },
          { label: adjustedPrice2 > 0 ? `<b>${this.currencyPipe.transform(adjustedPrice2, "USD", "symbol", "1.0-0")}</b>` : "-" },
          { label: adjustedPrice4 > 0 ? `<b>${this.currencyPipe.transform(adjustedPrice4, "USD", "symbol", "1.0-0")}</b>` : "-" },
          { label: adjustedPrice6 > 0 ? `<b>${this.currencyPipe.transform(adjustedPrice6, "USD", "symbol", "1.0-0")}</b>` : "-" },
        ],
      },
    ];

    let secondRows = [
      {
        columns: [
          { label: `RPO Conversion Fee (${this.percentPipe.transform(conversionFee, "1.1")})` },
          { label: conversionFee2 > 0 ? `<span class='text-primary'>(${this.currencyPipe.transform(conversionFee2, "USD", "symbol", "1.0-0")})</span>` : "-" },
          { label: conversionFee4 > 0 ? `<span class='text-primary'>(${this.currencyPipe.transform(conversionFee4, "USD", "symbol", "1.0-0")})</span>` : "-" },
          { label: conversionFee6 > 0 ? `<span class='text-primary'>(${this.currencyPipe.transform(conversionFee6, "USD", "symbol", "1.0-0")})</span>` : "-" },
        ],
      },
      {
        columns: [
          { label: "RPO Credit" },
          { label: this.currencyPipe.transform(rubblCredit2, "USD", "symbol", "1.0-0") },
          { label: this.currencyPipe.transform(rubblCredit4, "USD", "symbol", "1.0-0") },
          { label: this.currencyPipe.transform(rubblCredit6, "USD", "symbol", "1.0-0") },
        ],
      },
      {
        columns: [
          { label: "<b>Rubbl Total</b>" },
          {
            label:
              rubblTotal2 > 0
                ? `<b>${this.currencyPipe.transform(rubblTotal2, "USD", "symbol", "1.0-0")}</b><br/> <span class='ml-1 badge badge-outline-secondary'>
                    ${this.percentPipe.transform(rubblPercentage2, "1.1")}</span>`
                : "-",
          },
          {
            label:
              rubblTotal4 > 0
                ? `<b>${this.currencyPipe.transform(rubblTotal4, "USD", "symbol", "1.0-0")}</b><br/> <span class='ml-1 badge badge-outline-secondary'>
                    ${this.percentPipe.transform(rubblPercentage4, "1.1")}</span>`
                : "-",
          },
          {
            label:
              rubblTotal6 > 0
                ? `<b>${this.currencyPipe.transform(rubblTotal6, "USD", "symbol", "1.0-0")}</b><br/> <span class='ml-1 badge badge-outline-secondary'>
                    ${this.percentPipe.transform(rubblPercentage6, "1.1")}</span>`
                : "-",
          },
        ],
      },
    ];

    let thirdRows = [
      {
        columns: [
          { label: "<b>Your Total</b>" },
          {
            useCheckmark: true,
            label: total2 > 0 ? `<b class="fs-18">${this.currencyPipe.transform(total2, "USD", "symbol", "1.0-0")}</b>` : "-",
          },
          {
            useCheckmark: true,
            label: total4 > 0 ? `<b class="fs-18">${this.currencyPipe.transform(total4, "USD", "symbol", "1.0-0")}</b>` : "-",
          },
          {
            useCheckmark: true,
            label: total6 > 0 ? `<b class="fs-18">${this.currencyPipe.transform(total6, "USD", "symbol", "1.0-0")}</b>` : "-",
          },
        ],
      },
    ];

    firstRows.unshift({
      columns: [{ label: "<b>Rental Duration</b>" }, { label: "<b>2 Months</b>" }, { label: "<b>4 Months</b>" }, { label: "<b>6 Months</b>" }],
    });

    this.comparisonTable.rows = firstRows;
    this.comparisonTable2.rows = secondRows;
    this.comparisonTable3.rows = thirdRows;
  }

  save() {
    // const patchOperation = this.inventoryService.jsonPatchWithPartitionKey(this.entity.id, this.en);
    const jsonPatch = generate(this.observer);
    if (!jsonPatch.length) {
      return;
    }
    this.inventoryService.jsonPatchWithPartitionKey(this.entity.id, jsonPatch, this.entity.accountId).subscribe((result) => {
      this.toastrService.success("Succesfuly saved");
    });
  }
}
