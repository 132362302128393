<div class="d-lg-flex align-items-center" rt="top-bar-container">
  <div class="mr-auto w-auto">
    <div class="input-group" [hidden]="!showSearchInput">
      <input type="text" #searchInput class="form-control search" placeholder="Search" rt="common-index-table-search" />
      <i class="icon-mag-glass"></i>
    </div>
  </div>
  <div class="mt-3 mt-lg-0">
    <common-icon-button
      *ngIf="addButtonText"
      [routerLink]="['./create']"
      queryParamsHandling="merge"
      iconClass="icon-add"
      [buttonText]="addButtonText"
      rt="common-index-table-add-button"
    ></common-icon-button>
  </div>
</div>
<div class="form-row mt-3" *ngIf="countValueFacets?.length">
  <div class="col-lg-4 col-xl-3" *ngFor="let facet of countValueFacets">
    <common-index-table-facet [facet]="facet" [facetItems]="facet.facets" (apply)="facetApplied($event)"></common-index-table-facet>
  </div>
</div>
<div class="mt-2" rt="table-container" [ngClass]="{ searching: isSearching }">
  <div class="card table-card" *ngIf="result != null">
    <ngx-datatable
      rt="table"
      #indexTable
      [rows]="rows"
      [columns]="columns"
      [headerHeight]="50"
      [footerHeight]="125"
      [rowHeight]="58"
      columnMode="force"
      [externalPaging]="true"
      [count]="result.odata.count"
      [offset]="offset"
      [limit]="request.top"
      (page)="search($event)"
      [externalSorting]="true"
      (sort)="onSort($event)"
    >
      <ngx-datatable-footer>
        <ng-template
          ngx-datatable-footer-template
          let-rowCount="rowCount"
          let-pageSize="pageSize"
          let-selectedCount="selectedCount"
          let-curPage="curPage"
          let-offset="offset"
          let-isVisible="isVisible"
        >
          <datatable-pager
            [pagerLeftArrowIcon]="'datatable-icon-left'"
            [pagerRightArrowIcon]="'datatable-icon-right'"
            [pagerPreviousIcon]="'datatable-icon-prev'"
            [pagerNextIcon]="'datatable-icon-skip'"
            [page]="curPage"
            [size]="pageSize"
            [count]="rowCount"
            [hidden]="!(rowCount / pageSize > 1)"
            (change)="table.onFooterPage($event)"
          >
          </datatable-pager>
          <div class="page-count">
            {{ pageCountText(pageSize, curPage, rowCount) }}
          </div>
        </ng-template>
      </ngx-datatable-footer>
    </ngx-datatable>
  </div>
</div>

<ng-template #statusTemplate let-row="row" let-value="value">
  <div class="float-right float-lg-none">
    <common-status-label [status]="value"></common-status-label>
  </div>
</ng-template>

<ng-template #currencyTemplate let-row="row" let-value="value">
  <span *ngIf="value <= 0" [title]="value | currency: 'USD':'symbol':'1.0-0'">
    {{ value | currency: "USD":"symbol":"1.0-0" }}
  </span>
  <span *ngIf="value > 0" [title]="value | currency: 'USD':'symbol':'1.0-0'" class="text-green"> +{{ value | currency: "USD":"symbol":"1.0-0" }} </span>
</ng-template>

<ng-template #linkTemplate let-row="row" let-value="value" let-column="column">
  <a class="text-green cursor-pointer" (click)="linkClick(row, column)">{{ value }}</a>
</ng-template>

<ng-template #actionsTemplate let-row="row" let-value="value" let-column="column">
  <ng-template
    let-actions="computedActions"
    #selfie
    [ngTemplateOutlet]="selfie"
    [ngTemplateOutletContext]="{ computedActions: getComputedActions(column, row) }"
  >
    <div class="btn-group btn-group-action" role="group">
      <button type="button" class="btn btn-outline-gray br-0 py-0" *ngIf="actions.length" (click)="actions[0].click(row)">
        {{ actions[0].label }}
      </button>

      <div ngbDropdown container="body" class="d-inline-block" *ngIf="actions.length > 1">
        <button class="btn btn-outline-gray br-0 more-actions px-2" ngbDropdownToggle><i class="icon-carrot"></i></button>
        <div class="br-0" ngbDropdownMenu>
          <ng-container *ngFor="let action of actions | slice: 1">
            <button ngbDropdownItem (click)="action.click(row)" [disabled]="action.disable">{{ action.label }}</button>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>
