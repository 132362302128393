import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RubblWebCommonModule } from '@rubbl-web-common';
import { ToastrModule } from 'ngx-toastr';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RubblWebCommonModule,
    ToastrModule.forRoot(),
    NgxDatatableModule
  ],
  exports: [
    RubblWebCommonModule,
    ToastrModule,
    NgxDatatableModule
  ]
})
export class SharedModule { }
