<div>
  <div class="d-flex justify-content-center align-items-center bb-light p-4">
    <div></div>
    <h4 class="medium mb-0">Confirm Status Update</h4>
  </div>
  <div class="p-4">
    <p>
      Are you sure you want to update the status of the
      <span class="bold">{{ rentalRequestItem.inventoryItem.make + " " + rentalRequestItem.inventoryItem.model }}</span>
      <span class="text-light-grey">{{ " " + "(" + rentalRequestItem.inventoryItem.serialNumber + ")" }}</span> to <span class="bold">"{{ status }}"</span>
    </p>
    <p *ngIf="status != 'Completed'">Additional information is required before updating.</p>
    <form class="form">
      <div class="form-group" *ngIf="status === 'OnRent' || status === 'ReturnFulfillment' || status === 'Completed'">
        <label *ngIf="status == 'OnRent'">Rental Start Date</label>
        <label *ngIf="status == 'ReturnFulfillment'">Rental End Date</label>
        <label *ngIf="status == 'Completed'">test</label>
        <common-date-picker minDate="false" placeHolder="Select" (closeDatePickerEvent)="datePickerClosed($event)"></common-date-picker>
      </div>
      <div class="form-group" *ngIf="status === 'OnRent' || status === 'Cancelled'">
        <label>Reason for update</label>
        <common-dropdown class="dropdown-container fw" [dropDownItems]="reasons" [showSelect]="true" (itemSelected)="onReasonSelected($event)">
        </common-dropdown>
      </div>
      <div class="form-group d-flex justify-content-center flex-column bb-light">
        <label>Additional Information</label>
        <p class="text-light-grey">Provide additional notes and comments based on the reason selected</p>
        <textarea class="form-control" rows="4" (change)="doTextareaValueChange($event)"></textarea>
      </div>
    </form>
    <div class="d-flex justify-content-center align-items-center">
      <button type="cancel" (click)="onModalCancel()" class="btn btn-md btn-modal-footer mr-3 btn-no-box-shadow btn-outline-dark">Cancel</button>
      <button type="submit" (click)="save($event)" class="btn btn-md btn-modal-footer ml-3 bg-green text-white btn-no-box-shadow">Update</button>
    </div>
  </div>
</div>
