import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss']
})
export class InputCheckboxComponent implements OnInit {

  @Input() label = "";
  @Input() numberOfStars: number = null;
  @Input() checked: boolean = false;
  @Input() useHtml: boolean = false;
  @Input() fontSize: string = "16px";
  @Input() checkmarkColor: string = "#009688";
  @Input() disabled: boolean = false;
  @Input() large: boolean = false;
  @Input() invalid: boolean = false;

  @Output() checkedChange = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  checkBoxChange() {
    this.checkedChange.emit(this.checked);
  }
}
