import { Component, Input, Output, EventEmitter } from "@angular/core";
import { DropDownItem } from "../dropdown/dropdown-item.model";
import { StatusLabelStyle } from "../status-label/status-label.component";

@Component({
  selector: "common-display-entity-header",
  templateUrl: "./display-entity-header.component.html",
})
export class DisplayEntityHeaderComponent {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() entityId: string;
  @Input() status: string;
  @Input() dateCreated: string;
  @Input() availableStatusList: DropDownItem[];
  @Input() secondaryLabelText: string;
  @Input() externalUrl: string;
  @Input() externalText: string;
  dropDownOrNot: boolean = true;

  @Output() statusChange = new EventEmitter<string>();

  pillStatusStyle: string = StatusLabelStyle.pill;

  constructor() { }
  
  ngOnInit() {
    let optionsArray = ['Canceled', 'Cancelled', 'Completed', 'Complete', 'On Rent']
    if (optionsArray.includes(this.status)) {
      this.dropDownOrNot = false;
    }
  }
  
  statusChanged(newStatus: string) {
    this.statusChange.emit(newStatus);
  }
}
