import { Address } from "./address.model";
import { IndexLocation } from "../../inventory/models/location.model";

export interface ILocation {
  latitude: number;
  longitude: number;
  name: string;
  address?: Address;
  properties?: any;
}

export class Location {
  latitude: number;
  longitude: number;
  name: string;
  address?: Address = new Address();
  point: any;
  properties?: any;

  constructor() {
    this.latitude = undefined;
    this.longitude = undefined;
    this.name = "";
    this.address = new Address();
  }

  static fromIndexLocation(inventoryItemPoint: IndexLocation) {
    return { latitude: inventoryItemPoint.coordinates[1], longitude: inventoryItemPoint.coordinates[0] } as Location;
  }
}
