import { Component, Input, ViewChild, TemplateRef, Output, EventEmitter } from "@angular/core";
import { DatePipe } from "@angular/common";
import { BaseApiService, SearchModel } from "../../../core/core";
import { TableUtilsService } from "../../services/table-utils.service";
import { NgbDropdownConfig } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "common-api-table",
  templateUrl: "./api-table.component.html",
  providers: [DatePipe],
})
export class ApiTableComponent {
  @Input() createButtonText: string;
  @Input() columns = [];
  @Input() rows = [];
  @Input() partitionKey: string;
  @Input() showSearchInput: boolean = true;
  @Input() apiService: BaseApiService<any>;
  @Input() searchModel: SearchModel;
  @Input() actions: any[];

  // gets called when user hits create button on the html
  @Output() createEntity = new EventEmitter();

  request = {};
  result = {};

  @ViewChild("apiTable") table;
  @ViewChild("statusTemplate", { static: true }) statusTemplate: TemplateRef<any>;
  @ViewChild("currencyTemplate", { static: true }) currencyTemplate: TemplateRef<any>;
  @ViewChild("linkTemplate", { static: true }) linkTemplate: TemplateRef<any>;
  @ViewChild("actionsTemplate", { static: true }) actionsTemplate: TemplateRef<any>;

  constructor(private tableUtils: TableUtilsService, dropdownConfig: NgbDropdownConfig) {
    dropdownConfig.placement = "bottom-right";
  }

  ngOnInit() {
    // in order to use custom templates you can pass in customTemplate: "status" or customTemplate: "currency"
    const cellTemplateMapping = {
      status: this.statusTemplate,
      currency: this.currencyTemplate,
      link: this.linkTemplate,
      actions: this.actionsTemplate,
    };

    this.columns
      .filter((d) => d.customTemplate != null)
      .forEach((column) => {
        column.cellTemplate = cellTemplateMapping[column.customTemplate];
      });

    if (this.rows.length == 0) {
      // there are certain cases where we will pass in the rows if we are using a resolver.
      // we only need to search if there are no rows search
      this.search();
    } else {
      this.tableUtils.addMobileCardFunctionality(this.columns.map((d) => d.name));
    }
  }

  search() {
    this.apiService.getAll(this.searchModel).subscribe((result) => {
      this.rows = [...result.results];
      this.tableUtils.addMobileCardFunctionality(this.columns.map((d) => d.name));
    });
  }

  linkClick(row, column) {
    if (!column.linkIdProperties) {
      console.error("must provide a property for the link id");
      return;
    }

    if (!column.linkHref) {
      console.error("must provide a link href");
      return;
    }

    const linkProperties = [];
    column.linkIdProperties.forEach((property) => {
      linkProperties.push(row[property]);
    });

    const link = this.formatLink(column.linkHref, ...linkProperties);
    window.open(link);
  }

  formatLink(link: string, ...val: string[]) {
    for (let index = 0; index < val.length; index++) {
      link = link.replace(`{${index}}`, val[index]);
    }
    return link;
  }

  getComputedActions(column, entity) {
    if (entity.computedActions) {
      return entity.computedActions;
    }

    let computedActions = [];
    for (let action of column.actions) {
      if (action.show && action.show(entity) == false) {
        continue;
      }

      computedActions.push({
        label: action.label(entity),
        disable: action.disable ? action.disable(entity) : false,
        // when we compute an action and we have a click event then we need to delete the computed actions off the entity;
        click: (entity) => {
          delete entity.computedActions;
          action.click(entity);
        },
      });
    }
    entity.computedActions = computedActions;
    return computedActions;
  }
}
