<div class="d-lg-flex align-items-center" rt="top-bar-container">
  <common-icon-button
    class="ml-auto"
    *ngIf="createButtonText"
    iconClass="icon-add"
    [buttonText]="createButtonText"
    rt="common-index-table-add-button"
    (click)="createEntity.emit()"
  >
  </common-icon-button>
</div>
<div class="mt-2" rt="table-container">
  <div class="card table-card" *ngIf="result != null">
    <ngx-datatable
      rt="table"
      #apiTable
      [rows]="rows"
      [selectionType]="undefined"
      [columns]="columns"
      [headerHeight]="50"
      [footerHeight]="125"
      [rowHeight]="58"
      columnMode="force"
    >
    </ngx-datatable>
  </div>
</div>

<ng-template #statusTemplate let-row="row" let-value="value">
  <div class="float-right float-lg-none">
    <common-status-label [status]="value"></common-status-label>
  </div>
</ng-template>

<ng-template #currencyTemplate let-row="row" let-value="value">
  <span *ngIf="value <= 0" [title]="value | currency: 'USD':'symbol':'1.0-0'">
    {{ value | currency: "USD":"symbol":"1.0-0" }}
  </span>
  <span *ngIf="value > 0" [title]="value | currency: 'USD':'symbol':'1.0-0'" class="text-green"> +{{ value | currency: "USD":"symbol":"1.0-0" }} </span>
</ng-template>

<ng-template #linkTemplate let-row="row" let-value="value" let-column="column">
  <a class="text-green cursor-pointer" (click)="linkClick(row, column)">{{ value }}</a>
</ng-template>

<ng-template #actionsTemplate let-row="row" let-value="value" let-column="column">
  <ng-template
    let-actions="computedActions"
    #selfie
    [ngTemplateOutlet]="selfie"
    [ngTemplateOutletContext]="{ computedActions: getComputedActions(column, row) }"
  >
    <div class="btn-group btn-group-action" role="group">
      <button type="button" class="btn btn-outline-gray br-0 py-0" *ngIf="actions.length" (click)="actions[0].click(row)">
        {{ actions[0].label }}
      </button>

      <div ngbDropdown container="body" class="d-inline-block" *ngIf="actions.length > 1">
        <button class="btn btn-outline-gray br-0 more-actions px-2" ngbDropdownToggle><i class="icon-carrot"></i></button>
        <div class="br-0" ngbDropdownMenu>
          <ng-container *ngFor="let action of actions | slice: 1">
            <button ngbDropdownItem (click)="action.click(row)" [disabled]="action.disable">{{ action.label }}</button>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>
