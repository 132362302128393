import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SimpleSearchComponent } from "./components/simple-search/simple-search.component";
import { SearchSuggestsComponent } from "./components/search-suggests/search-suggests.component";
import { FormsModule } from "@angular/forms";
import { FormsModule as RubblFormsModule } from "../forms/forms.module";
import { HomeSearchComponent } from "./components/home-search/home-search.component";

@NgModule({
  declarations: [SimpleSearchComponent, SearchSuggestsComponent, HomeSearchComponent],
  imports: [CommonModule, FormsModule, RubblFormsModule],
  exports: [SimpleSearchComponent, SearchSuggestsComponent, HomeSearchComponent],
})
export class SearchResultsModule {}
