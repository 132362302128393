import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MarketingCard } from '../../../marketing/components/marketing-card/marketing-card.model';

@Component({
  selector: 'common-how-it-works-modal',
  templateUrl: './how-it-works-modal.component.html',
})
export class HowItWorksModalComponent {

  modalTitle: string; //Text that shows on the very top of the modal
  subTitle: string; //Text that shows right below the firs line break
  howItWorksCards: MarketingCard[]; //List of objects where each object is a numbered column
  modalCancelText: string; //Text that shows on the bottom left button
  modalLinkText: string; //Text that shows on the bottom right button
  modalLinkAddress: string; //Url that modalLinkText redirects too

  constructor(public activeModal: NgbActiveModal) { }


}
