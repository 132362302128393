<div ngbDropdown class="form" (openChange)="openChange($event)" rt="multi-dropdown" #dropdown="ngbDropdown">
  <button type="button" [ngClass]="customClass" class="btn btn-outline-gray btn-no-box-shadow" [class.button-dropdown-open]="dropDownOpen" ngbDropdownToggle>
    <span class="input-text" *ngIf="itemsSelectedCount > 0">
      {{ selectedText }}
    </span>
    <span class="input-text text-grey" *ngIf="itemsSelectedCount == 0">{{ emptyMessage }}</span>

    <app-rotating-caret class="caret ml-auto" [rotateCaret]="dropDownOpen"></app-rotating-caret>
  </button>
  <div class="drop-down py-3" [class.form-control-dropdown-menu]="customClass == 'form-control'" ngbDropdownMenu>
    <div class="pb-3 px-3" *ngIf="enableSearch">
      <common-icon-input [placeholder]="'Search...'" [autofocus]="true" [iconClass]="'icon-mag-glass'" (keyup)="search($event)"> </common-icon-input>
    </div>
    <div class="dropdown-items">
      <div *ngIf="!includeGrouping">
        <div class="pb-2 px-3" *ngFor="let item of dropDownItems">
          <!-- This item is the same for grouped or not grouped display-->
          <app-input-checkbox (change)="onCheckboxChange(item)" [useHtml]="true" [label]="buildLabelHtml(item)" [(checked)]="item.checked">
          </app-input-checkbox>
        </div>
      </div>
      <div *ngIf="includeGrouping">
        <!-- including select all if needed-->
        <div *ngIf="includeSelectAll">
          <div class="pb-2 px-3" *ngFor="let item of filteringGroupedItems('')">
            <!-- This item is the same for grouped or not grouped display-->
            <app-input-checkbox (change)="onCheckboxChange(item)" [useHtml]="true" [label]="buildLabelHtml(item)" [(checked)]="item.checked">
            </app-input-checkbox>
          </div>
        </div>
        <div *ngFor="let groupName of groupList">
          <div class="pt-2 pl-3 mb-2 bg-grey group-header">
            {{ groupName }}
          </div>
          <div class="pb-2 px-3" *ngFor="let item of filteringGroupedItems(groupName)">
            <!-- This item is the same for grouped or not grouped display-->
            <app-input-checkbox (change)="onCheckboxChange(item)" [useHtml]="true" [label]="buildLabelHtml(item)" [(checked)]="item.checked">
            </app-input-checkbox>
          </div>
        </div>
      </div>
    </div>

    <div class="controls d-flex justify-content-between w-100 pt-2 px-3 h-100" [class.pt-3]="includeGrouping">
      <div class="w-25 mt-2">
        <a class="text-grey clear-button cursor-pointer" (click)="clear()">Clear</a>
      </div>
      <div>
        <button type="button" (click)="apply($event); dropdown.close()" class="btn btn-secondary br-0">Apply</button>
      </div>
    </div>
  </div>
</div>
