import { AuditMetadata } from "../../core/core";
import { Name } from "../../account/account";
import { Location } from "../../core/models/location.model";

export class TransportationRequest {
  rentalRequestId: string;
  rentalRequestNumber: string;
  fulfillmentRequestId: string;
  fulfillmentRequestNumber: string;
  createdDateTime: Date;
  id: string;
  accountId: string;
  accountName: string;
  needsAttentionCount: number;
  loadCount: number;
  orderId: string;
  loads: TransportationLoad[];
}

export class TransportationLoad {
  accountId: string;
  transportationRequestId: string;
  status: string;
  dropoffLocation?: LoadLocation; // for the api
  pickupLocation?: LoadLocation; // for the api
  pickup?: LoadLocation; // for the index
  dropoff?: LoadLocation; // for the index
  totalDimensions?: any;
  actualCost?: any;
  quotePrice?: number;
  quotedCost?: number;
  estimatedCost?: any;
  requiresAttention: boolean;
  routeEstimate?: any;
  partitionKey: string;
  auditMetadata: AuditMetadata;
  inventoryIds?: string[];
  loadItems: any[];
  id: string;
  isActive: boolean;
  invoice: any;
  loadInvoiceStatus: string;
  metadata?: LoadMetadata;
  currentLocation: Location;
  carrier?: LoadContact;
  scheduledDroppoff?: string;
  scheduledPickup?: string;
  quote: TransportationLoadQuote;
  geofences: [];
}

export class TransportationLoadQuote {
  quotedPrice?: number;
  quotedBy: string;
  sentDateTime?: Date;
  rubblQuote?: number;
}

export class LoadLocation {
  contacts: LoadContact[];
  carrier?: any;
  instructions: string;
  latitude: number;
  longitude: number;
  name?: any;
  address?: any;
  scheduledDate: string;
  displayName: string;
  retrievedDateTime: any;
  geofences: [];
}

export class LoadContact {
  isPrimary: boolean;
  userId: string;
  name: Name | any;
  email: string;
  phoneNumber: string;
}

export class LoadMetadata {
  OrderId: string;
  AccountId: string;
  OrderItem: string;
  InventoryItemType: string;
  OwnerAccount: string;
  OwnerAccountId: string;
  senderAccountName: string;
  senderAccountId: string;
  recipientAccountName: string;
  recipientAccountId: string;
}

export enum LoadStatus {
  Requested = "Requested",
  Quoted = "Quoted",
  Scheduled = "Scheduled",
  InTransit = "InTransit",
  ArrivingSoon = "ArrivingSoon",
  Delivered = "Delivered",
  Cancelled = "Cancelled",
}
