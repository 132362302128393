import { Injectable } from "@angular/core";
import { BaseApiService, AuthenticationService, EnvironmentConfig, Reason } from '../../core/core';
import { HttpClient } from '@angular/common/http';


declare var environment: EnvironmentConfig;
@Injectable({
  providedIn: "root",
})
export class ReasonService extends BaseApiService<Reason> {

  constructor(httpClient: HttpClient, authenticationService: AuthenticationService) {
    super(
      httpClient,
      environment.baseUrl,
      "/api/admin/v1/reason",
      true,
      authenticationService
    );
  }
}
