import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MarketingCardComponent } from "./components/marketing-card/marketing-card.component";
import { LearnMoreServicesModalComponent } from "./components/learn-more-services-modal/learn-more-services-modal.component";
import { GlossaryComponent } from "./components/glossary/glossary.component";
import { GlossarySectionComponent } from "./components/glossary-section/glossary-section.component";
import { ThingsToKnowSectionComponent } from "./components/things-to-know-section/things-to-know-section.component";
import { MediaObjectComponent } from "./components/media-object/media-object.component";
import { RouterModule } from "@angular/router";
import { ServicesCardComponent } from "./components/services-card/services-card.component";
import { GlossaryModalComponent } from "./components/glossary-modal/glossary-modal.component";
import { ServicesSectionLpComponent } from "./components/services-section-lp/services-section-lp.component";
import { FeaturedMachinesCarouselComponent } from "./components/featured-machines-carousel/featured-machines-carousel.component";
import { CardCarouselComponent } from "./components/card-carousel/card-carousel.component";
import { RubblSharedModule } from "../rubbl-shared/rubbl-shared.module";
import { AlternatingTextImageSectionComponent } from "./components/alternating-text-image-section/alternating-text-image-section.component";
import { MarketingSectionDefaultComponent } from "./components/marketing-section-default/marketing-section-default.component";
import { MarketingCardsWithNumbersComponent } from "./components/marketing-cards-with-numbers/marketing-cards-with-numbers.component";
import { ThingsToKnowCardComponent } from "./components/things-to-know-card/things-to-know-card.component";
import { DamageCoverageCardComponent } from "./components/damage-coverage-card/damage-coverage-card.component";
import { UserTestimonialComponent } from "./components/user-testimonial/user-testimonial.component";
import { UserTestimonialCarouselComponent } from "./components/user-testimonial-carousel/user-testimonial-carousel.component";
import { NgbCarousel, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FaqDropdownListComponent } from "./components/faq-dropdown-list/faq-dropdown-list.component";
import { GetInTouchFormComponent } from "./components/get-in-touch-form/get-in-touch-form.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientJsonpModule } from "@angular/common/http";
import { PhoneBannerComponent } from "./components/phone-banner/phone-banner.component";
import { MarketingHeroComponent } from "./components/marketing-hero/marketing-hero.component";

@NgModule({
  declarations: [
    MarketingCardComponent,
    LearnMoreServicesModalComponent,
    GlossaryComponent,
    GlossarySectionComponent,
    ThingsToKnowSectionComponent,
    MediaObjectComponent,
    ServicesCardComponent,
    GlossaryModalComponent,
    ServicesSectionLpComponent,
    FeaturedMachinesCarouselComponent,
    CardCarouselComponent,
    AlternatingTextImageSectionComponent,
    MarketingSectionDefaultComponent,
    MarketingCardsWithNumbersComponent,
    ThingsToKnowCardComponent,
    DamageCoverageCardComponent,
    UserTestimonialComponent,
    UserTestimonialCarouselComponent,
    FaqDropdownListComponent,
    GetInTouchFormComponent,
    PhoneBannerComponent,
    MarketingHeroComponent,
  ],
  imports: [CommonModule, RouterModule, RubblSharedModule, NgbModule, FormsModule, ReactiveFormsModule, HttpClientJsonpModule],
  exports: [
    MarketingCardComponent,
    LearnMoreServicesModalComponent,
    GlossaryComponent,
    GlossarySectionComponent,
    ThingsToKnowSectionComponent,
    MediaObjectComponent,
    ServicesCardComponent,
    GlossaryModalComponent,
    ServicesSectionLpComponent,
    FeaturedMachinesCarouselComponent,
    CardCarouselComponent,
    AlternatingTextImageSectionComponent,
    MarketingSectionDefaultComponent,
    MarketingCardsWithNumbersComponent,
    ThingsToKnowCardComponent,
    DamageCoverageCardComponent,
    UserTestimonialComponent,
    UserTestimonialCarouselComponent,
    FaqDropdownListComponent,
    GetInTouchFormComponent,
    PhoneBannerComponent,
    MarketingHeroComponent,
  ],
})
export class MarketingModule {}
