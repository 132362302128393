<ng-container *ngIf="!newEntity">
  <ng-container *ngFor="let displayDataSection of displayDataSections; last as isLast">
    <div class="d-flex align-items-center mb-2">
      <div class="subhead-1" *ngIf="displayDataSection.header">{{ displayDataSection.header }}</div>
      <common-icon-button
        *ngIf="displayDataSection.editComponent"
        class="ml-auto"
        iconClass="icon-edit"
        [buttonText]="displayDataSection.editButtonText || 'Edit'"
        (click)="edit(displayDataSection.editComponent)"
      >
      </common-icon-button>
    </div>
    <div class="row no-gutters mt-3">
      <div [ngClass]="displayItem.columnClass" class="mb-4" *ngFor="let displayItem of displayDataSection.items">
        <label [innerHtml]="displayItem.label"></label>
        <ng-container *ngIf="!displayItem.useStatusLabel; else statusLabel">
          <div *ngIf="!displayItem.href" class="font-weight-light" [ngClass]="displayItem.cellClass" [innerHtml]="displayItem.value || '&#8212;'"></div>
          <div *ngIf="displayItem.href">
            <a [href]="displayItem.href" target="_blank" [innerHtml]="displayItem.value"></a>
          </div>
        </ng-container>
        <ng-template #statusLabel>
          <common-status-label [status]="displayItem.value" labelClass="font-weight-light"></common-status-label>
        </ng-template>
        <small *ngFor="let error of displayItem.errors" class="form-text text-red">
          {{ error }}
        </small>
      </div>
    </div>
    <div class="bb-light mt-3 mb-4" *ngIf="displayDataSections.length > 1 && !isLast"></div>
  </ng-container>
</ng-container>
