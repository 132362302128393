<a class="card rental-card text-dark" [routerLink]="['./', order.id]">
  <div class="card-body d-md-flex">
    <div class="mr-md-4" *ngIf="orderPhoto">
      <div class="thumbnail-container">
        <img class="thumbnail img-thumbnail" [src]="orderPhoto" />
        <div class="thumbnail-multiple" *ngIf="order.itemCount > 1"></div>
      </div>
    </div>
    <div class="d-flex flex-column mt-md-2" [class.mt-2]="order.itemCount > 1">
      <div class="section-header mt-2">{{ order.itemCount }} Machine<ng-container *ngIf="order.itemCount > 1">s</ng-container></div>
      <ng-container *ngIf="!order.fulfillmentNumber">
        <div class="subhead-2 mt-2">Order: #{{ order.rentalRequestNumber | uppercase }}</div>
        <div class="supporting-text mt-2">
          Order Date: <span class="text-grey">{{ order.createdDateTime | date: "mediumDate" }}</span>
        </div>
        <div class="supporting-text mt-2">
          <span [innerHTML]="order?.dropoffLocation | location: 'full' : true"></span>
        </div>
      </ng-container>
      <ng-container *ngIf="order.fulfillmentNumber">
        <div class="subhead-2 mt-2">Order: #{{ order.fulfillmentNumber | uppercase }}</div>
        <div class="supporting-text mt-2">
          Order Date: <span class="text-grey">{{ order.createdDateTime | date: "mediumDate" }}</span>
        </div>
        <div class="supporting-text mt-2" *ngIf="order.items && order.items.length">
          Yard: <span class="text-grey">{{ order?.items[0]?.location }}</span>
        </div>
      </ng-container>
      <common-status-label class="mt-4" [status]="order.status" labelStyle="status-pill"></common-status-label>
    </div>
  </div>
  <div class="card-img-overlay d-flex justify-content-center align-items-center">
    <button class="btn btn-view d-flex justify-content-center align-items-center"><i class="icon-eyeball text-white mr-2"></i>View</button>
  </div>
</a>
