import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { EnvironmentConfig } from "../models/environment-config.model";
import { LogService } from "./log.service";

declare let environment: EnvironmentConfig;

@Injectable({
  providedIn: "root",
})
export class SeoService {
  clientNames = {
    "rubbl.website": "Marketplace",
    "rubbl.admin": "Admin",
    "rubbl.transportation": "Transportation",
    "rubbl.owner": "Fleet",
  };

  constructor(private title: Title, private meta: Meta) {}

  setSeo(routeData: SeoRouteData) {
    if (environment.production !== "true") {
      console.log(routeData);
      return;
    }

    if (routeData.doNotUpdateSeo === true) {
      return;
    }

    // if route data has a title set it, otherwise set it to a generic title
    if (routeData.title) {
      this.title.setTitle(`${routeData.title} | Rubbl`);
      // this.meta.updateTag({ property: "og:title", content: routeData.title }); // og title
    } else {
      const genericTitle = `${this.clientNames[environment.clientId]} | Rubbl`;
      this.title.setTitle(genericTitle);
      // this.meta.updateTag({ property: "og:title", content: genericTitle }); // og title
    }

    // set keywords
    routeData.keywords
      ? this.meta.updateTag({ name: "keywords", content: routeData.keywords })
      : this.meta.updateTag({ name: "keywords", content: "rubbl,heavy,equipment,construction,rental,rent,dirt,moving,dozer,excavator,caterpillar,near,me" });

    // if route has description set it, otherwise remove meta description
    if (routeData.description) {
      this.meta.updateTag({ name: "description", content: routeData.description });
      // this.meta.updateTag({ property: "og:description", content: routeData.description });
    } else {
      this.meta.removeTag("name=description");
      // this.meta.removeTag("property='og:description'");
    }

    // set og url
    // this.meta.updateTag({ property: "og:url", content: window.location.href });
  }
}

export class SeoRouteData {
  doNotUpdateSeo: boolean;
  title: string;
  keywords: string;
  description: string;
  ogTags: string[];
}
