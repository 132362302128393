import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "common-icon-button-secondary",
  templateUrl: "./icon-button-secondary.component.html",
  styles: [
    `
      .btn {
        border: 1px solid #b6bcc2;
      }
    `,
  ],
})
export class IconButtonSecondaryComponent implements OnInit {
  @Input() iconClass: string = "";
  @Input() buttonText: string = "";
  @Input() disabled: boolean;
  @Input() buttonClass: string;
  @Output() btnClick = new EventEmitter();
  classObj: any;

  constructor() {}
  ngOnInit() {
    this.classObj = { disabled: this.disabled ? "disabled" : "", [this.buttonClass]: this.buttonClass ? this.buttonClass : "" };
  }
}
