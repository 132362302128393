<div class="aside-cart-container p-4" rt="app-aside-cart-modal" ngbAutofocus>
  <div class="d-flex align-content-between align-items-center">
    <!-- <app-header-cart [darkTheme]="true" [disableClick]="true" rt="header-cart-aside"></app-header-cart> -->
    <h4 class="medium">Cart</h4>
    <div class="ml-auto" (click)="activeModal.dismiss('Cross click')">
      <span class="icon-close text-grey"></span>
    </div>
  </div>
  <div class="bb-light mt-2"></div>
  <div *ngIf="cart.items.length == 0" class="">
    <button type="button" class="btn btn-lg btn-block btn-no-box-shadow btn-secondary" (click)="activeModal.dismiss('continue shopping click')">
      Continue shopping
    </button>
    <div class="bb-light"></div>
    <div class="text-center pt-5 pb-5 text-grey">There’s nothing in your cart</div>
  </div>

  <button
    *ngIf="cart.items.length > 0"
    type="button"
    (click)="checkout()"
    class="btn btn-lg btn-block btn-no-box-shadow btn-secondary d-flex justify-content-between"
    (click)="activeModal.dismiss('continue shopping click')"
    [disabled]="invalidCartItems"
  >
    <span class="text-white">Checkout</span>
    <span class="text-white">{{ cart.items.length }} {{ cart.items.length == 1 ? "machine" : "machines" }}</span>
  </button>

  <div class="bb-light"></div>
  <div class="machine-container" *ngIf="cart.items.length > 0">
    <ng-container *ngFor="let cartItem of cart.items">
      <div class="mb-3" *ngIf="cartItem.status !== 'Available'">
        <common-validation-card class="mr-auto w-100" cardClass="border-warning small-card" [messages]="['This item is no longer available']" [showX]="false">
        </common-validation-card>
      </div>
      <div class="cart-item-container position-relative mb-4" [ngClass]="cartItem.status === 'Available' ? 'available' : 'unavailable'">
        <div class="cart-item media align-items-center">
          <app-thumbnail-with-x
            [imgSrc]="cartItem.imageThumbnail"
            width="125px"
            height="125px"
            (xClicked)="removeItemFromCart(cartItem.id)"
          ></app-thumbnail-with-x>
          <div class="media-body pl-3">
            <div class="bold">{{ cartItem.title }}</div>
            <div class="subhead-2 mt-1">{{ cartItem.subtitle }}</div>
            <div class="subhead-2 mt-1">{{ cartItem.durationTotal | currency: "USD":"symbol":"1.0-0" }}/{{ cartItem.durationUnit }}</div>
            <a class="cursor-pointer text-green mt-1" (click)="edit(cartItem)">Edit</a>
          </div>
        </div>
        <div class="cart-item-unavailable-overlay h-100 w-100"></div>
        <div class="x-unavailable-container" (click)="removeItemFromCart(cartItem.id)">
          <span class="icon-close"></span>
        </div>
      </div>
    </ng-container>
  </div>
</div>
