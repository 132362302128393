import { Component, OnInit, Input } from "@angular/core";
import { GroupList } from "../../../../core/core";
import { fadeInOut } from "../../../../rubbl-shared/rubbl-shared";
import { RentalRequestItem } from "../../models/rental-request-item.model";

@Component({
  selector: "common-rental-items",
  templateUrl: "./rental-items.component.html",
  animations: [fadeInOut("250ms", "250ms")],
})
export class RentalItemsComponent implements OnInit {
  @Input() rentalId: string;
  @Input() rentalItems: RentalRequestItem[];
  groupedRentalItems: any;

  constructor() {}

  ngOnInit(): void {
    this.groupedRentalItems = GroupList.groupBy("primaryType", this.rentalItems);
  }
}
