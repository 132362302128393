<button
  class="navbar-toggler d-block d-lg-none border-0"
  type="button"
  aria-controls="navbarHeader"
  aria-expanded="false"
  aria-label="Toggle navigation"
  (click)="toggleOverlay()"
>
  <span class="navbar-toggler-icon"></span>
</button>

<div ngbDropdown #myDrop="ngbDropdown" class="d-none d-lg-inline-block" display="dynamic">
  <div class="cursor-pointer account-container text-white d-flex align-items-center" ngbDropdownToggle>
    <img [src]="baseImagePath + '/pubweb/marketplace/images/icons/account.svg'" />
    <div class="ml-2" *ngIf="user">{{ user.profile.given_name }}</div>
    <app-rotating-caret [rotateCaret]="false" color="white" fontSize="10px"></app-rotating-caret>
  </div>
  <div ngbDropdownMenu class="account-dropdown" id="account-dropdown">
    <div class="arrow-up"></div>
    <ng-container *ngIf="!user">
      <button ngbDropdownItem (click)="signUp()">Sign up</button>
      <button ngbDropdownItem (click)="login()">Login</button>
    </ng-container>
    <ng-container *ngIf="user">
      <button ngbDropdownItem [routerLink]="['/manage/rentals']">My rentals</button>
      <button ngbDropdownItem (click)="goToOwner()">My machines</button>
      <button ngbDropdownItem (click)="goToInspect()">List a machine</button>
      <button ngbDropdownItem [routerLink]="['/manage/account']">Account</button>
      <button ngbDropdownItem (click)="logOut()">Log out</button>
    </ng-container>
  </div>
</div>
