<div ngbDropdown class="form" (openChange)="openChange($event)" autoClose="outside" #myDropStatus="ngbDropdown">
  <button type="button"  class="d-flex btn rounded-pill bg-white py-1 pill status-pill" 
    [class.button-dropdown-open]="dropDownOpen" ngbDropdownToggle> 
    <common-status-label [status]="status" [labelStyle]="pillStatusStyle"></common-status-label>
    <app-rotating-caret class="caret" [rotateCaret]="dropDownOpen"></app-rotating-caret>
  </button>
  <div class="drop-down" ngbDropdownMenu>
    <button type="button" ngbDropdownItem *ngFor="let item of dropDownItems" (click)="selectItem(item); myDropStatus.close();"
      [title]="item.label">
      <!-- {{item.label}} -->
      <common-status-label [status]="item.value" [labelStyle]="ddlStatusStyle"></common-status-label>
    </button>
  </div>
</div>