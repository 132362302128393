<div class="d-block d-md-flex align-items-center">
  <div class="media align-items-center mr-auto">
    <app-thumbnail-with-x height="75px" width="75px" borderRadius="50%" [imgSrc]="rentalItem.coverPhoto" hideX="true"> </app-thumbnail-with-x>
    <div class="media-body ml-3">
      <div class="d-flex">
        <h3 class="mr-3">{{ rentalItem.make }} {{ rentalItem.model }}</h3>
        <div class="d-none d-md-block">
          <common-status-label [status]="rentalItem.statusData.status" labelStyle="status-pill"></common-status-label>
        </div>
      </div>
      <div class="paragraph-text subhead-2 text-grey">
        {{ rentalItem.year }} {{ rentalItem.primaryType }} {{ attachments() }}
        <app-questionmark-tooltip *ngIf="rentalItem?.attachments?.length" class="ml-2" [displayLearnMore]="false" [text]="attachmentsToolTip()">
        </app-questionmark-tooltip>
      </div>
      <div class="paragraph-text text-grey">{{ rentalItem.inventoryItem.serialNumber }}</div>
    </div>
  </div>
  <!-- show status for mobile right here brother -->
  <div class="mt-3 d-md-none">
    <common-status-label [status]="rentalItem.statusData.status" labelStyle="status-pill"></common-status-label>
  </div>
</div>
