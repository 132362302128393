import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { routes } from './routes';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(routes, { onSameUrlNavigation: "reload", scrollPositionRestoration: "enabled", relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]
})
export class RoutesModule { }
