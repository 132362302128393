import { TransportationLoad } from "../../../transportation/transportation";
import { Charge } from "../../../cart/cart";
import { FulfillmentRequest } from "../../fulfillments/models/fulfillment-request.model";
import { RentalItemState } from "./rental-item-state.model";
import { InventoryItem } from "../../../inventory/inventory";

export class RentalRequestItem {
  id: string;
  coverPhoto?: string;
  make: string;
  model: string;
  year: string;
  usage: string;
  location: string;
  inventoryId: string;
  inventoryItem: InventoryItem;
  rubblAssetId: string;
  ownerId: string;
  ownerAccount: string;
  jobStartDate: string;
  jobEndDate: string;
  duration: string;
  jobDuration: number;
  primaryType: string;
  total: number;
  subTotal: number;
  attachments: RentalRequestAttachment[];
  chargeSummary: Charge[];
  fulfillmentRequest: FulfillmentRequest;
  rentalStage: string;
  returnRequested: boolean;
  rentalItemState: RentalItemState;
  status: string;
  serialNumber: string;
  poJobNumber: string;
  returnFulfillmentRequested: boolean;
  statusData: RentalItemStatusData;
}

export class RentalRequestAttachment {
  position: string;
  title: string;
  price: number;
  displayName: string;
  inventoryItemId: string;
  catalogId: string;
  primaryType: string;
}

export class RentalItemStatusData {
  status: string;
  bannerIcon: string;
  bannerMessage: string;
  showStartReturn: boolean;
  disableStartReturn: boolean;
}
