import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "common-header-phone",
  templateUrl: "./header-phone.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderPhoneComponent {
  constructor() {}
}
