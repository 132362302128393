import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";

import { AppComponent } from "./app.component";
import { LayoutComponent } from "./layout/layout.component";
import { RoutesModule } from "./routes/routes.module";
import { SharedModule } from "./shared/shared.module";
import { ServiceWorkerModule } from "@angular/service-worker";

@NgModule({
  declarations: [AppComponent, LayoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RoutesModule,
    SharedModule,
    FormsModule,
    ServiceWorkerModule.register("./ngsw-worker.js", {
      enabled: window.location.hostname != "localhost",
      registrationStrategy: "registerImmediately",
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
