import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Operation } from "fast-json-patch";
import { Observable } from "rxjs";
import { SearchResult } from "../models/search-result.model";
import { SearchModel } from "../models/search.model";
import { AuthenticationService } from "./authentication.service";

export abstract class BaseApiService<T> {
  protected url = `${this.baseUrl}${this.endpoint}`;

  constructor(
    protected httpClient: HttpClient,
    protected baseUrl: string,
    protected endpoint: string,
    protected useAuth: boolean = true,
    private auth: AuthenticationService,
    private bypassServiceWorker: boolean = true,
  ) {}

  get(id: string): Observable<T> {
    const headers = this.getAuth();
    return this.httpClient.get<T>(`${this.baseUrl}${this.endpoint}/${id}`, { headers });
  }

  getWithPartitionKey(id: string, partitionKey: string, partitionPath: string = "/details"): Observable<T> {
    const headers = this.getAuth();
    return this.httpClient.get<any>(`${this.baseUrl}${this.endpoint}/${id}${partitionPath}?partitionKey=${partitionKey}`, { headers });
  }

  getAll(searchModel: SearchModel): Observable<SearchResult<T>> {
    const headers = this.getAuth();
    for (const key in searchModel) {
      if (searchModel[key] === null) {
        delete searchModel[key];
      }
    }
    return this.httpClient.get<SearchResult<T>>(`${this.baseUrl}${this.endpoint}`, {
      params: searchModel as any,
      headers,
    });
  }

  create(model: T): Observable<T> {
    const headers = this.getAuth();
    return this.httpClient.post<T>(`${this.baseUrl}${this.endpoint}`, model, { headers });
  }

  update(id: string, model: T, partitionKey?: string): Observable<T> {
    const headers = this.getAuth();
    return this.httpClient.put<T>(`${this.baseUrl}${this.endpoint}/${id}${partitionKey ? "?partitionKey=" + partitionKey : ""}`, model, { headers });
  }

  patch(id: string, model: T): Observable<T> {
    const headers = this.getAuth();
    return this.httpClient.patch<T>(`${this.baseUrl}${this.endpoint}/${id}`, model, { headers });
  }

  jsonPatchWithPartitionKey(id: string, jsonPatchOperations: Operation[] | any[], partitionKey: string, partitionPath: string = ""): Observable<T> {
    const headers = this.getAuth();
    return this.httpClient.patch<T>(`${this.baseUrl}${this.endpoint}/${id}${partitionPath}?partitionKey=${partitionKey}`, jsonPatchOperations, { headers });
  }

  delete(id: string, partitionKey: string = null, partitionKeyValue: string = null, physicalDelete: boolean = false) {
    const headers = this.getAuth();

    let params = new HttpParams();
    if (partitionKeyValue) {
      params = params.append(partitionKey, partitionKeyValue);
    }

    return this.httpClient.delete(`${this.baseUrl}${this.endpoint}/${id}${physicalDelete ? "/physical" : ""}`, {
      headers,
      params,
    });
  }

  // The fallback method the subclass will call if not overridden
  protected errorHandling(): void {
    alert("Default doStuff");
  }

  protected getAuth(): HttpHeaders {
    let headers = new HttpHeaders();
    if (this.useAuth) {
      const token = this.auth.getAccessToken();
      headers = headers.append("Authorization", `Bearer ${token}`);
    }

    if (this.bypassServiceWorker) {
      // https://angular.io/guide/service-worker-devops#bypassing-the-service-worker
      headers = headers.append("ngsw-bypass", "");
    }

    return headers;
  }
}
