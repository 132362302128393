import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AzureMapsModule } from '../azure-maps/azure-maps.module';
import { MachineMapLocationComponent } from './components/machine-map-location/machine-map-location.component';
import { FormsModule } from '../forms/forms.module';
import { CoreModule } from '../core/core.module';


@NgModule({
  declarations: [
    MachineMapLocationComponent
    ],
  imports: [
    CoreModule,
    CommonModule,
    AzureMapsModule,
    FormsModule
  ],
  exports: [MachineMapLocationComponent]
})
export class TransportationModule { }
