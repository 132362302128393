import { CurrencyPipe } from "@angular/common";
import { Component, OnInit, Input } from "@angular/core";
import { KeyValue, ModalService } from "../../../../core/core";
import { CreditBillingPaymentsData, GlossaryModalComponent, MarketingCard } from "../../../../marketing/marketing";
import { RentalRequestItem } from "../../models/rental-request-item.model";
import { RentalRequest } from "../../models/rental-request.model";

@Component({
  selector: "common-rental-details-card",
  templateUrl: "./rental-details-card.component.html",
  providers: [CurrencyPipe],
})
export class RentalDetailsCardComponent implements OnInit {
  @Input() rental: RentalRequest;
  firstItem: RentalRequestItem;
  monthlyTotalTooltipData: KeyValue[];
  monthlyTotal: number;

  constructor(private modalService: ModalService, private currencyPipe: CurrencyPipe) {}

  ngOnInit(): void {
    this.firstItem = this.rental.items[0];

    const { monthlySummary } = this.rental;
    if (!monthlySummary.length) {
      return;
    }

    const rent = monthlySummary.find((d) => d.name === "Rent");
    const attachments = monthlySummary.find((d) => d.name === "Attachment");
    const services = monthlySummary.find((d) => d.name === "PremiumServices");
    const tax = monthlySummary.find((d) => d.name == "Tax");

    const subtotal = [rent, attachments, services].reduce((a, b) => a + (b ? b.amount : 0), 0);
    this.monthlyTotal = subtotal + tax?.amount;

    this.monthlyTotalTooltipData = [
      {
        key: `${this.rental.items.length} ${this.rental.items.length === 1 ? "machine" : "machines"}`,
        value: `${this.currencyPipe.transform(rent.amount, "USD", "symbol", "1.0-0")}`,
      },
    ];

    if (attachments && attachments.amount != 0) {
      this.monthlyTotalTooltipData.push({
        key: `Attachments`,
        value: `${this.currencyPipe.transform(attachments.amount, "USD", "symbol", "1.0-0")}`,
      });
    }

    this.monthlyTotalTooltipData.push({
      key: `Rubbl Managed Services`,
      value: `${this.currencyPipe.transform(services.amount, "USD", "symbol", "1.0-0")}`,
    });

    this.monthlyTotalTooltipData.push({
      key: `Subtotal`,
      value: `${this.currencyPipe.transform(subtotal, "USD", "symbol", "1.0-0")}`,
    });

    this.monthlyTotalTooltipData.push({
      key: `Taxes`,
      value: `${this.currencyPipe.transform(tax?.amount, "USD", "symbol", "1.0-0")}`,
    });

    this.monthlyTotalTooltipData.push({
      key: `Monthly Total`,
      value: `${this.currencyPipe.transform(this.monthlyTotal, "USD", "symbol", "1.0-0")}`,
    });
  }

  learnMoreAboutTerms() {
    const modalRef = this.modalService.fullPageModal(GlossaryModalComponent);
    modalRef.componentInstance.glossarySections = [CreditBillingPaymentsData];
  }
}
