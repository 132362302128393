import { CountValueFacetItem, Facet } from "./facet.model";
import { ParentChildCollectionFacet } from "./parent-child-collection-facet.model";

export class AttachmentFacet extends Facet {
  types: ParentChildCollectionFacet;
  catalogs: ParentChildCollectionFacet;
  specs: ParentChildCollectionFacet;

  constructor(name: string, types: CountValueFacetItem[], catalogs: CountValueFacetItem[], specs: CountValueFacetItem[]) {
    super(name, []);
    this.types = new ParentChildCollectionFacet("relatedAttachments/primaryType", "attachmentType", types || []);
    this.catalogs = new ParentChildCollectionFacet("relatedAttachments/typeDefinition", "attachmentCatalog", catalogs || []);
    this.specs = new ParentChildCollectionFacet("relatedAttachments/size", "attachmentSpec", specs || []);
  }

  get selected(): boolean {
    return this.types.facets.some(d => d.checked);
  }

  filter(urlParams: URLSearchParams): string {
    const typesFilter = this.types.filter(urlParams, "and");
    const selectedTypes = this.types.facets.filter(d => d.checked).map(d => d.value);
    // if types is selected we need to filter out to only show the corresponding attachment catalogs based off of the selected types
    if (this.types.selected) {
      const selectedTypes = this.types.facets.filter(d => d.checked).map(d => d.value);

      // reset catalogs facets
      this.catalogs.facets = [];
      for (const catalog of this.catalogs.allFacets) {
        const split = catalog.value.split("|");
        const typeForCatalog = split[0];

        // this catalog's type is not selected. on to the next one
        if (!selectedTypes.includes(typeForCatalog)) {
          continue;
        }

        this.catalogs.facets.push(catalog);
      }
    } else {
      this.catalogs.clear();
    }

    // gottta get the selected specs if catalog is selected
    const catalogFilter = this.catalogs.filter(urlParams, "or");
    if (this.catalogs.selected) {
      const selectedCatalogs = this.catalogs.facets.filter(d => d.checked).map(d => d.label);

      this.specs.facets = [];
      for (const spec of this.specs.allFacets) {
        const split = spec.value.split("|");
        const catalogForSpec = split[0];

        if (!selectedCatalogs.includes(catalogForSpec)) {
          continue;
        }
        spec.parentValue = catalogForSpec;
        this.specs.facets.push(spec);
      }
    } else {
      this.specs.clear();
    }

    const specsFilter = this.specs.filter(urlParams);

    const catalogsToRemove = [];
    // if we have a selected type, we need to remove all the catalogs that have the same name as the type AND if the catalog has no specs
    for (const selectedType of selectedTypes) {
      const exactAttachmentCatalogname = this.catalogs.facets.filter(d => !d.checked).find(d => d.label === selectedType);
      if (!exactAttachmentCatalogname) {
        continue;
      }

      // we have a type and catalog with the same name, lets check to see if the catalog has specs
      const firstSpec = this.specs.facets.find(d => d.parentValue === exactAttachmentCatalogname.label);
      if (firstSpec) {
        continue; // has atleast one spec, on to the next selected type
      }
      catalogsToRemove.push(exactAttachmentCatalogname.label);
    }
    // if we have any catalogs to remove lets remove them
    if (catalogsToRemove.length) {
      this.catalogs.facets = this.catalogs.facets.filter(d => !catalogsToRemove.includes(d.label));
    }

    const allAttachmentFilters = [typesFilter, catalogFilter, specsFilter].filter(d => d);
    return allAttachmentFilters.length ? `(${allAttachmentFilters.join(" and ")})` : null;
  }

  update = (types: CountValueFacetItem[], catalogs: CountValueFacetItem[], specs: CountValueFacetItem[]) => {
    this.types.update(types);
    this.catalogs.update(catalogs);
    this.specs.update(specs);
    this.filter(new URLSearchParams(window.location.search));
  };

  uncheck = () => {
    this.types.uncheck();
    this.catalogs.uncheck();
    this.specs.uncheck();
  };

  clear = () => {
    this.types.clear();
    this.catalogs.clear();
    this.specs.clear();
  };

  toQueryParam = (): any => {
    // need to update logic right here to be more specfic
    if (this.types.selected) {
    } else {
      this.catalogs.clear();
      this.specs.clear();
    }

    if (!this.catalogs.selected) {
      this.specs.clear();
    }

    return [this.types.toQueryParam(), this.catalogs.toQueryParam(), this.specs.toQueryParam()];
  };
}
