import { Injectable } from "@angular/core";
import { DatePipe } from "@angular/common";
import { LocationPipe } from "../../core/core";
import { ActivatedRoute, Router } from "@angular/router";
@Injectable({
  providedIn: "root",
})
export class TableUtilsService {
  constructor(private router: Router) {}

  viewEntity(activatedRoute: ActivatedRoute, entity: any, partitionKeyProperty?: string) {
    const queryParams = { partitionKey: entity[partitionKeyProperty] };
    this.router.navigate([`./${entity.id}`], {
      relativeTo: activatedRoute,
      queryParams,
      queryParamsHandling: "merge",
    });
  }

  addMobileCardFunctionality(tableHeaders: string[]) {
    setTimeout(() => {
      const datatableCells = document.querySelectorAll(".datatable-body-cell-label");
      let tableHeaderCount = 0;
      for (let i = 0; i < datatableCells.length; i++) {
        let cell = datatableCells[i];
        cell["dataset"]["label"] = tableHeaders[tableHeaderCount];

        tableHeaderCount++;
        if (tableHeaderCount === tableHeaders.length) {
          tableHeaderCount = 0;
        }
      }
    }, 0);
  }

  datePipe(date: string) {
    return date ? new DatePipe("en").transform(date, "shortDate") : "-";
  }

  dateTimePipe(date: string) {
    return date ? new DatePipe("en").transform(date, "short") : "-";
  }

  arrayLengthPipe(array: []): number {
    return array == null ? 0 : array.length;
  }

  locationPipe(location: Location) {
    return location ? new LocationPipe().transform(location) : "-";
  }

  nullCheckPipe(property: any) {
    return property ? property : "-";
  }
}
