import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { OrderCardComponent } from "./components/order-card/order-card.component";
import { RubblSharedModule } from "../rubbl-shared/rubbl-shared.module";
import { FormsModule } from "../forms/forms.module";
import { RouterModule } from "@angular/router";
import { CoreModule } from "../core/core.module";
import { MarketingModule } from "../marketing/marketing.module";
import { TablesModule } from "../tables/tables.module";
import { InventoryModule } from "../inventory/inventory.module";

import { OrderStatusComponent } from "./components/order-status/order-status.component";

import { FulfillmentDetailsCardComponent } from "./fulfillments/components/fulfillment-details-card/fulfillment-details-card.component";
import { FulfillmentHeaderComponent } from "./fulfillments/components/fulfillment-header/fulfillment-header.component";
import { FulfillmentItemsComponent } from "./fulfillments/components/fulfillment-items/fulfillment-items.component";
import { FulfillmentItemComponent } from "./fulfillments/components/fulfillment-item/fulfillment-item.component";
import { FulfillmentItemTransportationCardComponent } from "./fulfillments/components/fulfillment-item-transportation-card/fulfillment-item-transportation-card.component";
import { FulfillmentItemHeaderComponent } from "./fulfillments/components/fulfillment-item-header/fulfillment-item-header.component";
import { RentalHeaderComponent } from "./rentals/components/rental-header/rental-header.component";
import { RentalItemsComponent } from "./rentals/components/rental-items/rental-items.component";
import { RentalItemComponent } from "./rentals/components/rental-item/rental-item.component";
import { RentalDetailsCardComponent } from "./rentals/components/rental-details-card/rental-details-card.component";
import { RentalItemHeaderComponent } from "./rentals/components/rental-item-header/rental-item-header.component";
import { RentalItemTransportationCardComponent } from "./rentals/components/rental-item-transportation-card/rental-item-transportation-card.component";
import { UpdateRentalItemStatusComponent } from "./components/update-rental-item-status/update-rental-item-status.component";

@NgModule({
  declarations: [
    OrderCardComponent,
    OrderStatusComponent,
    FulfillmentDetailsCardComponent,
    FulfillmentHeaderComponent,
    FulfillmentItemsComponent,
    FulfillmentItemComponent,
    FulfillmentItemTransportationCardComponent,
    FulfillmentItemHeaderComponent,
    RentalHeaderComponent,
    RentalItemsComponent,
    RentalItemComponent,
    RentalDetailsCardComponent,
    RentalItemHeaderComponent,
    RentalItemTransportationCardComponent,
    UpdateRentalItemStatusComponent,
  ],
  imports: [CommonModule, FormsModule, RouterModule, RubblSharedModule, CoreModule, InventoryModule, TablesModule, NgbModule, MarketingModule],
  exports: [
    OrderCardComponent,
    OrderStatusComponent,
    FulfillmentDetailsCardComponent,
    FulfillmentHeaderComponent,
    FulfillmentItemsComponent,
    FulfillmentItemComponent,
    FulfillmentItemTransportationCardComponent,
    FulfillmentItemHeaderComponent,
    RentalHeaderComponent,
    RentalItemsComponent,
    RentalItemComponent,
    RentalDetailsCardComponent,
    RentalItemHeaderComponent,
    RentalItemTransportationCardComponent,
  ],
  providers: [DatePipe],
})
export class OrderModule {}
