import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { fadeInOut } from "../../../rubbl-shared/animations";
import { EnvironmentConfig, MarketTrackingEvents, ModalService, ThousandSuffixPipe } from "../../../core/core";
import { InventoryItem } from "../../../inventory/models/inventory-item.model";
import { Attachment } from "../../../inventory/models/attachment.model";
import { MachineCardAddToCartComponent } from "../machine-card-add-to-cart/machine-card-add-to-cart.component";
import { deepClone } from "fast-json-patch/module/core";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import { ComparisonTable } from "../../../tables/tables";
import { PaginationOptions } from "swiper/types/components/pagination";
import { InventoryService } from "../../../inventory/inventory";

declare const environment: EnvironmentConfig;

@Component({
  selector: "common-machine-card-advanced",
  templateUrl: "./machine-card-advanced.component.html",
  styleUrls: ["./machine-card-advanced.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOut("250ms", "250ms")],
  providers: [ThousandSuffixPipe],
})
export class MachineCardAdvancedComponent implements OnInit, OnChanges {
  @Input() machine: InventoryItem;
  @Input() filteredAttachments: string[] = [];
  @Input() machineIdsInCart: string[];

  machineInCart: boolean;
  MarketTrackingEvents = MarketTrackingEvents;

  fixedAttachments: Attachment[] = [];
  nonFixedAttachments: Attachment[] = [];
  availableAndSelected: Attachment[] = [];

  showExtraInfo: boolean = false;
  extraInfoHeight: string;
  comparisonTable: ComparisonTable;
  baseImagePath = environment.baseImagePath;

  @ViewChild("card") card;

  extraInfoIndex = 0;
  paginationConfig: PaginationOptions = {
    type: "bullets",
    el: ".swiper-pagination",
    bulletActiveClass: "swiper-pagination-bullet-active active-bullet",
    bulletClass: "swiper-pagination-bullet inactive-bullet",
    clickable: true,
    modifierClass: "swiper-pagination-bullets bottom-0",
  };

  extraInfoConfig: SwiperConfigInterface = {
    pagination: this.paginationConfig,
  };

  imageSwipeConfig: SwiperConfigInterface = {
    pagination: this.paginationConfig,
  };

  constructor(
    private modalService: ModalService,
    private cd: ChangeDetectorRef,
    private thousandSuffix: ThousandSuffixPipe,
    private inventoryService: InventoryService,
  ) {}

  ngOnInit() {
    if (this.machine.thumbnails.length == 1) {
      this.imageSwipeConfig.pagination = false;
    }

    // filter only available attachments
    this.machine.relatedAttachments = this.machine.relatedAttachments.filter(d => d.status === "Available");

    // sort attachments
    this.inventoryService.sortAttachments(this.machine.relatedAttachments);

    // figure out what is fixed on the attachments
    this.fixedAttachments = this.machine.relatedAttachments.filter(d => d.isFixed);
    this.nonFixedAttachments = this.machine.relatedAttachments.filter(d => !d.isFixed);

    let installFirst = true;
    for (const filteredAttachment of this.filteredAttachments) {
      const attachment = this.machine.relatedAttachments.find(d => d.size == filteredAttachment);
      if (!attachment) {
        continue;
      }
      // if we have a filtered attachment we need to select it
      attachment.selected = true;
      // lets install the first one
      if (installFirst) {
        attachment.install = true;
        installFirst = false;

        // if the attachment is standard set the cost to 0
        if (attachment.standard) {
          attachment.price = attachment.cost = 0;
        }
      }

      this.availableAndSelected.push(attachment);
    }

    // add the rental rate into discounts
    this.machine.rateSchedules.splice(2, 0, { label: "MONTH", numDays: 28, cost: this.machine.rentalRate, discount: 0, discountPercent: 0 });

    if (this.machine.rpoEnabled) {
      const rentDeduction2 = this.machine.rentalRate * 0.8 * 2;
      const rentDeduction4 = this.machine.rentalRate * 0.8 * 4;
      const rentDeduction6 = this.machine.rentalRate * 0.8 * 6;

      const adjustedPrice2 = this.machine.purchasePrice - rentDeduction2;
      const adjustedPrice4 = this.machine.purchasePrice - rentDeduction4;
      const adjustedPrice6 = this.machine.purchasePrice - rentDeduction6;

      this.comparisonTable = {
        headers: [],
        rows: [
          {
            columns: [{ label: "<b>Rental Duration</b>" }, { label: "<b>2 Mos</b>" }, { label: "<b>4 Mos</b>" }, { label: "<b>6 Mos</b>" }],
          },
          {
            columns: [
              { label: "Price" },
              { label: `$${this.thousandSuffix.transform(this.machine.purchasePrice, "1")}` },
              { label: `$${this.thousandSuffix.transform(this.machine.purchasePrice, "1")}` },
              { label: `$${this.thousandSuffix.transform(this.machine.purchasePrice, "1")}` },
            ],
          },
          {
            columns: [
              { label: "Rental Credit" },
              { label: `<span class="badge badge-pill badge-success p-1 font-weight-light">$${this.thousandSuffix.transform(rentDeduction2, "1")}</span>` },
              { label: `<span class="badge badge-pill badge-success p-1 font-weight-light">$${this.thousandSuffix.transform(rentDeduction4, "1")}</span>` },
              { label: `<span class="badge badge-pill badge-success p-1 font-weight-light">$${this.thousandSuffix.transform(rentDeduction6, "1")}</span>` },
            ],
          },
          {
            columns: [
              { label: "<b>Total</b>" },
              { label: `$${this.thousandSuffix.transform(adjustedPrice2, "1")}` },
              { label: `$${this.thousandSuffix.transform(adjustedPrice4, "1")}` },
              { label: `$${this.thousandSuffix.transform(adjustedPrice6, "1")}` },
            ],
          },
        ],
      };
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.machineIdsInCart) {
      this.machineInCart = changes.machineIdsInCart.currentValue.includes(this.machine.id);
    }
  }

  showExtraInfoclick(index: number = 0) {
    this.imageSwipeConfig.pagination = false;
    this.extraInfoHeight = this.card.nativeElement["offsetHeight"] + 10 + "px";
    this.showExtraInfo = true;

    this.extraInfoIndex = index;

    // we query for the modal body because on the search resutls map we slide out a modal.
    // if we dont have a modal body we need to add event listener to the document to hide the extra info
    const modalBody = document.querySelector(".modal-body");
    if (modalBody) {
      modalBody.addEventListener(
        "scroll",
        e => {
          this.hideExtraInfo();
        },
        { once: true },
      );
    } else {
      // add event listener to scroll
      document.addEventListener(
        "scroll",
        e => {
          this.hideExtraInfo();
        },
        { once: true },
      );
    }
  }

  hideExtraInfo() {
    this.imageSwipeConfig.pagination = this.paginationConfig;
    this.showExtraInfo = false;
    this.cd.detectChanges();
  }

  addToCart() {
    const modalRef = this.modalService.standardModal(MachineCardAddToCartComponent, true);
    modalRef.componentInstance.machine = deepClone(this.machine);
    modalRef.componentInstance.machineInCart = this.machineInCart;
    modalRef.result.then(
      result => {},
      () => {},
    );
  }
}
