import { Injectable } from "@angular/core";
import {
  BaseApiService,
  AuthenticationService,
  EnvironmentConfig,
} from "../../core/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ReasonConfiguration } from "../../core/models/reason-configuration.model";

declare var environment: EnvironmentConfig;
@Injectable({
  providedIn: "root",
})
export class ReasonsConfigurationService extends BaseApiService<ReasonConfiguration> {
  constructor(
    httpClient: HttpClient,
    authenticationService: AuthenticationService
  ) {
    super(
      httpClient,
      environment.baseUrl,
      "/api/admin/v1/reasons-configuration",
      true,
      authenticationService
    );
  }

  patchData(data) {
    const headers = this.getAuth();

    let params = new HttpParams();
    params = params.append("reasonId", data.reasonId);

    return this.httpClient.post<any>(
      `${this.baseUrl}/api/transportation/v1/request/${data.transportationRequestId}/load/${data.id}/schedule/send`,
      null,
      { headers, params }
    );
  }
}
