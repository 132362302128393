import { Component, ViewEncapsulation, Input, Output, EventEmitter, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { KeyValue, WindowService, WindowSizes } from "../../../core/core";

@Component({
  selector: "app-questionmark-tooltip",
  templateUrl: "./questionmark-tooltip.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./questionmark-tooltip.component.scss"],
})
export class QuestionmarkTooltipComponent implements OnDestroy, OnInit {
  @Input() text: string;
  @Input() tableRows: KeyValue[];
  @Input() displayLearnMore: boolean = true;
  @Input() placement: string = "top";
  @Input() mobilePlacement: string = "top";
  @Input() linkText: string;
  @Input() linkTextClass: string = "text-green";
  @Input() hideTableValueOnMobile: boolean = false;

  windowSubscription: Subscription;
  @ViewChild("t") t: NgbTooltip;

  @Output() learnMoreClick = new EventEmitter();

  constructor(private windowService: WindowService) {}

  ngOnInit() {
    this.windowSubscription = this.windowService.windowSizeBSubject.subscribe(result => {
      // if we are in mobile mode lets change the placement of the tooltip
      result === WindowSizes.Mobile ? (this.placement = this.mobilePlacement) : (this.placement = this.placement);
    });
  }

  ngOnDestroy() {
    if (this.windowSubscription) {
      this.windowSubscription.unsubscribe();
    }
  }

  open() {
    this.t.open();
  }
  learnMore() {
    this.learnMoreClick.emit();
  }
}
