import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { CancelConfirmModalComponent } from "../../../../layout/layout";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService, ModalService } from "../../../../core/core";
import { RentalItemStateService } from "../../services/rental-item-state.service";
import { RentalRequestItem } from "../../models/rental-request-item.model";
import { ChatService } from "../../../../rubbl-shared/rubbl-shared";

import { User } from "oidc-client";
import { NgbTooltip, NgbTooltipConfig } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "common-rental-item",
  templateUrl: "./rental-item.component.html",
  styleUrls: ["./rental-item.component.scss"],
  providers: [NgbTooltipConfig],
})
export class RentalItemComponent implements OnInit {
  user: User;
  learnMoreLink: string = "";
  tooltipText: string = "";
  @Input() rentalId: string;
  @Input() rentalItem: RentalRequestItem;
  @ViewChild("t") tooltip: NgbTooltip;
  startReturnToolTipOpen: boolean = false;

  constructor(
    private rentalItemStateService: RentalItemStateService,
    private toastrService: ToastrService,
    private modalService: ModalService,
    private chatService: ChatService,
    private authenticationService: AuthenticationService,
    private config: NgbTooltipConfig,
  ) {}
  ngOnInit(): void {
    this.rentalItem.statusData = this.rentalItemStateService.getRentalItemStatusData(this.rentalItem.rentalItemState);
    this.user = this.authenticationService.getUserNonAsync();
    if (this.rentalItem?.rentalItemState?.currentStage?.name === "Return Fulfillment") {
      this.learnMoreLink = "Learn more";
      this.tooltipText = "This machine will remain on rent until it begins traveling back to its destination";
    }
  }

  startReturn() {
    const modalRef = this.modalService.standardModal(CancelConfirmModalComponent, false);
    modalRef.componentInstance.confirmationTitle = "Confirm Starting Return";
    modalRef.componentInstance.confirmationMessage = `<span class="font-weight-light">Are you sure you want to start the return of the <span class="font-weight-bold">
    ${this.rentalItem.make} ${this.rentalItem.model}</span>`;
    modalRef.componentInstance.acceptButtonTitle = "Confirm";

    modalRef.result.then(
      (update: boolean) => {
        if (update) {
          this.rentalItemStateService.returnMachine(this.rentalId, this.rentalItem.inventoryId).subscribe(() => {
            this.rentalItem.statusData.bannerMessage = "Return quote processing";
            this.rentalItem.statusData.bannerIcon = "icon-left";
            this.tooltipText = "This machine will remain on rent until it begins traveling back to its destination";
            this.learnMoreLink = "Learn more";
            this.rentalItem.statusData.showStartReturn = false;
            this.rentalItem.statusData.status = "Return Fulfillment";
            this.toastrService.success("Successfully started return", null, { positionClass: "toast-top-center" });
          });
        }
      },
      () => {},
    );
  }

  contactUs() {
    this.chatService.openChat(`Hello, I need to return my machine after ${this.rentalItem.rentalItemState.onRent.daysInStage}.`);
  }

  openToolTip() {
    this.tooltip.open();
  }
  closeToolTip() {
    setTimeout(() => {
      this.tooltip.close();
    }, 1);
  }
}
