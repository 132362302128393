import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { CommonModule, DecimalPipe } from "@angular/common";
import { FormsModule as AngularFormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { InventoryModule } from "./inventory/inventory.module";
import { AzureMapsModule } from "./azure-maps/azure-maps.module";
import { AccountModule } from "./account/account.module";
import { CoreModule } from "./core/core.module";
import { LayoutModule } from "./layout/layout.module";
import { AzureSearchModule } from "./azure-search/azure-search.module";
import { FormsModule } from "./forms/forms.module";
import { SearchResultsModule } from "./search-results/search-results.module";
import { CartModule } from "./cart/cart.module";
import { TransportationModule } from "./transportation/transportation.module";
import { TablesModule } from "./tables/tables.module";
import { MarketingModule } from "./marketing/marketing.module";
import { OrderModule } from "./order/order.module";
import { InspectionsModule } from "./inspections/inspections.module";
import { BlogModule } from "./blog/blog.module";
import { EnvironmentConfig } from "./core/core";
import { BillingModule } from "./billing/billing.module";
import { RubblSharedModule } from "./rubbl-shared/rubbl-shared.module";
import { GoogleTagManagerModule } from "angular-google-tag-manager";

declare let environment: EnvironmentConfig;

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    CommonModule,
    RouterModule.forChild([]),
    AzureMapsModule,
    AzureSearchModule,
    AccountModule,
    BillingModule,
    CartModule,
    CoreModule,
    AngularFormsModule,
    ReactiveFormsModule,
    FormsModule,
    InventoryModule,
    LayoutModule,
    MarketingModule,
    SearchResultsModule,
    RubblSharedModule,
    TransportationModule,
    TablesModule,
    OrderModule,
    InspectionsModule,
    BlogModule,
    GoogleTagManagerModule.forRoot({
      id: "GTM-WD8K79F",
    }),
  ],
  exports: [
    AzureMapsModule,
    AzureSearchModule,
    AccountModule,
    BillingModule,
    CartModule,
    CoreModule,
    AngularFormsModule,
    ReactiveFormsModule,
    FormsModule,
    InventoryModule,
    LayoutModule,
    MarketingModule,
    SearchResultsModule,
    RubblSharedModule,
    TransportationModule,
    TablesModule,
    OrderModule,
    InspectionsModule,
    BlogModule,
  ],
  providers: [DecimalPipe],
})
export class RubblWebCommonModule {}
