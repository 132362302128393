import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { RentalItemStageName, RentalItemState } from "../models/rental-item-state.model";
import { RentalItemStateSearchModel } from "../models/rental-item-state-search.model";
import { AuthenticationService, BaseApiService, EnvironmentConfig, LogService, SearchResult } from "../../../core/core";
import { RentalItemStatusData } from "../models/rental-request-item.model";
import { LoadStatus } from "../../../transportation/transportation";
import { DatePipe } from "@angular/common";

declare var environment: EnvironmentConfig;

@Injectable({
  providedIn: "root",
})
export class RentalItemStateService extends BaseApiService<RentalItemState> {
  constructor(httpClient: HttpClient, authenticationService: AuthenticationService, private logService: LogService, private datePipe: DatePipe) {
    super(httpClient, environment.baseUrl, "/api/orders/v1/rental", true, authenticationService);
  }

  getRentalRequestItemState(rentalRequestId: string, inventoryId: string, partitionKey?: string): Observable<RentalItemState> {
    const headers = this.getAuth();
    return this.httpClient.get<RentalItemState>(`${this.baseUrl}${this.endpoint}/${rentalRequestId}/item/${inventoryId}/state?partitionKey=${partitionKey}`, {
      headers,
    });
  }

  getAll(searchModel: RentalItemStateSearchModel): Observable<SearchResult<RentalItemState>> {
    const headers = this.getAuth();
    for (const key in searchModel) {
      if (searchModel[key] === null) {
        delete searchModel[key];
      }
    }

    return this.httpClient.get<SearchResult<RentalItemState>>(
      `${this.baseUrl}${this.endpoint}/00000000-0000-0000-0000-000000000000/item/00000000-0000-0000-0000-000000000000/state/find`,
      { params: searchModel as any, headers },
    );
  }

  getAllWithUser(searchModel: RentalItemStateSearchModel, userAccountId: string): Observable<SearchResult<RentalItemState>> {
    const headers = this.getAuth();
    for (const key in searchModel) {
      if (searchModel[key] === null) {
        delete searchModel[key];
      }
    }

    return this.httpClient.get<SearchResult<RentalItemState>>(
      `${this.baseUrl}${this.endpoint}/00000000-0000-0000-0000-000000000000/item/00000000-0000-0000-0000-000000000000/state/find?accountId=${userAccountId}`,
      { params: searchModel as any, headers },
    );
  }

  startRent(rentalRequestId: string, itemId: string, targetDate: Date, reason: string, notes: string): Observable<any> {
    const headers = this.getAuth();

    let request = { targetDate: targetDate, reason: reason, notes: notes };
    return this.httpClient.post<any>(`${this.baseUrl}${this.endpoint}/${rentalRequestId}/item/${itemId}/onrent`, request, { headers });
  }

  returnMachine(rentalRequestId: string, itemId: string, model: { targetDate?: Date; reason?: string; notes?: string } = null): Observable<any> {
    const headers = this.getAuth();
    return this.httpClient.post<any>(`${this.baseUrl}${this.endpoint}/${rentalRequestId}/item/${itemId}/return`, model, { headers });
  }

  cancelRentalItem(rentalRequestId: string, itemId: string, reason: string, notes: string): Observable<any> {
    const headers = this.getAuth();
    let request = { reason: reason, notes: notes };
    return this.httpClient.post<any>(`${this.baseUrl}${this.endpoint}/${rentalRequestId}/item/${itemId}/cancel`, request, { headers });
  }

  completeRentalItem(rentalRequestId: string, itemId: string, targetDate: Date, notes: string): Observable<any> {
    const headers = this.getAuth();
    let request = { targetDate: targetDate, notes: notes };
    return this.httpClient.post<any>(`${this.baseUrl}${this.endpoint}/${rentalRequestId}/item/${itemId}/complete`, request, { headers });
  }

  completeRentalOrder(orderId: string): Observable<any> {
    const headers = this.getAuth();
    return this.httpClient.post<any>(`${this.baseUrl}/api/orders/v2/order/${orderId}/complete`, {}, { headers });
  }

  cancelRentalOrder(rentalItems: any[], orderId: string): Observable<any> {
    const headers = this.getAuth();
    return this.httpClient.post<any>(`${this.baseUrl}/api/orders/v2/order/${orderId}/cancel`, {}, { headers });
  }

  getRentalItemStatusData(rentalItemState: RentalItemState): RentalItemStatusData {
    let itemData = new RentalItemStatusData();
    switch (rentalItemState.currentStage.name) {
      case RentalItemStageName.Fulfillment:
        itemData = this.fulfillmentData(rentalItemState);
        break;

      case RentalItemStageName.OnRent:
        itemData = this.onRentBannerData(rentalItemState);
        break;

      case RentalItemStageName.ReturnFulfillment:
      case "Reverse Fulfillment":
        itemData = this.returnFulfillmentData(rentalItemState);
        break;

      case RentalItemStageName.Completed:
        itemData = this.completeStageStatusCheck(rentalItemState);
        break;
    }

    // if it is on rent and the return fulfillment has not been requested
    itemData.showStartReturn = rentalItemState.currentStage.code == "ST-RENT" && rentalItemState.returnFulfillmentRequested != true;
    if (rentalItemState.onRent.daysInStage < 28) {
      itemData.disableStartReturn = true;
    }

    return itemData;
  }

  completeStageStatusCheck(rentalItemState: RentalItemState): RentalItemStatusData {
    let status;
    let bannerMessage = "";
    let bannerIcon = "";

    switch (rentalItemState.status) {
      case "Cancelled":
        status = "Cancelled";
        break;
      case "Completed":
        status = "Completed";
        break;
    }

    return { bannerMessage, bannerIcon, status } as RentalItemStatusData;
  }

  fulfillmentData(rentalItemState: RentalItemState): RentalItemStatusData {
    let bannerMessage;
    let bannerIcon;
    let status = "Fulfillment";

    const load = rentalItemState.transportationLoad;
    switch (load?.status) {
      case LoadStatus.Requested:
        bannerMessage = ["Quote processing"];
        bannerIcon = "icon-right";
        break;

      case LoadStatus.Quoted:
        bannerMessage = [`Quoted for $${load.quotePrice}`];
        bannerIcon = "icon-envelope";
        break;

      case LoadStatus.Scheduled:
        bannerMessage = [`Scheduled for delivery on ${this.datePipe.transform(load.scheduledDroppoff, "mediumDate")}`];
        bannerIcon = "icon-transportation";
        break;

      case LoadStatus.InTransit:
        bannerMessage = ["On the way"];
        bannerIcon = "icon-warranty";

        break;
      case LoadStatus.ArrivingSoon:
        bannerMessage = ["Arriving Soon"];
        bannerIcon = "icon-warranty";
        break;

      case LoadStatus.Delivered:
        bannerMessage = [`Delivered on ${this.datePipe.transform(rentalItemState?.onRent?.startDateTime)}`];
        bannerIcon = "icon-transportation";
        break;

      case LoadStatus.Cancelled:
        console.error("cancelled not implemented");
        break;
    }

    return { bannerMessage, bannerIcon, status } as RentalItemStatusData;
  }

  onRentBannerData(rentalItemState: RentalItemState): RentalItemStatusData {
    let bannerMessage;
    let bannerIcon;
    let status = "On Rent";

    const onRentStage = rentalItemState.onRent;
    // if this item has been on rent for less then 3 days, show the user when the item  was delieverd
    if (onRentStage.daysInStage < 3) {
      bannerMessage = [`Delivered on ${this.datePipe.transform(onRentStage?.startDateTime)}`];
      bannerIcon = "icon-transportation";
    } else {
      bannerMessage = [`${onRentStage.daysInStage} days on rent`];
      bannerIcon = "icon-time";
    }

    return { bannerMessage, bannerIcon, status } as RentalItemStatusData;
  }

  returnFulfillmentData(rentalItemState: RentalItemState): RentalItemStatusData {
    let bannerMessage;
    let bannerIcon;
    let status;
    // when going into intransit it will set the completed date and time for the on rent stage.
    // look at the associated load and get the intransit or returned value

    const returnLoad = rentalItemState.returnTransportationLoad;
    if (!returnLoad) {
      this.logService.error(this.constructor.name, new Error("RentalItem does not have a return transportation load"));
      return { bannerMessage, bannerIcon, status: "On rent" } as RentalItemStatusData;
    }

    switch (returnLoad?.status) {
      case LoadStatus.Requested:
      case LoadStatus.Quoted:
        bannerMessage = returnLoad.quotePrice === 0 ? ["Return quote processing"] : [`Return quoted for $${returnLoad.quotePrice}`];
        bannerIcon = returnLoad.quotePrice === 0 ? "icon-left" : "icon-envelope";
        status = "On Rent";
        break;

      case LoadStatus.Scheduled:
        bannerMessage = [`Scheduled for pickup on ${this.datePipe.transform(returnLoad.scheduledPickup, "mediumDate")}`];
        bannerIcon = "icon-transportation";
        status = "On Rent";
        break;

      case LoadStatus.InTransit:
      case LoadStatus.ArrivingSoon:
        bannerMessage = ["Return in transit"];
        bannerIcon = "icon-warranty";
        status = "Return";
        break;

      case LoadStatus.Delivered:
        bannerMessage = [`Returned on ${this.datePipe.transform(rentalItemState?.onRent?.startDateTime)}`];
        bannerIcon = "icon-transportation";
        status = "Return";
        break;

      case LoadStatus.Cancelled:
        console.error("cancelled not implemented");
        break;
    }

    return { bannerMessage, bannerIcon, status } as RentalItemStatusData;
  }
}
