import { Component, Input } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "common-back-to-page-link",
  template: `<div
    class="d-inline-flex align-items-baseline text-grey supporting-text cursor-pointer"
    (click)="goBack()"
  >
    <i class="icon-arrow-left small text-grey mr-1"></i>
    {{ text }}
  </div>`,
})
export class BackToPageLinkComponent {
  @Input() text: string = "Back to list";
  @Input() urlToNavigate: string = "../";
  @Input() keepQueryParams: boolean = false;

  queryParams: any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.queryParams = this.activatedRoute.snapshot.queryParams;
  }

  goBack() {
    // remove partitionkey from the query params
    // had to clone query params because query params are read only.
    const queryParamsClone = Object.assign({}, this.queryParams);
    if (queryParamsClone.partitionKey) {
      if (!this.keepQueryParams) {
        queryParamsClone.partitionKey = null;
      }
    }

    this.router.navigate([this.urlToNavigate], {
      queryParams: queryParamsClone,
      relativeTo: this.activatedRoute,
      queryParamsHandling: "merge",
    });
  }
}
