<div ngbDropdown class="form" (openChange)="openChange($event)" autoClose="outside" #myDrop="ngbDropdown">
  <button
    type="button"
    [ngClass]="customClass"
    class="btn btn-outline-gray btn-no-box-shadow"
    [class.button-dropdown-open]="dropDownOpen"
    [disabled]="disabled"
    [attr.rt]="rt"
    ngbDropdownToggle
  >
    <div class="label-container label-main-container" [ngClass]="labelClass">
      <div class="label" [class.text-grey]="label === selectLabel" [title]="label">
        {{ label }}
      </div>
      <div *ngIf="subLabel" [ngClass]="'sub-label ' + subLabelClass">&nbsp;{{ subLabel }}</div>
    </div>
    <app-rotating-caret class="caret" [rotateCaret]="dropDownOpen"></app-rotating-caret>
  </button>
  <div class="drop-down" [class.form-control-dropdown-menu]="customClass.includes('form-control')" ngbDropdownMenu>
    <div class="px-3 py-2" *ngIf="enableSearch">
      <common-icon-input [placeholder]="'Search...'" [autofocus]="true" [iconClass]="'icon-mag-glass'" [value]="searchTerm" (keyup)="search($event.target.value)"> </common-icon-input>
    </div>
    <button type="button" ngbDropdownItem *ngFor="let item of dropDownItems" (click)="selectItem(item); myDrop.close()" [title]="item.label">
      <div class="label-container">
        <div class="label">
          {{ item.label }}
        </div>
        <div *ngIf="item.subLabel" [ngClass]="'sub-label ' + subLabelClass">&nbsp;{{ item.subLabel }}</div>
      </div>
    </button>
  </div>
</div>
