<div class="checkbox" [class.disabled]="disabled" [class.invalid]="invalid" rt='input-checkbox'>
  <label class="checkbox-label cursor-pointer" [class.large]="large">
    <input type="checkbox" [(ngModel)]="checked" (change)="checkBoxChange()" [disabled]="disabled">
    <span class="cr"
      [ngStyle]="{'background-color':checked? checkmarkColor:'white', 'border-color':checked? checkmarkColor:'#B6BCC2'}">
      <i class="cr-icon icon-checkmark"></i>
    </span>
    <span class="label-text" *ngIf="!useHtml">
      <app-star-rating *ngIf="numberOfStars != null" [numberOfStars]="numberOfStars" [disableTooltip]="true">
      </app-star-rating>
      {{label}}
    </span>
    <span class="label-text" [style.font-size]="fontSize" *ngIf="useHtml" [innerHtml]="label">
    </span>
  </label>
</div>