<form class="form show-errors" [formGroup]="paymentForm" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <div class="d-flex">
      <label class="mr-auto">Pay with</label>
      <common-payment-method-icons></common-payment-method-icons>
    </div>

    <common-dropdown customClass="form-control" [selectedValue]="paymentForm.value.paymentType"
      [dropDownItems]="paymentTypeDropDownItems" [showSelect]="true" (itemSelected)="paymentTypeSelected($event)">
    </common-dropdown>
  </div>
  <ng-container *ngIf="paymentForm.value.paymentType == 'ACH'">
    <div class="form-group">
      <label>Account holder name</label>
      <input type="text" class="form-control" formControlName="name" placeholder="Eg. Bolt Construction">
      <div *ngIf="formUtils.showInputError(paymentForm, 'name')">
        <small *ngIf="paymentForm.controls['name'].errors.required" class="form-text text-red">
          Account holder name required
        </small>
      </div>
    </div>

    <ng-container *ngIf="paymentForm.value.paymentType == 'ACH'">
      <div class="form-group">
        <label>Account holder phone number</label>
        <input type="text"  class="form-control" formControlName="phoneNumber" placeholder="Eg. 8588675309" mask="(000) 000-0000"
        [dropSpecialCharacters]="false">
        <div *ngIf="formUtils.showInputError(paymentForm, 'phoneNumber')">
          <small *ngIf="paymentForm.controls['phoneNumber'].errors.required" class="form-text text-red">
            Account holder phone number required
          </small>
        </div>
      </div>
    </ng-container>
 
    <div class="form-group">
      <label>Account type</label>
      <common-dropdown
        customClass="form-control show-errors  + {{ formUtils.showInputError(paymentForm, 'accountType') ? 'invalid' : ''}}"
        [dropDownItems]="backAccountTypeDropDownItems" [showSelect]="true" (itemSelected)="accountTypeSelected($event)">
      </common-dropdown>
      <small *ngIf="formUtils.showInputError(paymentForm, 'accountType')" class="form-text text-red">Account type
        required</small>
    </div>

   
  

    <div class="form-group">
      <label>Routing number</label>
      <input type="text" class="form-control" formControlName="routingNumber" placeholder="Eg. 123456789">
      <div *ngIf="formUtils.showInputError(paymentForm, 'routingNumber')">
        <small *ngIf="paymentForm.controls['routingNumber'].errors.required" class="form-text text-red">
          Routing number required
        </small>
        <small *ngIf="paymentForm.controls['routingNumber'].errors.qb" class="form-text text-red">
          Routing number invalid
        </small>
      </div>
    </div>

    <div class="form-group">
      <label>Account number</label>
      <input type="text" class="form-control" formControlName="accountNumber" placeholder="Eg. 1234567890">
      <div *ngIf="formUtils.showInputError(paymentForm, 'accountNumber')">
        <small *ngIf="paymentForm.controls['accountNumber'].errors.required" class="form-text text-red">
          Account number required
        </small>
        <small *ngIf="paymentForm.controls['accountNumber'].errors.qb" class="form-text text-red">
          Account number invalid
        </small>
      </div>
    </div>
    
    
    
    <ng-container *ngIf="isOwner">
      <div class="row">
        <div class="form-group col-md-6">
          <label>Use for</label>
          <div class="d-flex flex-wrap">
            <app-input-checkbox class="mr-3" label="Payments" [(checked)]="useForPayments"
              (checkedChange)="paymentUsageChange('Payments')">
            </app-input-checkbox>
            <app-input-checkbox class="" label="Earnings" [(checked)]="useForEarnings"
              (checkedChange)="paymentUsageChange('Earnings')">
            </app-input-checkbox>
          </div>

          <div *ngIf="formUtils.showInputError(paymentForm, 'usageType')">
            <small *ngIf="paymentForm.controls['usageType'].errors.required" class="form-text text-red">
              At least one must be checked.
            </small>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="paymentForm.value.paymentType == 'CreditCard'">
    <div class="form-group">
      <label>Cardholder's name</label>
      <input type="text" class="form-control" formControlName="name">
      <div *ngIf="formUtils.showInputError(paymentForm, 'name')">
        <small *ngIf="paymentForm.controls['name'].errors.required" class="form-text text-red">
          Cardholder's name required
        </small>
      </div>
    </div>

    <div class="form-group">
      <label>Card number</label>
      <input type="text" class="form-control" formControlName="cardNumber" mask="0000 0000 0000 0009">
      <div *ngIf="formUtils.showInputError(paymentForm, 'cardNumber')">
        <small *ngIf="paymentForm.controls['cardNumber'].errors.required" class="form-text text-red">
          Card number required
        </small>
        <small *ngIf="paymentForm.controls['cardNumber'].errors.mask" class="form-text text-red">
          Card number invalid
        </small>
        <small *ngIf="paymentForm.controls['cardNumber'].errors.qb" class="form-text text-red">
          Card number invalid
        </small>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-4">
        <label>Expiration <small class="text-grey">(mm/yy)</small></label>
        <input type="text" class="form-control" formControlName="expiration" mask="M0/00"
          [dropSpecialCharacters]="false">
        <div *ngIf="formUtils.showInputError(paymentForm, 'expiration')">
          <small *ngIf="paymentForm.controls['expiration'].errors.required" class="form-text text-red">
            Expiration required
          </small>
          <small *ngIf="paymentForm.controls['expiration'].errors.mask" class="form-text text-red">
            Expiration invalid
          </small>
          <small *ngIf="paymentForm.controls['expiration'].errors.qb" class="form-text text-red">
            Expiration invalid
          </small>
        </div>
      </div>
      <div class="form-group col-3">
        <label>CVV</label>
        <input type="text" class="form-control" formControlName="cVV" name="accountNumber">
        <div *ngIf="formUtils.showInputError(paymentForm, 'cVV')">
          <small *ngIf="paymentForm.controls['cVV'].errors.required" class="form-text text-red">
            CVV required
          </small>
          <small *ngIf="paymentForm.controls['cVV'].errors.qb" class="form-text text-red">
            CVV invalid
          </small>
        </div>
      </div>
      <div class="form-group col-5">
        <label>Zip Code</label>
        <input type="text" class="form-control" formControlName="zipCode" name="accountNumber">
        <div *ngIf="formUtils.showInputError(paymentForm, 'zipCode')">
          <small *ngIf="paymentForm.controls['zipCode'].errors.required" class="form-text text-red">
            Zip Code required
          </small>
          <small *ngIf="paymentForm.controls['zipCode'].errors.qb" class="form-text text-red">
            Zip Code invalid
          </small>
        </div>
      </div>
    </div>

  </ng-container>
  <button type="submit" class="d-none"></button>
</form>


<common-edit-item-footer *ngIf="showFooterSave" (save)="onSubmit()" #editItemFooter></common-edit-item-footer>