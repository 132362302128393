import { Component, OnInit, Input } from '@angular/core';
import { EnvironmentConfig } from '../../../core/core';

declare var environment: EnvironmentConfig;

@Component({
  selector: 'common-order-card',
  templateUrl: './order-card.component.html',
  styleUrls: ['./order-card.component.scss']
})
export class OrderCardComponent implements OnInit {

  @Input() order: any;
  orderPhoto: string;

  constructor() { }

  ngOnInit() {
    this.orderPhoto = environment.baseImagePath + this.order.items.find(d => d.coverPhoto !== "").coverPhoto;
    
  }

}
