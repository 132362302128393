import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { DropDownItem } from "./dropdown-item.model";

@Component({
  selector: "common-dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.scss"],
})
export class DropdownComponent implements OnInit, OnChanges {
  @Input() dropDownItems: DropDownItem[] = [];
  @Input() selectedValue: string;
  @Input() customClass: string = "form-control";
  @Input() enableSearch: boolean;
  @Input() disabled: boolean = false;
  @Input() labelClass: string = "";
  @Input() subLabelClass: string = "text-grey";
  @Input() showSelect: boolean = false;
  @Input() selectLabel: string = "Select"; // this is the first option in the dropdown if the show select flag is set
  @Input() searchTerm: string = "";
  @Input() rt: string;

  @Output() itemSelected = new EventEmitter<any>();
  @Output() selectedValueChange = new EventEmitter<any>();

  label = "";
  subLabel: string = "";
  selectedItem: DropDownItem;
  dropDownOpen: boolean;
  cachedDropDownItems: DropDownItem[];

  constructor() {}

  ngOnInit() {
    // if we want to show a default option then we need to prepend the default option to the dropdown items
    // otherwise just grab the first item in the list
    if (this.showSelect) {
      this.label = this.selectLabel;
    } else {
      this.selectedItem = this.dropDownItems[0];
      this.label = this.selectedItem.label;
    }

    if (this.dropDownItems.length === 0) {
      return;
    }

    this.cachedDropDownItems = [...this.dropDownItems];

    if (this.selectedValue != null && this.selectedValue !== "") {
      this.setSelectedItem(this.selectedValue);
      return;
    }
    if (this.searchTerm !== "") {
      this.search(this.searchTerm);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedValue) {
      this.setSelectedItem(this.selectedValue);
    }

    if (changes.dropDownItems && this.showSelect) {
      this.dropDownItems = [{ label: this.selectLabel, value: this.selectLabel }, ...this.dropDownItems];
    }
  }

  selectItem(dropDownItem: DropDownItem) {
    // if selected value/label are the same as the already selected label/value then lets bail
    if (
      (dropDownItem.value && this.selectedItem && dropDownItem.value === this.selectedItem.value) ||
      (dropDownItem.label && this.selectedItem && dropDownItem.label === this.selectedItem.label)
    ) {
      return;
    }

    this.selectedItem = dropDownItem;
    this.label = this.selectedItem.label;
    this.subLabel = this.selectedItem.subLabel;
    if (dropDownItem.action) {
      dropDownItem.action();
    }

    // if we have a value of the dropdown item we need to emit it
    if (dropDownItem.value) {
      const valueToEmit = this.selectLabel === dropDownItem.value ? "" : dropDownItem.value;
      this.itemSelected.emit(valueToEmit);
      this.selectedValueChange.emit(valueToEmit);
      return;
    }

    // for some reason we dont have a value in the dropdown item so lets just emit it
    this.itemSelected.emit(dropDownItem.label);
  }

  openChange(event: boolean) {
    this.dropDownOpen = event;
  }

  search(searchTerm: string) {
    if (!this.cachedDropDownItems) {
      return;
    }
    this.dropDownItems = this.cachedDropDownItems.filter((d) => {
      if (d.label && d.subLabel) {
        return d.label.toLowerCase().includes(searchTerm.toLowerCase()) || d.subLabel.toLowerCase().includes(searchTerm.toLowerCase());
      } else {
        return d.label.toLowerCase().includes(searchTerm.toLowerCase());
      }
    });
  }

  setSelectedItem(value) {
    this.label = "";
    this.subLabel = "";
    this.selectedItem = this.dropDownItems.find((d) => d.value === value);

    if (this.selectedItem) {
      this.label = this.selectedItem.label;
      this.subLabel = this.selectedItem.subLabel;
      this.resetSearchTerm();
      return;
    }

    if (this.showSelect) {
      this.label = this.selectLabel;
      this.selectedItem = { label: this.selectLabel };
      this.resetSearchTerm();
    }
  }

  resetSearchTerm() {
    // if the selected value matches the search term we have to wipe out the search term
    if (this.label === this.searchTerm) {
      this.searchTerm = "";
      this.search("");
    }
  }
}
