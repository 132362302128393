<ng-template #resultTemplate let-r="result">
  <ng-container>{{ r.address.freeformAddress }}</ng-container>
</ng-template>
<div class="location-typeahead-container" [ngClass]="dropdownClass" [class.show-icon]="showIcon">
  <i *ngIf="showIcon" class="icon-transportation"></i>

  <input
    id="typeahead-basic"
    type="text"
    class="form-control input-focus-border"
    [ngClass]="inputClass"
    [class.button-dropdown-open]="instance.isPopupOpen()"
    [class.padding-left]="showIcon"
    [style.width]="width"
    [placeholder]="placeHolderText"
    [(ngModel)]="model"
    [resultTemplate]="resultTemplate"
    [inputFormatter]="formatter"
    [ngbTypeahead]="search"
    [focusFirst]="true"
    (keyup.enter)="onEnter()"
    #instance="ngbTypeahead"
    autocomplete="off"
    #typeahead
  />
  <span class="focus-border" *ngIf="showFocusBorder"></span>
  <div class="dropdown-arrow-container" *ngIf="instance.isPopupOpen()">
    <img class="dropdown-arrow" [src]="environment.baseImagePath + '/pubweb/marketplace/images/icons/dropdown-arrow-up.svg'" />
  </div>
</div>
