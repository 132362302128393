import { Component } from "@angular/core";

import { Router } from "@angular/router";
import { SearchRequest } from "../../models/search-request.model";
import { Location, LogService, MarketTrackingEvents } from "../../../core/core";
import { SearchResultsService } from "../../services/search-results.service";
import { AzureSuggestsResult } from "../../../azure-search/azure-search";

@Component({
  selector: "common-home-search",
  templateUrl: "./home-search.component.html",
  styleUrls: ["./home-search.component.scss"],
})
export class HomeSearchComponent {
  inputFocused: boolean;
  searchRequest: SearchRequest = new SearchRequest();

  constructor(private router: Router, private searchResultsService: SearchResultsService, private logService: LogService) {}

  suggestionSelected($event: AzureSuggestsResult) {
    this.searchRequest.searchTerm = $event.value;
    this.searchRequest.selectedSuggestion = $event;

    // when suggestion is selected lets automatically focus to the location
    // reason for timeout is to wait for the search sugggests animation to finish
    setTimeout(() => {
      document.querySelector("#typeahead-basic")["focus"]();
    }, 250);
  }

  locationSelected(event: Location) {
    this.searchRequest.location.location = event;
  }

  search() {
    this.searchResultsService.clearSearchResults();
    const queryParams = this.searchRequest.queryParams;
    this.router.navigate([this.searchRequest.searchResultsUrl()], {
      queryParams,
    });

    this.logService.trackEvent(
      MarketTrackingEvents.Home.SearchButtonClick,
      {
        event: "rentalSearch",
        searchTerm: this.searchRequest.searchTerm,
      },
      true,
    );
  }
}
