import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "../forms/forms.module";
import { RouterModule } from "@angular/router";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { IndexTableComponent } from "./tables";
import { ApiTableComponent } from "./components/api-table/api-table.component";
import { ComparisonTableComponent } from "./components/comparison-table/comparison-table.component";
import { IndexTableFacetComponent } from "./components/index-table-facet/index-table-facet.component";
import { NgbDropdown, NgbModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  declarations: [IndexTableComponent, ApiTableComponent, ComparisonTableComponent, IndexTableFacetComponent],
  imports: [CommonModule, FormsModule, RouterModule, NgxDatatableModule, NgbModule],
  exports: [IndexTableComponent, ApiTableComponent, NgxDatatableModule, ComparisonTableComponent],
})
export class TablesModule {}
