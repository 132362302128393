import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { EnvironmentConfig, BaseApiService, AuthenticationService, Location, SearchModel, SearchResult } from "../../core/core";
import { Account, AccountStatus, AccountSummary } from "../models/account.model";
import { combineLatest, Observable, of } from "rxjs";
import { CertificateOfInsurance, BusinessContact } from "../models/certificate-of-insurance.model";
import { PaymentMethod, PaymentUsageType } from "../../billing/payments/payments";
import { PaymentUtils } from "../../billing/payments/payment-utils"; // have to import it like this or else it causes a circular dependency
import { AzureSearchRequest, AzureSearchService } from "../../azure-search/azure-search";
import { InventoryItem } from "../../inventory/inventory";
import { map, mergeMap, tap } from "rxjs/operators";
import { FulfillmentRequest } from "../../order/order";

declare var environment: EnvironmentConfig;

@Injectable({ providedIn: "root" })
export class AccountService extends BaseApiService<Account> {
  constructor(httpClient: HttpClient, authenticationService: AuthenticationService, private azureSearchService: AzureSearchService) {
    super(httpClient, environment.baseUrl, "/api/admin/v1/account", true, authenticationService);
  }

  getAccountSumary(accountId: string): Observable<AccountSummary> {
    return combineLatest([
      this.getMachineCount(accountId),
      this.getCompletedFulfillmentsCount(accountId),
      this.azureSearchService.getOne<Account>(accountId, "accounts"),
    ]).pipe(
      map(([machineCount, completedFulfillments, account]) => ({
        availableMachineCount: machineCount,
        completedFulfillmentsCount: completedFulfillments,
        ...account,
      })),
    );
  }

  getAccountSummaryByAccountName(accountName: string) {
    const searchRequest = new AzureSearchRequest();
    searchRequest.filter = `name eq '${accountName}'`;
    return this.azureSearchService.search(searchRequest, "accounts").pipe(
      mergeMap(result => {
        if (result.value.length == 0) {
          return of(null);
        }
        const account = result.value.shift() as Account;

        return combineLatest([this.getMachineCount(account.id), this.getCompletedFulfillmentsCount(account.id)]).pipe(
          map(([machineCount, completedFulfillments]) => ({
            availableMachineCount: machineCount,
            completedFulfillmentsCount: completedFulfillments,
            ...account,
          })),
        );
      }),
    );
  }

  getMachineCount(accountId: string) {
    const searchRequest = new AzureSearchRequest();
    searchRequest.filter = `status eq 'Available' and accountId eq '${accountId}'`;
    searchRequest.select = "status";

    return this.azureSearchService.search<InventoryItem>(searchRequest, "machines").pipe(
      map(result => {
        return result.odata["count"];
      }),
    );
  }

  getCompletedFulfillmentsCount(accountId: string) {
    const searchRequest = new AzureSearchRequest();
    searchRequest.filter = `status eq 'Complete' and accountId eq '${accountId}'`;
    searchRequest.select = "status";
    return this.azureSearchService.search<FulfillmentRequest>(searchRequest, "fulfillment-requests").pipe(
      map(result => {
        return result.odata["count"];
      }),
    );
  }

  addLocation(accountId: string, yard: Location) {
    const headers = this.getAuth();
    return this.httpClient.post(`${environment.baseUrl}/api/admin/v1/account/${accountId}/yards`, yard, { headers });
  }

  editLocation(accountId: string, oldYardName: string, yard: Location) {
    const headers = this.getAuth();
    return this.httpClient.put(`${environment.baseUrl}/api/admin/v1/account/${accountId}/yards/${oldYardName}`, yard, { headers });
  }

  deleteLocation(accountId: string, yardName: string) {
    const headers = this.getAuth();
    return this.httpClient.delete(`${environment.baseUrl}/api/admin/v1/account/${accountId}/yards/${yardName}`, { headers });
  }

  uploadAccountLogo(accountId: string, selectedFile): Observable<any> {
    const uploadData = new FormData();
    uploadData.append("file", selectedFile);

    const headers = this.getAuth();
    return this.httpClient.post(`${this.baseUrl}${this.endpoint}/${accountId}/logo`, uploadData, { headers });
  }

  replaceAccountLogo(accountId: string, selectedFile): Observable<any> {
    const uploadData = new FormData();
    uploadData.append("file", selectedFile);

    const headers = this.getAuth();
    return this.httpClient.put(`${this.baseUrl}${this.endpoint}/${accountId}/logo`, uploadData, { headers });
  }

  getStatuses(): Observable<string[]> {
    const statuses = [];
    for (let status in AccountStatus) {
      statuses.push(status);
    }
    return of(statuses);
  }

  getCompanyTypes(): Observable<any> {
    const companyTypes = [
      { displayName: "Contractor", color: "#FDAF09", icon: "location-pin.svg", companyType: "Contractor" },
      { displayName: "Rental Shop", color: "#f53331", icon: "location-pin.svg", companyType: "RentalCompany" },
      { displayName: "Dealers", color: "#5AD4D9", icon: "location-pin.svg", companyType: "Dealer" },
      { displayName: "Service", color: "#0184FF", icon: "location-pin.svg", companyType: "ServiceProvider" },
    ];

    return of(companyTypes);
  }

  sendForgotPassword(email) {
    const uploadData = new FormData();
    uploadData.append("EmailAddress", email);

    return this.httpClient.post(`${environment.identityProvider}/Account/ForgotPassword`, uploadData, { responseType: "text" });
  }

  rensendInvite(userId, accountId) {
    const headers = this.getAuth();
    return this.httpClient.post(`${this.baseUrl}${this.endpoint}/${userId}/${accountId}/ResendInvite`, {}, { headers, responseType: "text" });
  }

  getCertificateOfInsurance(accountId: string, coiId?: string) {
    const headers = this.getAuth();
    if (!coiId) {
      return this.httpClient.get<CertificateOfInsurance>(`${this.baseUrl}${this.endpoint}/${accountId}/coi`, { headers });
    }

    return this.httpClient.get<CertificateOfInsurance>(`${this.baseUrl}${this.endpoint}/${accountId}/coi/${coiId}/`, { headers });
  }

  setCertificateOfInsurance(accountId: string, contact: BusinessContact): Observable<any> {
    const headers = this.getAuth();
    return this.httpClient.post(`${this.baseUrl}${this.endpoint}/${accountId}/coi`, contact, { headers });
  }

  getPaymentMethods(accountId: string, searchModel: SearchModel): Observable<SearchResult<PaymentMethod>> {
    const headers = this.getAuth();
    return this.httpClient.get<SearchResult<PaymentMethod>>(`${this.baseUrl}${this.endpoint}/${accountId}/payment`, { params: searchModel as any, headers });
  }

  createPaymentMethod(accountId: string, paymentMethod: any): Observable<{ id: string }> {
    if (paymentMethod.expiration) {
      paymentMethod.expirationDate = PaymentUtils.convertExpirationToDate(paymentMethod.expiration).toDateString();
    }

    const headers = this.getAuth();
    return this.httpClient.post<{ id: string }>(`${this.baseUrl}${this.endpoint}/${accountId}/payment`, paymentMethod, { headers });
  }

  defaultPaymentMethod(accountId: string, paymentMethodId: string, paymentUsageType: PaymentUsageType): Observable<object> {
    const headers = this.getAuth();

    let params = new HttpParams();
    params = params.append("usageType", paymentUsageType);

    return this.httpClient.patch(`${this.baseUrl}${this.endpoint}/${accountId}/payment/${paymentMethodId}`, null, { headers, params });
  }

  deletePaymentMethod(accountId: string, paymentMethodId: string): Observable<object> {
    const headers = this.getAuth();
    return this.httpClient.delete(`${this.baseUrl}${this.endpoint}/${accountId}/payment/${paymentMethodId}`, { headers });
  }
}
