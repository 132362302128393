<div ngbDropdown #myDrop="ngbDropdown" class="d-none d-lg-inline-block">
  <div class="cursor-pointer" ngbDropdownToggle>
    <img [src]="baseImagePath + '/pubweb/marketplace/images/icons/account.svg'" />
    <app-rotating-caret [rotateCaret]="false" color="white" fontSize="10px"></app-rotating-caret>
  </div>
  <div ngbDropdownMenu class="account-dropdown" id="account-dropdown">
    <div class="arrow-up"></div>
    <ng-container *ngFor="let item of navigationItems">
      <a class="bold" *ngIf="item.externalLink" ngbDropdownItem [href]="item.externalLink">{{ item.label }}</a>
      <a class="bold" *ngIf="item.route" ngbDropdownItem [routerLink]="item.route">{{ item.label }}</a>
    </ng-container>
    <button ngbDropdownItem *ngIf="!isLoggedIn" (click)="logIn()">Log in</button>
    <button ngbDropdownItem *ngIf="isLoggedIn" (click)="logOut()">Log out</button>
  </div>
</div>
