import { Component } from '@angular/core';

@Component({
  selector: 'common-payment-method-icons',
  templateUrl: './payment-method-icons.component.html',
  styleUrls: ['payment-method-icons.component.scss']
})
export class PaymentMethodIconsComponent {

  constructor() { }
}
