import { Component, OnInit, Input } from "@angular/core";
import { RentalRequest } from "../../models/rental-request.model";

@Component({
  selector: "common-rental-header",
  templateUrl: "./rental-header.component.html",
})
export class RentalHeaderComponent implements OnInit {
  @Input() rental: RentalRequest;

  constructor() {}

  ngOnInit(): void {}
}
