import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BlogLayoutComponent } from "./components/blog-layout/blog-layout.component";
import { RubblSharedModule } from "../rubbl-shared/rubbl-shared.module";
import { RouterModule } from "@angular/router";
import { BlogPostListComponent } from "./components/blog-post-list/blog-post-list.component";
import { BlogPostDetailsComponent } from "./components/blog-post-details/blog-post-details.component";
import { BlogPostCardComponent } from "./components/blog-post-card/blog-post-card.component";
import { SearchResultsModule } from "../search-results/search-results.module";
import { AccountModule } from "../account/account.module";
import { BlogRelatedPostsComponent } from "./components/blog-related-posts/blog-related-posts.component";

@NgModule({
  declarations: [BlogLayoutComponent, BlogPostListComponent, BlogPostDetailsComponent, BlogPostCardComponent, BlogRelatedPostsComponent],
  imports: [CommonModule, RouterModule, RubblSharedModule, SearchResultsModule, AccountModule],
  exports: [BlogLayoutComponent],
})
export class BlogModule {}
