import { Component } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-header-learn-more',
  templateUrl: './header-learn-more.component.html',
  styleUrls: ['./header-learn-more.component.scss']
})
export class HeaderLearnMoreComponent {

  constructor(dropdownConfig: NgbDropdownConfig) {
    dropdownConfig.placement = "bottom-right";
  }

}
