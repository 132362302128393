<section class="rental-item-container mb-4">
  <common-validation-card
    *ngIf="rentalItem.statusData.bannerIcon && rentalItem.statusData.bannerMessage"
    [messages]="[rentalItem.statusData.bannerMessage]"
    [iconClass]="rentalItem.statusData.bannerIcon"
    [rightSideToolTipText]="tooltipText"
    cardClass="bg-grey bb-0"
  ></common-validation-card>
  <div class="d-flex align-items-center mt-4 mb-3" [ngClass]="{ 'mt-4': rentalItem.statusData.bannerIcon && rentalItem.statusData.bannerMessage }">
    <app-thumbnail-with-x height="100px" width="100px" borderRadius="5px" [imgSrc]="rentalItem.coverPhoto" [hideX]="true"> </app-thumbnail-with-x>
    <div class="ml-4">
      <label>
        <span class="section-header bold">{{ rentalItem.make }}</span> {{ rentalItem.model }}
      </label>
      <div>{{ rentalItem.year }} {{ rentalItem.primaryType }}</div>
      <div class="mt-2 text-grey paragraph-text">{{ rentalItem.rentalItemState.inventoryItem.serialNumber }}</div>
    </div>
    <div class="ml-5 d-none d-md-block">
      <label>Est. Job Duration</label>
      <div class="paragraph-text">{{ rentalItem.jobStartDate | date: "shortDate" }} - {{ rentalItem.jobEndDate | date: "shortDate" }}</div>
      <div class="text-grey paragraph-text mt-2">({{ rentalItem.duration }})</div>
    </div>
    <div class="ml-auto d-none d-md-block">
      <a class="d-flex align-items-center paragraph-text" [routerLink]="['item', rentalItem.inventoryId, 'transportation']"
        >View <i class="icon-arrow-right ml-1 text-green"></i
      ></a>
    </div>
  </div>

  <div class="d-flex d-md-none mt-3 mb-1">
    <div class="mr-1">
      <common-status-label [status]="rentalItem.statusData.status" labelStyle="status-pill"></common-status-label>
    </div>
    <div class="ml-4">
      <label>Est. Job Duration</label>
      <div class="paragraph-text">{{ rentalItem.jobStartDate | date: "shortDate" }} - {{ rentalItem.jobEndDate | date: "shortDate" }}</div>
      <div class="text-grey paragraph-text mt-2">({{ rentalItem.duration }})</div>
    </div>
  </div>
  <!-- tablet and desktop status and start return -->
  <div class="d-none d-md-flex">
    <div>
      <common-status-label [status]="rentalItem.statusData.status" labelStyle="status-pill"></common-status-label>
    </div>
    <div class="ml-4">
      <div *ngIf="rentalItem.statusData.showStartReturn" (click)="openToolTip()">
        <common-icon-button-secondary
          *ngIf="rentalItem.statusData.showStartReturn"
          [disabled]="rentalItem.statusData.disableStartReturn"
          (btnClick)="startReturn()"
          iconClass="icon-left"
          buttonText="Start Return"
          [ngbTooltip]="rentalItem.statusData.disableStartReturn ? htmlContent : ''"
          placement="top"
          tooltipClass="custom-tooltip"
          [autoClose]="false"
          [triggers]="'manual'"
          [buttonClass]="rentalItem.statusData.disableStartReturn ? 'cursor-pointer' : ''"
          #t="ngbTooltip"
        ></common-icon-button-secondary>
      </div>
    </div>
  </div>
  <!--  mobile view details button -->
  <div class="d-md-none mt-3">
    <common-icon-button-secondary
      iconClass="icon-eyeball"
      buttonText="View details"
      [routerLink]="['item', rentalItem.inventoryId, 'transportation']"
    ></common-icon-button-secondary>
  </div>

  <!-- mobile start return button -->
  <div class="d-md-none mt-3">
    <common-icon-button-secondary
      *ngIf="rentalItem.statusData.showStartReturn"
      [disabled]="rentalItem.statusData.disableStartReturn"
      (btnClick)="startReturn()"
      iconClass="icon-left"
      buttonText="Start Return"
      [ngbTooltip]="rentalItem.statusData.disableStartReturn ? tooltipContent : ''"
      placement="top"
      tooltipClass="custom-tooltip"
    ></common-icon-button-secondary>
  </div>
</section>

<ng-template #tooltipContent>
  <span
    >The minimum rental length is 28 days. If you need to return your machine early,
    <span class="cursor-pointer text-green bold" (click)="contactUs()"> contact us.</span></span
  >
  &nbsp;
</ng-template>

<ng-template #htmlContent>
  <div class="mt-3">
    <div class="d-flex justify-content-between">
      <!-- only show rows if there is a value -->

      <span
        >The minimum rental length is 28 days. If you need to return your machine early,
        <span class="cursor-pointer text-green bold" (click)="contactUs()"> contact us.</span></span
      >
      &nbsp;
    </div>
  </div>
  &nbsp;
  <i class="icon-close text-light-grey" (click)="closeToolTip()"></i>
</ng-template>
