import { Component, Input, OnInit, ChangeDetectorRef } from "@angular/core";
import { NgbCarouselConfig, NgbSlideEvent } from "@ng-bootstrap/ng-bootstrap";
import { EnvironmentConfig, MarketTrackingEvents } from "../../../core/core";
import { InventoryItem } from "../../models/inventory-item.model";

declare var environment: EnvironmentConfig;

@Component({
  selector: "common-machine-card",
  templateUrl: "./machine-card.component.html",
  styleUrls: ["./machine-card.component.scss"],
})
export class MachineCardComponent implements OnInit {
  @Input() machine: InventoryItem;
  machineImages: string[] = [];
  imagesToLoad: string[];

  MarketTrackingEvents = MarketTrackingEvents;

  constructor(private config: NgbCarouselConfig, private changeDetector: ChangeDetectorRef) {
    this.config.interval = 0;
    this.config.showNavigationIndicators = false;
  }

  ngOnInit() {
    // remove dupes with array from set
    this.machineImages = this.machine.thumbnails.map(path => environment.baseImagePath + path);

    // hackish way to lazy load images. ng bootstrap does not contain a way to lazy load
    // load the first two images and the last image
    // then once the user selects left or right on the carousel
    // the slide event will be called then we will load all the images;
    this.imagesToLoad = this.machineImages.slice(0, 2);
    this.imagesToLoad.push(this.machineImages[this.machineImages.length - 1]);
  }

  slideEvent(slide: NgbSlideEvent) {
    this.imagesToLoad = this.machineImages;

    // for some reason this was able to fix carousel when we use the machine card on azure maps popup
    setTimeout(() => {
      this.changeDetector.detectChanges();
    }, 0);
  }
}
