import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MachineCategoryPipe, UnitStringPipe } from "./inventory";
import { EquipmentCategoryPipe } from "./pipes/equipment-category.pipe";
import { SpecMeasurementPipe } from "./pipes/spec-measurement.pipe";
import { ChargePipe } from "./pipes/charge.pipe";
import { ShippingDimensionPipe } from "./pipes/shipping-dimension.pipe";
import { RouterModule } from "@angular/router";
import { MachineCardComponent } from "./components/machine-card/machine-card.component";
import { MachineCardHorizontalComponent } from "./components/machine-card-horizontal/machine-card-horizontal.component";
import { MachineCategoryCardComponent } from "./components/machine-category-card/machine-category-card.component";
import { CoreModule } from "../core/core.module";
import { RubblSharedModule } from "../rubbl-shared/rubbl-shared.module";
import { SwiperModule } from "ngx-swiper-wrapper";
import { NgbCarouselModule } from "@ng-bootstrap/ng-bootstrap";
import { AccountModule } from "../account/account.module";
import { MachineCardAttachmentsComponent } from "./components/machine-card-attachments/machine-card-attachments.component";
import { FormsModule } from "../forms/forms.module";
import { TablesModule } from "../tables/tables.module";
import { FormsModule as AngularForms } from "@angular/forms";
import { MachineItemRpoComponent } from "./components/machine-item-rpo/machine-item-rpo.component";

@NgModule({
  declarations: [
    MachineCategoryPipe,
    UnitStringPipe,
    EquipmentCategoryPipe,
    SpecMeasurementPipe,
    ChargePipe,
    ShippingDimensionPipe,
    MachineCardComponent,
    MachineCardHorizontalComponent,
    MachineCategoryCardComponent,
    MachineCardAttachmentsComponent,
    MachineItemRpoComponent,
  ],
  imports: [CommonModule, RouterModule, RubblSharedModule, CoreModule, NgbCarouselModule, AccountModule, FormsModule, TablesModule, AngularForms],
  exports: [
    MachineCategoryPipe,
    UnitStringPipe,
    EquipmentCategoryPipe,
    SpecMeasurementPipe,
    ChargePipe,
    ShippingDimensionPipe,
    SwiperModule,
    MachineCardComponent,
    MachineCardHorizontalComponent,
    MachineCategoryCardComponent,
    MachineItemRpoComponent,
  ],
  providers: [MachineCategoryPipe, UnitStringPipe, EquipmentCategoryPipe, SpecMeasurementPipe, ChargePipe, ShippingDimensionPipe],
})
export class InventoryModule {}
