<ng-container *ngIf="loaded">
  <h4>Rental Purchase Option</h4>
  <form>
    <div class="form-row align-items-end">
      <div class="form-group col-md">
        <label for="purchasePrice">Purchase Price</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input
            type="text"
            class="form-control"
            name="purchasePrice"
            placeholder=""
            mask="separator"
            thousandSeparator=","
            [dropSpecialCharacters]="false"
            inputmode="numeric"
            [(ngModel)]="entity.purchasePrice"
            (keyup)="onPurchasePriceChange($event.target.value)"
          />
        </div>
      </div>
      <div class="form-group col-md">
        <label for="rentalRate">Monthly Rental Rate</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input
            type="text"
            class="form-control"
            name="rentalRate"
            mask="separator"
            thousandSeparator=","
            [dropSpecialCharacters]="true"
            placeholder=""
            [(ngModel)]="entity.rentalRate"
            (keyup)="onPurchasePriceChange(entity.purchasePrice)"
          />
        </div>
      </div>
      <div class="form-group ml-md-3">
        <label class="d-block" for="rpo">RPO</label>
        <div class="form-check form-check-inline radio-button">
          <common-radio-button [id]="'attachment-modal-single-item-select'" groupName="rpo" label="Yes" [value]="true" [(model)]="entity.rpoEnabled">
          </common-radio-button>
        </div>
        <div class="form-check form-check-inline radio-button">
          <common-radio-button [id]="'attachment-modal-type-select'" groupName="rpo" label="No" [value]="false" [(model)]="entity.rpoEnabled">
          </common-radio-button>
        </div>
      </div>
      <div class="col-md mb-2">
        <button type="submit" class="btn btn-secondary mb-2" (click)="save()">Save</button>
      </div>
    </div>
  </form>
  <div class="bg-grey p-4 mt-3">
    <common-comparison-table [comparisonTable]="comparisonTable" colspan="3"></common-comparison-table>
    <common-comparison-table [comparisonTable]="comparisonTable2" colspan="3"></common-comparison-table>
    <common-comparison-table [comparisonTable]="comparisonTable3" colspan="3"></common-comparison-table>
  </div>
</ng-container>
