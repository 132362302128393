import { Component, Input, OnInit } from "@angular/core";
import { NavigationItem } from "../../models/navigation-item.model";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import { Router } from "@angular/router";

@Component({
  selector: "common-top-bar-navigation",
  templateUrl: "./top-bar-navigation.component.html",
  styleUrls: ["./top-bar-navigation.component.scss"],
})
export class TopBarNavigationComponent implements OnInit {
  @Input() navigationItems: NavigationItem[];
  @Input() routerLinkActiveOptions = { exact: false };
  @Input() preserveQueryParams: boolean = false;
  @Input() backgroundColor: string = "#FFFFFF";
  @Input() rightSideText: string;
  @Input() rightsideTextClass: string = "align-self-end ml-auto supporting-text-bold text-grey";

  swiperIndex: number;
  config: SwiperConfigInterface = {
    direction: "horizontal",
    slidesPerView: "auto",
  };

  constructor(private router: Router) {}

  ngOnInit() {
    for (const navigationItem of this.navigationItems) {
      if (this.router.url.startsWith(navigationItem.route)) {
        this.swiperIndex = this.navigationItems.indexOf(navigationItem);
        break;
      }
    }
  }
}
