import { Component, OnInit } from '@angular/core';
import { AuthenticationService, LogService, HeaderService } from '@rubbl-web-common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  title = 'admin';

  constructor(private authService: AuthenticationService, private logService: LogService, private headerService: HeaderService) {
    this.logService.info(AppComponent.name, "Starting Transportation app");
  }

  ngOnInit() {
    this.headerService.showAdminHeaderItems();
  }
}
