import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { User } from "oidc-client";

import { HeaderLearnMoreComponent } from "./header-learn-more/header-learn-more.component";
import { HeaderSignUpComponent } from "./header-sign-up/header-sign-up.component";
import { HeaderLoginComponent } from "./header-login/header-login.component";
import { HeaderAccountComponent } from "./header-account/header-account.component";
import { HeaderSearchComponent } from "./header-search/header-search.component";
import { AuthenticationService } from "../../core/core";
import { HeaderCartComponent } from "./header-cart/header-cart.component";
import { HeaderPhoneComponent } from "./header-phone/header-phone.component";

@Injectable({
  providedIn: "root",
})
export class HeaderService {
  user: User;

  rightSideHeaderItems = new BehaviorSubject([]);
  leftSideHeaderItems = new BehaviorSubject([]);

  lastMethodCalled: string;

  constructor(private authenticationService: AuthenticationService) {
    this.authenticationService.accountInfo$.subscribe(accountInfo => {
      this.user = this.authenticationService.getUserNonAsync();
      // this is such a hack and the whole header needs to be reworked to not be so custom and not have dynamic options when on different pages.
      // since it could take a bit for the user to get signed in silent we want to be able to recall the last method so it can determine what to show on the header
      if (this.lastMethodCalled) {
        this[this.lastMethodCalled]();
      }
    });
  }

  showHomePageHeaderItems() {
    const rightSideItems = [HeaderLearnMoreComponent] as any[];

    this.user
      ? rightSideItems.push(HeaderAccountComponent, HeaderPhoneComponent, HeaderCartComponent)
      : rightSideItems.push(HeaderSignUpComponent, HeaderLoginComponent, HeaderPhoneComponent, HeaderCartComponent);

    this.rightSideHeaderItems.next(rightSideItems);
    this.leftSideHeaderItems.next([]);

    this.lastMethodCalled = "showHomePageHeaderItems";
  }

  showSearchResultsHeaderItems() {
    const rightSideItems = [HeaderLearnMoreComponent, HeaderAccountComponent, HeaderPhoneComponent, HeaderCartComponent] as any[];
    this.rightSideHeaderItems.next(rightSideItems);
    this.leftSideHeaderItems.next([HeaderSearchComponent]);

    this.lastMethodCalled = "showSearchResultsHeaderItems";
  }

  showDefaultHeaderItems() {
    const rightSideItems = [HeaderLearnMoreComponent] as any[];

    this.user
      ? rightSideItems.push(HeaderAccountComponent, HeaderPhoneComponent, HeaderCartComponent)
      : rightSideItems.push(HeaderSignUpComponent, HeaderLoginComponent, HeaderPhoneComponent, HeaderCartComponent);

    this.rightSideHeaderItems.next(rightSideItems);

    this.leftSideHeaderItems.next([HeaderSearchComponent]);
    this.lastMethodCalled = "showDefaultHeaderItems";
  }

  showAdminHeaderItems() {
    this.rightSideHeaderItems.next([HeaderAccountComponent]);
    this.lastMethodCalled = "showAdminHeaderItems";
  }
}
