import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { ComparisonTable } from "./comparison-table.model";
import { EnvironmentConfig, WindowService, WindowSizes } from "../../../core/core";
import { Subscription } from "rxjs";

declare var environment: EnvironmentConfig;

@Component({
  selector: "common-comparison-table",
  templateUrl: "./comparison-table.component.html",
  styleUrls: ["./comparison-table.component.scss"],
})
export class ComparisonTableComponent implements OnInit, OnDestroy {
  @Input() comparisonTable: ComparisonTable;
  @Input() colspan: string = "2";

  windowSubscription: Subscription;
  mobile: boolean = false;
  baseImagePath: string = environment.baseImagePath;

  constructor(private windowService: WindowService) {}

  ngOnInit(): void {
    this.windowSubscription = this.windowService.windowSizeBSubject.subscribe((windowSize) => {
      this.mobile = windowSize === WindowSizes.Tablet || windowSize === WindowSizes.Mobile;
    });
  }

  ngOnDestroy() {
    this.windowSubscription.unsubscribe();
  }
}
