<div class="edit-item-footer fixed-bottom p-4">
  <div class="d-flex justify-content-center">
    <button class="btn btn-outline-gray mr-3" *ngIf="cancelPath !== ''" name="common-edit-footer-cancel"
      rt="common-edit-footer-cancel" [disabled]="loading" (click)="onCancel()">
      Cancel
    </button>
    <common-button-spinner buttonClass="btn btn-secondary edit-item-button" name="common-edit-footer-save"
      rt="common-edit-footer-save" text="Save" (click)="onSave()" [loading]="loading" #buttonSpinner>
    </common-button-spinner>
  </div>
</div>