import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { RateSchedule } from "../../../../inventory/inventory";

@Component({
  selector: "common-add-to-cart-dates",
  templateUrl: "./add-to-cart-dates.component.html",
  styleUrls: ["./add-to-cart-dates.component.scss"],
})
export class AddToCartDatesComponent implements OnInit {
  @Input() startDate: NgbDate;
  @Input() duration: number;
  @Input() rateSchedules: RateSchedule[];

  threeMonthDiscount: number;
  sixMonthDiscount: number;

  @Output() startDateSelected = new EventEmitter<NgbDate>();
  @Output() durationSelected = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {
    this.threeMonthDiscount = this.rateSchedules.find(d => d.label === "3 MOS")?.discount;
    this.sixMonthDiscount = this.rateSchedules.find(d => d.label === "6 MOS")?.discount;
  }

  onStartDateSelected(date: NgbDate) {
    this.startDateSelected.emit(date);
  }

  onDurationSelected(duration: string) {
    this.durationSelected.emit(duration);
  }
}
