import { Injectable } from "@angular/core";
import { User } from "oidc-client";
import { BehaviorSubject } from "rxjs";
import { AuthenticationService, EnvironmentConfig } from "../../../core/core";

declare let environment: EnvironmentConfig;

@Injectable({
  providedIn: "root",
})
export class ChatService {
  user: User;

  showButton$: BehaviorSubject<boolean> = new BehaviorSubject(environment.enableLiveChat !== "false");
  chatIsOpen: boolean = false;
  chatIsOpen$: BehaviorSubject<boolean> = new BehaviorSubject(this.chatIsOpen);

  constructor(private authenticationService: AuthenticationService) {
    this.authenticationService.getUser().then((user) => (this.user = user));
  }

  toggleChat() {
    if (this.chatIsOpen) {
      // close chat
    } else {
      // open chat
    }

    this.chatIsOpen = !this.chatIsOpen;
    this.chatIsOpen$.next(this.chatIsOpen);
  }

  openChat(subject: string = "") {
    this.chatIsOpen = true;
    this.chatIsOpen$.next(this.chatIsOpen);
  }
}
