import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StatusLabelStyle } from '../status-label/status-label.component';
import { DropDownItem } from '../dropdown/dropdown-item.model';

@Component({
  selector: 'common-status-label-dropdown',
  templateUrl: './status-label-dropdown.component.html',
  styleUrls: ['./status-label-dropdown.component.scss']
})
export class StatusLabelDropdownComponent implements OnInit {

  @Input() status: string;
  @Input() dropDownItems: DropDownItem[] = [];

  @Output() statusChange = new EventEmitter<string>();

  dropDownOpen: boolean;
  pillStatusStyle: string = StatusLabelStyle.btnPill;
  ddlStatusStyle: string = StatusLabelStyle.ddlItem;

  constructor() { }

  ngOnInit(): void {
  }

  openChange(event: boolean) {
    this.dropDownOpen = event;
  }

  selectItem(dropDownItem: DropDownItem) {
    if (this.status !== dropDownItem.value) {
      this.status = dropDownItem.value;
      this.statusChange.emit(this.status);
    }
  }

}
