import { Component } from "@angular/core";
import { AuthenticationService } from "../../../core/core";

@Component({
  selector: "app-header-login",
  template: '<a rt="header-login" class="nav-link d-none d-xl-block" href="" (click)="login()">Login</a>',
})
export class HeaderLoginComponent {
  constructor(private auth: AuthenticationService) {}

  login() {
    this.auth.login();
  }
}
