import { Component } from "@angular/core";
import { AuthenticationService } from "../../../core/services/authentication.service";

@Component({
  selector: "app-header-sign-up",
  template: '<a class="nav-link" rt="header-sign-up" href="" (click)="signUp()">Sign up</a>',
})
export class HeaderSignUpComponent {
  constructor(private authenticationService: AuthenticationService) {}

  signUp() {
    this.authenticationService.signUp();
  }
}
