import { Pipe, PipeTransform } from "@angular/core";
import { AddressPipe } from "./address-pipe";
import { Location } from "../models/location.model";
import { IndexLocation } from "../../inventory/models/location.model";

@Pipe({
  name: "location",
})
export class LocationPipe implements PipeTransform {
  constructor() {}

  transform(location: Location | IndexLocation | any, addressType: string = "full", useHtml: boolean = true): any {
    if (location == null) {
      return "";
    }

    // use address pipe to return the address
    if (location.address != null && !location.address.empty) {
      return new AddressPipe().transform(location.address, addressType, useHtml);
    }

    // otherwise lets return the gps coordinates.
    // the index has different properties as from the api
    if (location.location) {
      // this is the index one. index has a child property of location that contains latidude and longitude
      location = location.location;
    }

    // lets check for the point coordinates if its using the transpo request index
    if (location.point?.coordinates) {
      location.longitude = location.point.coordinates[0];
      location.latitude = location.point.coordinates[1];
    }

    if(useHtml) {
      return `${location.latitude}, <br/>${location.longitude}`;
    }

    return `${location.latitude}, ${location.longitude}`;
  }
}
