import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'common-icon-input',
  templateUrl: './icon-input.component.html',
  styleUrls: ['./icon-input.component.scss']
})
export class IconInputComponent {

  @Input() value: string = '';
  @Input() placeholder: string = '';
  @Input() iconClass: string = '';
  @Input() autofocus: boolean = false;

  @Output() valueChange = new EventEmitter<string>();
  @Output() keyUp = new EventEmitter<string>();

  constructor() { }

  keyUpEvent() {
    this.valueChange.emit(this.value);
    this.keyUp.emit(this.value);
  }
}
