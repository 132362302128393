import { AuditMetadata, SearchModel } from "../../core/core";
import { TransportationInfo, TransportationEstimate, JobsiteContact } from "../../transportation/transportation";
import { Charge } from "../cart";
import { RelatedItem } from "./related-item";
import { Attachment, InventoryItemSummary } from "../../inventory/inventory";

export class Cart {
  id: string = "";
  accountId: string;
  userId: string;
  rentalTotal: number;
  activeStatus: string;
  preventativeMaintenanceTotal: number;
  grandTotal: number;
  cartSummary: CartSummary;
  monthlySummary: CartSummary;
  items: CartItem[] = [];
  transportationInfo: TransportationInfo;
  paymentContacts: JobsiteContact[];
  formattedDuration: string;
  auditMetadata: AuditMetadata;
  isActive: boolean;
  emptyAttachments: RelatedItem[];
  poNumber: string;

  static monthlyTotal(monthlySummary: CartSummary) {
    if (!monthlySummary) {
      return 0;
    }

    return Object.values(monthlySummary).reduce((a, b) => a + b);
  }

  static monthlySubTotal(monthlySummary: CartSummary) {
    if (!monthlySummary) {
      return 0;
    }

    let monthlySubTotal = 0;
    for (const charge of Object.keys(monthlySummary)) {
      if (charge === "Tax") {
        continue;
      }
      monthlySubTotal += monthlySummary[charge];
    }
    return monthlySubTotal;
  }
}

export class CartSummary {
  Rent?: number;
  Maintenance?: number;
  Attachment?: number;
  PremiumServices?: number;
  Tax?: number;
}

export class CartItem {
  id: string;
  status: string;
  accountId: string;
  title: string;
  subtitle: string;
  inventoryItem: InventoryItemSummary;
  imageThumbnail: string;
  beginDate: string;
  endDate: string;
  jobDuration: number;
  duration: number; // number of days
  formattedDuration: string;
  descriptors: string[];
  rentSubtotal: number;
  maintenanceSubtotal: number;
  total: number;
  dailyRentalRate: number;
  charges: Charge[];
  chargeSummary: CartSummary;
  monthlySummary: CartSummary;
  weeklySummary: CartSummary;
  dailySummary: CartSummary;
  relatedItemTitle: string;
  attachments: Attachment[];
  transportationEstimateSummary?: TransportationEstimate;
  durationUnit: "day" | "week" | "month"; // client generated
  durationTotal: number; // client generated

  constructor(beginDate: Date, durationInDays: number, item: any, attachments: any[]) {
    this.beginDate = beginDate.toDateString();
    this.inventoryItem = new InventoryItemSummary(item.id, item.catalogItemId, item.accountId);
    this.duration = durationInDays;
    const formattedDurations = {
      7: "1 week",
      14: "2 weeks",
      28: "1+ month",
      84: "3+ months",
      168: "6+ months",
    };
    this.formattedDuration = formattedDurations[durationInDays];

    //formulate formatted duration here
    this.attachments = attachments;
  }

  static getEndDate(beginDate: Date, jobDuration: number): string {
    return new Date(beginDate.setMonth(beginDate.getMonth() + jobDuration)).toDateString();
  }
}

export class CartSearchModel extends SearchModel {
  userId: string;
  active: boolean = true;
}
