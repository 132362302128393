<!--  this component has some logic if the title is null. if the title is null we use just the body and have the icon next to the body -->
<div class="mb-2" [class.mb-4]="marketingCard.title">
  <div class="d-flex align-items-center mb-2" *ngIf="marketingCard.title">
    <i class="mr-2 media-icon text-primary {{ marketingCard.iconClass }}"></i>
    <span class="service-title align-top bold">{{ marketingCard.title }}</span>
  </div>
  <div class="font-weight-light" [class.d-flex]="!marketingCard.title" [class.indent]="indentBody && marketingCard.title">
    <i *ngIf="!marketingCard.title" class="mr-2 media-icon {{ marketingCard.iconClass }}"></i>

    <p class="mt-1" *ngIf="marketingCard.body">
      <span [innerHtml]="marketingCard.body"></span>
      <a *ngIf="marketingCard.linkText" [routerLink]="marketingCard.linkUrl"> {{ marketingCard.linkText }}</a>
    </p>

    <ng-container *ngIf="marketingCard.listItems">
      <ul class="list-items mb-2">
        <li *ngFor="let listItem of marketingCard.listItems">{{ listItem }}</li>
      </ul>
      <a *ngIf="marketingCard.linkText && marketingCard.linkUrl" [routerLink]="marketingCard.linkUrl">{{ marketingCard.linkText }}</a>
      <a href="" *ngIf="marketingCard.linkText && marketingCard.linkAction" (click)="action(marketingCard)">{{ marketingCard.linkText }}</a>
    </ng-container>
  </div>
</div>
