import { Component, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'common-edit-item-footer',
  templateUrl: './edit-item-footer.component.html',
  styleUrls: ['./edit-item-footer.component.scss']
})
export class EditItemFooterComponent {

  @Input() cancelPath = '../';
  @Output() save = new EventEmitter<void>();

  loading: boolean = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  onSave() {
    this.save.emit();
  }

  onCancel() {
    this.router.navigate([this.cancelPath], { relativeTo: this.activatedRoute });
  }
}
