import { CurrencyPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { KeyValue } from "../../../core/core";
import { Attachment } from "../../models/attachment.model";

@Component({
  selector: "common-machine-card-attachments",
  templateUrl: "./machine-card-attachments.component.html",
  styleUrls: ["./machine-card-attachments.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MachineCardAttachmentsComponent implements OnInit {
  @Input() attachments: Attachment[];
  firstTwoAttachments: Attachment[];

  fixedAttachments: Attachment[];

  tooltipData: KeyValue[];
  attachmentString: string;

  constructor(private currencyPipe: CurrencyPipe) {}

  ngOnInit(): void {
    console.error("Obsolete");
    this.attachments = this.attachments.filter(d => d.status === "Available");
    const fixedAttachments = this.attachments.filter(d => d.isFixed);
    const nonFixedAlphabetical = this.attachments
      .filter(d => !d.isFixed)
      .sort(function (a, b) {
        var textA = a.displayName.toUpperCase();
        var textB = b.displayName.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });

    // put the fixed ones first then, non fixed by alphabetical order
    // this.attachments = [...fixedAttachments, ...nonFixedAlphabetical];
    // only show first two attachments
    this.firstTwoAttachments = this.attachments.slice(0, 2);

    // if we have more then two attachments show all of them in the table data
    if (this.attachments.length > 2) {
      this.tooltipData = this.attachments.map(a => {
        const price = a.cost === 0 ? "Included" : `+ ${this.currencyPipe.transform(a.cost, "USD", "symbol", "1.0-0")}/mo`;
        return { key: `${a.displayName}`, value: price };
      });
    }
  }
}
