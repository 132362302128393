import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver, ViewChild, ViewContainerRef } from "@angular/core";
import { MachineCardComponent, MachineCategoryCardComponent } from "../../../inventory/inventory";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import { Subscription } from "rxjs";
import { WindowService } from "../../../core/core";
import { MachineCardAdvancedComponent } from "../../../cart/cart";

@Component({
  selector: "app-card-carousel",
  templateUrl: "./card-carousel.component.html",
  styleUrls: ["./card-carousel.component.scss"],
})
export class CardCarouselComponent implements OnInit, OnDestroy {
  @Input() header: string;
  @Input() slidesPerViewConfig = {
    mobile: 2,
    tablet: 3,
    desktop: 4,
    "large-desktop": 5,
    "extra-large-desktop": 5,
    "extra-extra-large-desktop": 5,
  };

  @Input() cardComponentName: string;
  @Input() cardData: any[];

  @ViewChild("swiperContainer", { read: ViewContainerRef, static: true }) swiperContainer;

  config: SwiperConfigInterface = {
    direction: "horizontal",
    slidesPerView: 5,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: false,
    spaceBetween: 15,
    fadeEffect: {
      crossFade: true,
    },
  };

  componentMap = {
    MachineCategoryCardComponent: MachineCategoryCardComponent,
    MachineCardComponent: MachineCardComponent,
    MachineCardAdvancedComponent: MachineCardAdvancedComponent,
  };

  windowSizeSubscription: Subscription;

  constructor(private windowService: WindowService, private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit() {
    this.loadComponents();
    this.windowSizeSubscription = this.windowService.windowSizeBSubject.subscribe(windowSize => {
      this.config.slidesPerView = this.slidesPerViewConfig[windowSize];
    });
  }

  ngOnDestroy() {
    this.windowSizeSubscription.unsubscribe();
  }

  loadComponents() {
    const factory = this.componentFactoryResolver.resolveComponentFactory(this.componentMap[this.cardComponentName]);
    for (let data of this.cardData) {
      const compRef = this.swiperContainer.createComponent(factory);
      compRef.location.nativeElement.className = "swiper-slide";

      for (let key in data) {
        compRef.instance[key] = data[key];
      }
    }
  }
}
