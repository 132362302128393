<div class="modal-header" rt="cancel-accept-modal-header">
  <div></div>
  <h4 class="mb-0">
      {{confirmationTitle}}
  </h4>
  <i class="icon-close text-grey" id="icon-close" (click)="activeModal.dismiss()"></i>
</div>
<div class="modal-body px-4 pt-4 pb-2" [ngClass]="{'pb-5' : !confirmationSubText}" rt="cancel-accept-modal-body" [innerHTML]="confirmationMessage"></div>
<div *ngIf="confirmationSubText" class="pb-5 px-4 font-weight-light text-grey" [innerHTML]="confirmationSubText"></div>

<div class="modal-footer d-flex justify-content-center">
  <button type="button" class="btn btn-outline-dark btn-modal-footer mr-4" id="cancel-accept-modal-cancel" (click)="activeModal.dismiss()">
    {{cancelButtonTitle}}
  </button>
  <button type="button" class="btn btn-modal-footer text-white bg-black" [ngClass]="{'bg-green' : acceptButtonTitle === 'Confirm' || acceptButtonTitle === 'Update'}" id="cancel-accept-modal-accept" (click)="activeModal.close(true)">
    {{acceptButtonTitle}}
  </button>
</div>