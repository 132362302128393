import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { AzureSearchService, AzureSearchRequest, AzureSearchResult } from "../../azure-search/azure-search";
import { InventoryItem } from "../../inventory/inventory";

import { ActivatedRoute, Params, Router } from "@angular/router";
import { MapBounds } from "../models/facets/map-facet.model";
import { SearchRequest } from "../models/search-request.model";
import { LocationFacet } from "../models/facets/location-facet.model";

const PageSize = 25;
const IndexName = "machines";

@Injectable({
  providedIn: "root",
})
export class SearchResultsService {
  private azureSearchRequest = new AzureSearchRequest();
  private currentPageSize = PageSize;
  private totalInventoryItems;

  public readonly searchRequest$ = new Subject<SearchRequest>();
  searchRequest = new SearchRequest();

  public readonly searchResults$ = new Subject<AzureSearchResult<InventoryItem>>();
  private searchResults = new AzureSearchResult<InventoryItem>();

  ownerSearch: boolean = false;

  constructor(private azureSearchService: AzureSearchService, private router: Router, private route: ActivatedRoute) {
    this.azureSearchRequest.top = PageSize;
    this.azureSearchRequest.facets = [
      "make, count:200, sort:value",
      "model, count:400, sort:value",
      "primaryType, count: 200, sort:value",
      "typeDefinition, count: 200, sort:value",
      "rpoEnabled",
      "weight,interval:1",
      "rating, interval:1",
      "hours,interval:100",
      "year, interval:1",
      "enginePower, interval: 1",
      "relatedAttachments/size, count: 200, sort:value",
      "relatedAttachments/typeDefinition, count: 200, sort:value",
      "relatedAttachments/primaryType, count: 200, sort:value",
    ];
    this.azureSearchRequest.searchFields = "make,model,primaryType,account/name,typeDefinition,displayName";
    this.azureSearchRequest.searchMode = "all";
  }

  search(searchTerm: string, location: LocationFacet, top: number = 25, newSearch: boolean = false) {
    this.clearSearchResults();

    this.searchRequest.searchTerm = searchTerm;
    this.searchRequest.location = location;
    this.azureSearchRequest.search = searchTerm ? `${searchTerm} | ${searchTerm}* | "${searchTerm}"` : "";

    // if it is a new search, such as a user going straight to the search results page, or doing a search from the page, just filter for status equal available
    this.azureSearchRequest.filter = newSearch ? "(status eq 'Available')" : this.searchRequest.filter;
    this.azureSearchRequest.orderby = this.searchRequest.order;
    this.azureSearchRequest.top = top;

    this.azureSearchService.search<InventoryItem>(this.azureSearchRequest, IndexName).subscribe((result: AzureSearchResult<InventoryItem>) => {
      // if it is not a new search(consective search) then just finish the results, otherwise we need to process all the persistant facet logic
      this.searchRequest.initializeFacets(result.search.facets);
      this.azureSearchRequest.filter = this.searchRequest.filter;
      if (!newSearch || this.searchRequest.facetSelectedCount === 0) {
        this.searchFinished(result);
        return;
      }

      // the reason for this logic is because azure search index only includes facets for your search results, and not for the whole result set
      if (this.searchRequest.primaryType.selected) {
        this.primaryTypeSelected();
      } else if (this.searchRequest.make.selected) {
        this.makeSelected();
      } else {
        this.lastSearch();
      }
    });
  }

  primaryTypeSelected() {
    this.searchRequest.attachment.clear(); // always clear attachment

    // if type def is selected we need to clear it, otherwise it will show all type definitions
    if (this.searchRequest.typeDefinition.selected) {
      this.searchRequest.typeDefinition.clear();
      this.azureSearchRequest.filter = this.searchRequest.filter;
    }

    // if make or model is selected we need to wipe them out and re apply the filter
    if (this.searchRequest.make.selected || this.searchRequest.model.selected) {
      this.searchRequest.make.clear();
      this.searchRequest.model.clear();
      this.azureSearchRequest.filter = this.searchRequest.filter;
    }

    this.azureSearchService.search<InventoryItem>(this.azureSearchRequest, IndexName).subscribe((result: AzureSearchResult<InventoryItem>) => {
      this.searchRequest.initializeFacets(result.search.facets);
      this.azureSearchRequest.filter = this.searchRequest.filter;

      // if make is selected we need to wipe out model in the
      if (this.searchRequest.make.selected) {
        this.makeSelected();
        // if model is selected or type def selected we need to make one more god damn search
      } else if (this.searchRequest.model.selected || this.searchRequest.typeDefinition.selected) {
        this.lastSearch();
      } else {
        this.searchFinished(result);
      }
    });
  }

  makeSelected() {
    this.searchRequest.attachment.clear(); // always clear attachment

    // if the model is selected we need to wipe out and re apply the filter
    if (this.searchRequest.model.selected) {
      this.searchRequest.model.clear();
      this.azureSearchRequest.filter = this.searchRequest.filter;
    }

    this.azureSearchService.search<InventoryItem>(this.azureSearchRequest, IndexName).subscribe((result: AzureSearchResult<InventoryItem>) => {
      this.searchRequest.initializeFacets(result.search.facets);
      this.azureSearchRequest.filter = this.searchRequest.filter;

      if (this.searchRequest.model.selected) {
        this.lastSearch();
      } else {
        this.searchFinished(result);
      }
    });
  }

  lastSearch() {
    this.azureSearchService.search<InventoryItem>(this.azureSearchRequest, IndexName).subscribe((result: AzureSearchResult<InventoryItem>) => {
      this.searchFinished(result);
    });
  }

  searchFinished(result: AzureSearchResult<InventoryItem>) {
    this.totalInventoryItems = result.odata.count;
    this.searchResults = result;
    this.searchResults$.next(result);
    this.updateFacets(result.search.facets);
  }

  nextPage() {
    if (this.totalInventoryItems < this.azureSearchRequest.skip + this.currentPageSize) {
      return;
    }

    this.azureSearchRequest.skip += this.currentPageSize;
    this.azureSearchService.search<InventoryItem>(this.azureSearchRequest, IndexName).subscribe((result: AzureSearchResult<InventoryItem>) => {
      this.totalInventoryItems = result.odata.count;
      const previousSearchResults = this.searchResults;
      Array.prototype.push.apply(previousSearchResults.value, result.value);
      this.searchResults$.next(previousSearchResults);
    });
  }

  filterResults() {
    // update params yo
    const queryParams: Params = this.searchRequest.toQueryParam();
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: "merge",
    });
  }

  mapMoved(bounds: MapBounds) {
    this.searchRequest.map.mapBounds = bounds;
    this.azureSearchRequest.top = 1000;
    this.filterResults();
  }

  updateFacets(facets: any) {
    this.searchRequest.initializeFacets(facets);
    this.searchRequest$.next(this.searchRequest);
  }

  clearSearchResults() {
    window.scrollTo(0, 0);
    this.azureSearchRequest.skip = 0;

    this.searchResults = null;
    this.searchResults$.next(null);
  }

  resetSearchRequest() {
    this.searchRequest = new SearchRequest();
    this.searchRequest$.next(null);
  }
}
