<div ngbDropdown #myDrop="ngbDropdown" rt="header-learn-more">
  <a class="nav-link" href="" id="learn-more" ngbDropdownToggle
    >Learn More
    <i class="icon-carrot"></i>
  </a>

  <div ngbDropdownMenu class="learn-more-dropdown" id="learn-more-dropdown">
    <div class="arrow-up"></div>
    <button ngbDropdownItem [routerLink]="['/lp/list-your-machines']">Listing</button>
    <button ngbDropdownItem [routerLink]="['/lp/rent-machines']">Renting</button>
  </div>
</div>
