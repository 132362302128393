<div class="card" [ngClass]="cardClass">

  <div class="card-body" [class.py-3]="messages.length == 1" [ngClass]="cardBodyClass">
    <div class="d-flex align-items-center" rt="validation-message" [class.mt-1]="!first"
      *ngFor="let message of messages;let first = first">
      <i *ngIf="showX" class="icon-close default small mr-2"></i>
      <i *ngIf="iconClass" class="mr-2" [ngClass]="iconClass"></i>
      <div class="supporting-text" [class.max-width-message]="rightSideText" [innerHtml]="message"></div>
      <div *ngIf="rightSideText" class="ml-auto cursor-pointer" [ngClass]="rightSideTextClass" (click)="rightSideClicked()">{{ rightSideText }}</div>
      <div *ngIf="rightSideToolTipText" class="ml-auto cursor-pointer">
        <app-questionmark-tooltip
          class="ml-md-2"
          [ngClass]="rightSideTextClass"
          [displayLearnMore]="false"
          [text]="rightSideToolTipText"
          [linkText]="'Learn more'"
          linkTextClass="text-red"
        >
        </app-questionmark-tooltip>
      </div>
    </div>
  </div>
</div>
