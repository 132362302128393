import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'common-cancel-confirm-modal',
  templateUrl: './cancel-confirm.modal.component.html',
  styleUrls: ["./cancel-confirm.modal.component.scss"]
})
export class CancelConfirmModalComponent {

  confirmationTitle: string = "Accept Title";
  confirmationMessage: string = "Are you sure?";
  confirmationSubText: string;
  cancelButtonTitle: string = "Cancel";
  acceptButtonTitle: string = "Accept";

  constructor(public activeModal: NgbActiveModal) { }

}
