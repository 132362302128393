import { LayoutComponent } from '../layout/layout.component';
import { AuthGuard } from '@rubbl-web-common';

export const routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data: { login: true },
    hideFooter: false,
    children: [
      {
        path: '',
        loadChildren: () => import('./transportation/transportation.module').then(m => m.TransportationModule),
        data: { pageTitle: "Transportation Requests", }
      }
    ]
  },


  // Not lazy-loaded routes
  // { path: 'register', component: RegisterComponent },
  // { path: 'recover', component: RecoverComponent },
  // { path: 'lock', component: LockComponent },
  // { path: 'maintenance', component: MaintenanceComponent },
  // { path: '404', component: Error404Component },
  // { path: '500', component: Error500Component },

  // Not found
  { path: '**', redirectTo: '' }

];
