import { Injectable } from "@angular/core";
import { BaseApiService, AuthenticationService, EnvironmentConfig } from "../../core/core";
import { HttpClient } from "@angular/common/http";

declare var environment: EnvironmentConfig;

@Injectable({
  providedIn: "root",
})
export class TransportationService extends BaseApiService<any> {
  constructor(httpClient: HttpClient, authenticationService: AuthenticationService) {
    super(httpClient, environment.baseUrl, "/api/transportation/v1/quote", true, authenticationService);
  }

  getShortHaulEstimate(primaryType: string) {
    const shortHaulMappings = {
      Excavator: 400,
      Dozer: 400,
      Loader: 400,
      "Skid Steer": 400,
      "Water Truck": 400,
      "Dump Truck": 400,
      Tractor: 400,
      Scraper: 400,
      Compactor: 400,
      "Crawler Carrier": 400,
      Telehandler: 400,
      "Padding Machine": 400,
      "Motor Grader": 400,
      Pipelayer: 400,
      "Articulated Truck": 400,
      "Fuel/Lube Truck": 400,
    };

    const shortHaulRate = shortHaulMappings[primaryType];
    return shortHaulRate ? shortHaulRate : 400;
  }
}
