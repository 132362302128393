import { OnInit, ViewChild, ElementRef, Input, Component, TemplateRef, EventEmitter, Output } from "@angular/core";
import { fromEvent } from "rxjs";
import { map, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { AzureSearchRequest, AzureSearchResult, AzureSearchService } from "../../../azure-search/azure-search";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { CountValueFacet } from "../../../search-results/search-results";
import { TableUtilsService } from "../../services/table-utils.service";
import { NgbDropdownConfig } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "common-index-table",
  templateUrl: "./index-table.component.html",
})
export class IndexTableComponent implements OnInit {
  @Input() tableHeader: string;
  @Input() addButtonText: string;
  @Input() paginationName: string;
  @Input() indexName: string;
  @Input() columns = [];
  @Input() inheritedTypeFilter: string;
  @Input() partitionKey: string;
  @Input() orderBy: string;
  @Input() showSearchInput: boolean = true;
  @Input() childProperty: string;
  @Input() facets: { label: string; facetProperty: string; facetFilter: string }[];

  rows = [];
  request = new AzureSearchRequest();
  result: AzureSearchResult<any>;
  countValueFacets: CountValueFacet[];
  offset: number;
  isSearching: boolean = false;

  queryParams = {
    orderby: "",
    offset: 0,
    filter: "",
    searchTerm: "",
  };

  @ViewChild("searchInput", { static: true }) searchInput: ElementRef;
  @ViewChild("indexTable") table;
  @ViewChild("statusTemplate", { static: true }) statusTemplate: TemplateRef<any>;
  @ViewChild("currencyTemplate", { static: true }) currencyTemplate: TemplateRef<any>;
  @ViewChild("linkTemplate", { static: true }) linkTemplate: TemplateRef<any>;
  @ViewChild("actionsTemplate", { static: true }) actionsTemplate: TemplateRef<any>;

  constructor(
    protected azureSearchService: AzureSearchService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private tableUtils: TableUtilsService,
    dropdownConfig: NgbDropdownConfig,
  ) {
    dropdownConfig.placement = "bottom-right";
  }

  ngOnInit() {
    const cellTemplateMapping = {
      status: this.statusTemplate,
      currency: this.currencyTemplate,
      link: this.linkTemplate,
      actions: this.actionsTemplate,
    };

    this.columns
      .filter((d) => d.customTemplate != null)
      .forEach((column) => {
        column.cellTemplate = cellTemplateMapping[column.customTemplate];
      });

    // get query string params only once!
    let paramMap = this.activatedRoute.snapshot.queryParams;
    this.queryParams.searchTerm = paramMap["searchTerm"] || "";
    this.request.search = this.queryParams.searchTerm + "*";
    this.request.orderby = this.queryParams.orderby = paramMap["orderby"] || this.orderBy;
    this.request.filter = this.queryParams.filter = paramMap["filter"] || "";
    this.request.facets = this.facets?.map((d) => d.facetFilter);

    this.searchInput.nativeElement.value = this.queryParams.searchTerm = paramMap["searchTerm"] || "";

    // search
    this.search({ offset: +paramMap["offset"] || 0 }, true);

    // add listener to search input
    fromEvent(this.searchInput.nativeElement, "keyup")
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(800),
        distinctUntilChanged(),
      )
      .subscribe((text: string) => {
        this.request.search = text + "*";
        this.queryParams.searchTerm = text;
        this.search({ offset: 0 });
      });
  }

  search(pageInfo = { offset: 0 }, firstSearch: boolean = false) {
    this.isSearching = true;
    this.request.skip = pageInfo.offset * this.request.top;
    this.offset = this.queryParams.offset = pageInfo.offset;
    this.azureSearchService.search<any>(this.request, this.indexName, this.inheritedTypeFilter).subscribe((result) => {
      this.result = result;
      // if its the first search and we have facets, lets set them up and see if we need to do a research
      if (firstSearch && result.search.facets) {
        const countValueFacets = [] as CountValueFacet[];
        // iterate through the facets passed in and create the count value facets
        this.facets.forEach((f) => {
          countValueFacets.push(new CountValueFacet(f.facetProperty, result.search.facets[f.facetProperty], f.label));
        });
        this.countValueFacets = countValueFacets;
        // grab the query params to see if we have any filters. filter out the null filters
        let filterArray = this.countValueFacets.map((d) => d.filter(new URLSearchParams(window.location.search))).filter((d) => d != null);
        if (filterArray.length) {
          this.request.filter = filterArray.join("and") + (this.queryParams.filter.length ? " and " + this.queryParams.filter : "");
          this.search({ offset: +this.activatedRoute.snapshot.queryParams["offset"] || 0 });
          return;
        }
      } else if (this.countValueFacets) {
        // update facets that are not selected
        for (let facet of this.countValueFacets) {
          let correspondingFacetFromSearch = result.search.facets[facet.name];
          facet.update(correspondingFacetFromSearch);
        }
      }

      if (this.childProperty) {
        // if we have a child property we have to get all the children arrays and add the parents properties to that children object
        // then we flatten to have one array of children(reglardless of parent)
        const childArraysOfParents = result.value.map((parent) => {
          return parent[this.childProperty].map((child) => {
            return {
              ...child,
              ...parent,
            };
          });
        });

        const flattendChilds = [].concat.apply([], childArraysOfParents);
        // we want to delete the child elements
        flattendChilds.forEach((element) => {
          delete element[this.childProperty];
        });
        this.rows = flattendChilds;
      } else {
        this.rows = result.value;
      }

      this.updateQueryParams();
      this.isSearching = false;

      this.tableUtils.addMobileCardFunctionality(this.columns.map((d) => d.name));
    });
  }

  facetApplied(countValueFacet: CountValueFacet) {
    this.countValueFacets.find((d) => d.name == countValueFacet.name).facets = countValueFacet.facets;
    const queryParams: Params = {};
    for (let facet of this.countValueFacets) {
      queryParams[facet.name] = facet.toQueryParam();
    }

    this.router
      .navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: "merge",
      })
      .then(() => {
        const urlParams = new URLSearchParams(window.location.search);
        this.request.filter = this.countValueFacets
          .map((d) => d.filter(urlParams))
          .filter((d) => d != null)
          .join(" and ");
        this.search();
      });
  }

  onSort(event) {
    // update the request and the query params
    this.request.orderby = this.queryParams.orderby = `${event.column.prop} ${event.newValue}`;
    this.search();
  }

  onFilter(filter: string) {
    this.request.filter = this.queryParams.filter = filter;
    this.search({ offset: 0 });
  }

  pageCountText(pageSize: number, currentPageIndex: number, rowCount: number): string {
    if (rowCount === 0) {
      return "";
    }

    const firstNumber = pageSize * (currentPageIndex - 1) + 1;
    let secondNumber = pageSize * currentPageIndex;
    if (secondNumber > rowCount) {
      secondNumber = rowCount;
    }

    return `${firstNumber} - ${secondNumber} of ${rowCount} ${this.paginationName}`;
  }

  linkClick(row, column) {
    if (!column.linkIdProperties) {
      console.error("must provide a property for the link id");
      return;
    }

    if (!column.linkHref) {
      console.error("must provide a link href");
      return;
    }

    const linkProperties = [];
    column.linkIdProperties.forEach((property) => {
      linkProperties.push(row[property]);
    });

    const link = this.formatLink(column.linkHref, ...linkProperties);
    window.open(link);
  }

  formatLink(link: string, ...val: string[]) {
    for (let index = 0; index < val.length; index++) {
      link = link.replace(`{${index}}`, val[index]);
    }
    return link;
  }

  private updateQueryParams() {
    // instead of replacing history, what we should do is determine if this is set a flag to determine if
    // this is the first time and if it is the first time calling this do not call it
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.queryParams,
      replaceUrl: true, // change
      queryParamsHandling: "merge",
    });
  }

  getComputedActions(column, entity) {
    if (entity.computedActions) {
      return entity.computedActions;
    }

    let computedActions = [];
    for (let action of column.actions) {
      if (action.show && action.show(entity) == false) {
        continue;
      }

      computedActions.push({
        label: action.label(entity),
        disable: action.disable ? action.disable(entity) : false,
        // when we compute an action and we have a click event then we need to delete the computed actions off the entity;
        click: (entity) => {
          delete entity.computedActions;
          action.click(entity);
        },
      });
    }
    entity.computedActions = computedActions;
    return computedActions;
  }
}
