export class BankAccount {
  accountNumber: string;
  routingNumber: string;
  phoneNumber: string;
  accountType: BankAccountType;
}

export enum BankAccountType {
  PersonalChecking = "PersonalChecking",
  PersonalSavings = "PersonalSavings",
  BusinessChecking = "BusinessChecking",
  BusinessSavings = "BusinessSavings"
}
