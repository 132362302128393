import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EnvironmentConfig, BaseApiService, AuthenticationService } from "../../core/core";
import { User } from "../models/user.model";
import { Observable } from "rxjs";

declare var environment: EnvironmentConfig;

@Injectable({
  providedIn: "root",
})
export class UserService extends BaseApiService<User> {
  constructor(httpClient: HttpClient, authenticationService: AuthenticationService) {
    super(httpClient, environment.baseUrl, "/api/admin/v1/user", true, authenticationService);
  }

  create(user: any): Observable<any> {
    const headers = this.getAuth();
    return this.httpClient.post(`${environment.baseUrl}/api/admin/v1/user/invite`, user, { headers });
  }

  changePassword(userId: string, passwordform: { oldPassword: string; password: string }) {
    const headers = this.getAuth();
    return this.httpClient.put(`${environment.baseUrl}/api/admin/v1/user/${userId}/password`, passwordform, { headers });
  }

  updateClaims(userId: string, claims: Record<string, string>) {
    const headers = this.getAuth();
    return this.httpClient.patch(`${environment.baseUrl}/api/admin/v1/user/${userId}/claims`, claims, { headers });
  }

  rensendInvite(userId: string, accountId: string) {
    const headers = this.getAuth();
    return this.httpClient.post(
      `${this.baseUrl}${this.endpoint}/${userId}/ResendInvite`,
      {},
      {
        headers,
        responseType: "text",
        params: {
          accountId,
        },
      },
    );
  }

  generateAdminKey(userId: string): Observable<{ adminKey: string }> {
    const headers = this.getAuth();
    return this.httpClient.get<{adminKey: string}>(`${environment.baseUrl}/api/admin/v1/user/${userId}/adminkey`, { headers })
  }
}
