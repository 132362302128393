import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AzureMapComponent } from './components/azure-map/azure-map.component';

@NgModule({
  declarations: [
    AzureMapComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [AzureMapComponent]
})
export class AzureMapsModule { }
