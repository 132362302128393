import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from "@angular/core";
import { MultiDropDownItem } from "./multi-dropdown-item.model";
import { GroupList, SortList } from "../../../core/core";

@Component({
  selector: "common-multi-dropdown",
  templateUrl: "./multi-dropdown.component.html",
  styleUrls: ["./multi-dropdown.component.scss"],
})
export class MultiDropdownComponent implements OnInit, OnChanges {
  @Input() includeSelectAll: boolean = false;
  @Input() includeGrouping: boolean = false;
  @Input() dropDownItems: MultiDropDownItem[] = [];
  @Input() entityProperty: any;
  @Input() multiSelectedText: string = "Selected";
  @Input() emptyMessage: string = "Select";
  @Input() customClass: string = "form-control";
  @Input() enableSearch: boolean;

  @Output() entityPropertyChange = new EventEmitter<any>();
  @Output() applyChange = new EventEmitter<any>();

  dropDownOpen: boolean;
  cachedDropDownItems: MultiDropDownItem[];
  itemsSelectedCount: number = 0;
  selectedText: string = "";
  currentGroupValue: string = "";
  groupList: string[] = [];

  allValue: string = "ALL";
  constructor() {}

  ngOnInit() {
    this.updateGrouping();
    this.updateSelectAll();
    this.updateDropdownCache();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dropDownItems && !changes.dropDownItems.isFirstChange() && changes.dropDownItems.currentValue !== changes.dropDownItems.previousValue) {
      this.updateGrouping();
      this.updateSelectAll();
      this.updateDropdownCache();
    }
  }

  updateSelectAll() {
    if (this.includeSelectAll && this.dropDownItems.length > 0) {
      const selectAll = new MultiDropDownItem();
      selectAll.label = "All";
      selectAll.value = this.allValue;
      this.dropDownItems.unshift(selectAll);
    }
  }

  updateGrouping() {
    if (this.includeGrouping && this.dropDownItems.length > 0) {
      const groupedList = GroupList.groupBy("groupValue", this.dropDownItems).map((x) => x.key);
      this.groupList = groupedList.length ? SortList.sortList(groupedList) : [];
    }
  }

  updateDropdownCache() {
    this.cachedDropDownItems = [...this.dropDownItems];
    if (this.entityProperty) {
      for (const property of this.entityProperty) {
        // find corresponding available dropdownitem to mark it checked
        const matchingDropDownItem = this.dropDownItems.find((d) => d.value === property) || this.dropDownItems.find((d) => d.value.id === property.id);
        if (matchingDropDownItem != null) {
          matchingDropDownItem.checked = true;
        }
      }
    }

    this.updateDropdownText();
  }

  openChange(event: boolean) {
    this.dropDownOpen = event;

    if (!this.dropDownOpen) {
      this.apply();
    }
  }

  updateDropdownText() {
    this.itemsSelectedCount = this.dropDownItems.filter((d) => d.checked && d.value !== this.allValue).length;

    this.selectedText = !this.includeSelectAll
      ? this.itemsSelectedCount > 1 && this.multiSelectedText !== "Selected"
        ? this.itemsSelectedCount + " " + this.multiSelectedText + "s"
        : this.itemsSelectedCount + " " + this.multiSelectedText
      : this.itemsSelectedCount === this.dropDownItems.length - 1 && this.dropDownItems[0].checked
      ? "All Selected"
      : this.itemsSelectedCount > 1 && this.multiSelectedText !== "Selected"
      ? this.itemsSelectedCount + " " + this.multiSelectedText + "s"
      : this.itemsSelectedCount + " " + this.multiSelectedText;

    // such a hack but the above logic is disgusting to read. replace statuss with statuses
    this.selectedText = this.selectedText.replace("Statuss", "Statuses");
  }

  search(event: any) {
    this.dropDownItems = this.cachedDropDownItems.filter((d) => d.label.toLowerCase().includes(event.target.value.toLowerCase()));
  }

  onCheckboxChange(ev) {
    if (ev.value === this.allValue) {
      this.dropDownItems.forEach((e) => {
        if (e.value !== this.allValue) {
          e.checked = ev.checked;
        }
      });
      this.entityPropertyChange.emit(this.dropDownItems.filter((d) => d.checked && d.value !== this.allValue).map((dropdownItem) => dropdownItem.value));
    } else {
      if (this.includeSelectAll && !ev.checked && this.dropDownItems[0].checked) {
        this.dropDownItems[0].checked = false;
      }
      this.entityPropertyChange.emit(this.dropDownItems.filter((d) => d.checked).map((dropdownItem) => dropdownItem.value));
    }
    this.updateDropdownText();
  }

  clear() {
    this.dropDownItems.forEach((e) => (e.checked = false));
    this.entityPropertyChange.emit(this.dropDownItems.filter((d) => d.checked && d.value !== this.allValue).map((dropdownItem) => dropdownItem.value));
    this.updateDropdownText();
  }

  filteringGroupedItems(groupName: string) {
    return this.dropDownItems.filter((x) => x.groupValue === groupName);
  }

  buildLabelHtml(item: MultiDropDownItem) {
    if (item.subLabel && item.subLabel.length) {
      return `<span>${item.label}</span><span class="text-grey">&nbsp;${item.subLabel}</span>`;
    }
    return `<span>${item.label}</span>`;
  }

  apply(event?) {
    event?.stopPropagation();
    this.applyChange.emit(this.dropDownItems.filter((d) => d.checked).map((dropdownItem) => dropdownItem.value));
  }
}
