<div class="card rubbl-card" rt="rental-details-card">
  <div class="card-header d-flex">
    <div class="mr-auto">Order Details</div>
  </div>
  <div class="card-body">
    <div class="section-header">Jobsite Information</div>

    <label class="mt-4">Jobsite location</label>
    <div class="paragraph-text text-grey address" [innerHtml]="rental.dropoffLocation | location: 'full' : true"></div>

    <div class="d-flex align-items-center mt-4">
      <label class="mr-auto">Jobsite contact</label>
      <!-- <div class="text-green cursor-pointer">Change</div> -->
    </div>
    <div class="paragraph-text text-grey">
      <div>{{ rental.contacts[0].name }}</div>
      <div class="mt-1">{{ rental.contacts[0].phoneNumber }}</div>
      <div class="mt-1">{{ rental.contacts[0].email }}</div>
    </div>

    <ng-container *ngIf="rental.contacts[1]">
      <div class="d-flex mt-4">
        <label class="mr-auto">Secondary Contact</label>
        <!-- <div class="text-green cursor-pointer">Change</div> -->
      </div>
      <div class="paragraph-text text-grey">
        <div>{{ rental.contacts[1].name }}</div>
        <div class="mt-1">{{ rental.contacts[1].phoneNumber }}</div>
        <div class="mt-1">{{ rental.contacts[1].email }}</div>
      </div>
    </ng-container>

    <ng-container *ngIf="monthlyTotal">
      <div class="bb-light my-4"></div>
      <div class="section-header">Payment Info</div>

      <label class="mt-4">Monthly total</label>
      <div class="d-flex">
        <div class="paragraph-text text-grey address mr-2" [innerHtml]="monthlyTotal | currency: 'USD':'symbol':'1.0-0'"></div>

        <app-questionmark-tooltip [tableRows]="monthlyTotalTooltipData" [displayLearnMore]="false" mobilePlacement="right"></app-questionmark-tooltip>
      </div>
    </ng-container>

    <ng-container *ngIf="firstItem?.rentalItemState?.configuration?.billingConfiguration?.nextBillDate">
      <div class="d-flex mt-4">
        <label>Next Billing Date</label>
        <a class="ml-auto" [routerLink]="['/manage/account/statements']">View statements</a>
      </div>

      <div class="paragraph-text text-grey address">
        {{ firstItem?.rentalItemState.configuration?.billingConfiguration?.nextBillDate | date }}
      </div>
    </ng-container>

    <ng-container *ngIf="firstItem?.poJobNumber">
      <label class="mt-4">Job or PO Number</label>
      <div class="paragraph-text text-grey address">{{ firstItem?.poJobNumber }}</div>
    </ng-container>
  </div>
</div>
