import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { EventManager } from "@angular/platform-browser";
import { AzureSuggestsResult, AzureSuggestsRequest, AzureSearchService } from "../../../azure-search/azure-search";
import { OverlayService } from "../../../layout/services/overlay.service";
import { fadeInOut } from "../../../rubbl-shared/animations";

@Component({
  selector: "app-search-suggests",
  templateUrl: "./search-suggests.component.html",
  styleUrls: ["./search-suggests.component.scss"],
  animations: [fadeInOut("250ms", "250ms")],
})
export class SearchSuggestsComponent implements OnInit {
  @Input() searchInput: HTMLElement;
  @Input() customClass: string = "";

  @Input() useSearchSuggestionsOverlay: boolean = true;
  @Output() suggestionSelected = new EventEmitter<AzureSuggestsResult>();

  searchTerm = "";
  showSearchSuggestions = false;
  searchSuggestions: AzureSuggestsResult[] = [];
  suggestsRequest = new AzureSuggestsRequest();

  constructor(private overlayService: OverlayService, private eventManager: EventManager, private azureSearchService: AzureSearchService) {}

  ngOnInit() {
    this.eventManager.addEventListener(this.searchInput, "keyup", $event => this.searchInputChange($event));
    this.eventManager.addEventListener(this.searchInput, "blur", () => this.searchInputBlur());
    this.eventManager.addEventListener(this.searchInput, "focus", () => this.searchInputFocus());
  }

  searchInputChange($event) {
    // check if current the key pressed is enter
    if ($event.keyCode === 10 || $event.keyCode === 13) {
      this.showSearchSuggestions = false;
      this.toggleOverlay(false);
      return;
    }

    this.searchTerm = $event.target.value;
    this.showSearchSuggestions = this.searchTerm === "" ? false : true;
    if (this.showSearchSuggestions) {
      this.getSearchSuggestions();
      // going to have to remove the z-index from navbar so it goes behind the search suggests overlay
    }
    this.toggleOverlay(this.showSearchSuggestions);
  }

  searchInputBlur() {
    this.showSearchSuggestions = false;
    this.toggleOverlay(false);
  }

  searchInputFocus() {
    if (this.searchTerm !== "") {
      this.showSearchSuggestions = true;
      this.toggleOverlay(true);

      // if we cleared out search suggestions due to clicking on a selected search term then we
      // need to repopulate the search suggestions
      if (this.searchSuggestions == null) {
        this.getSearchSuggestions();
      }
    }
  }

  getSearchSuggestions() {
    this.suggestsRequest.search = this.searchTerm;
    // such a stupid hack so plurals work...have to work with items that start with "s" like skid steer
    if (this.suggestsRequest.search.substring(this.suggestsRequest.search.length - 1) == "s" && this.suggestsRequest.search.length > 1) {
      this.suggestsRequest.search = this.suggestsRequest.search.slice(0, this.suggestsRequest.search.length - 1);
    }
    this.suggestsRequest.fuzzy = false;
    this.azureSearchService.suggestions(this.suggestsRequest, "suggestions").subscribe((result: AzureSuggestsResult[]) => {
      this.searchSuggestions = result;
    });
  }

  selectSuggestedTerm(suggestedTerm: AzureSuggestsResult) {
    this.searchTerm = suggestedTerm.value;
    this.suggestionSelected.emit(suggestedTerm);
    this.searchSuggestions = null; // clear out current search suggestions
    this.toggleOverlay(false);
  }

  toggleOverlay(show: boolean) {
    if (this.useSearchSuggestionsOverlay) {
      this.overlayService.toggleOverlayWithBool("search-suggests", show);
    }
  }
}
