import { Injectable } from "@angular/core";
import {
  BaseApiService,
  AuthenticationService,
  EnvironmentConfig,
} from "../../core/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { TransportationLoad } from "../models/transportation-load.model";

declare var environment: EnvironmentConfig;

@Injectable({
  providedIn: "root",
})
export class TransportationLoadService extends BaseApiService<any> {
  constructor(
    httpClient: HttpClient,
    authenticationService: AuthenticationService
  ) {
    super(
      httpClient,
      environment.baseUrl,
      "/api/transportation/v2/request",
      true,
      authenticationService
    );
  }

  getLoad(
    requestId: string,
    loadId: string,
    accountId: string
  ): Observable<any> {
    const headers = this.getAuth();

    let params = new HttpParams();
    params = params.append("accountId", accountId);

    return this.httpClient.get<any>(
      `${this.baseUrl}/api/transportation/v1/request/${requestId}/load/${loadId}`,
      { headers, params }
    );
  }

  editLoad(requestId: string, load: TransportationLoad): Observable<any> {
    const headers = this.getAuth();
    return this.httpClient.put<any>(
      `${this.baseUrl}/api/transportation/v1/request/${requestId}/load/${load.id}`,
      load,
      { headers }
    );
  }

  editTotalDimensions(
    load: TransportationLoad,
    totaldimensions: any,
    reason: string
  ) {
    const headers = this.getAuth();

    let params = new HttpParams();
    params = params.append("accountId", load.accountId);

    return this.httpClient.put<any>(
      `${this.baseUrl}/api/transportation/v1/request/${load.transportationRequestId}/load/${load.id}/totaldimensions`,
      { totaldimensions, reason },
      { headers, params }
    );
  }

  setSchedule(load: TransportationLoad, schedule: any) {
    const headers = this.getAuth();

    let params = new HttpParams();
    params = params.append("accountId", load.accountId);

    return this.httpClient.put<any>(
      `${this.baseUrl}/api/transportation/v1/request/${load.transportationRequestId}/load/${load.id}/schedule`,
      schedule,
      { headers, params }
    );
  }

  sendSchedule(load: TransportationLoad) {
    const headers = this.getAuth();

    let params = new HttpParams();
    params = params.append("accountId", load.accountId);

    return this.httpClient.post<any>(
      `${this.baseUrl}/api/transportation/v1/request/${load.transportationRequestId}/load/${load.id}/schedule/send`,
      null,
      { headers, params }
    );
  }

  setPayment(load: TransportationLoad, formData: FormData) {
    const headers = this.getAuth();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");

    let params = new HttpParams();
    params = params.append("accountId", load.accountId);

    return this.httpClient.put<any>(
      `${this.baseUrl}/api/transportation/v1/request/${load.transportationRequestId}/load/${load.id}/invoice`,
      formData,
      { headers, params }
    );
  }

  sendPayment(load: TransportationLoad, invoiceVariance?: any) {
    const headers = this.getAuth();

    let body = {
      accountId: load.accountId,
    };

    if (invoiceVariance) {
      body = invoiceVariance;
    }

    return this.httpClient.post<any>(
      `${this.baseUrl}/api/transportation/v1/request/${load.transportationRequestId}/load/${load.id}/invoice/send`,
      body,
      { headers }
    );
  }

  getInvoice(load: TransportationLoad) {
    const headers = this.getAuth();
    const url = `${this.baseUrl}/api/transportation/v1/request/${load.transportationRequestId}/load/${load.id}/invoice`;
    const responseType = "blob";

    let params = new HttpParams();
    params = params.append("accountId", load.accountId);

    return this.httpClient
      .get(url, { headers, params, responseType })
      .subscribe((res) => {
        // this turns the binary into a downloadable url and opens up the file save dialog
        const a = document.createElement("a");
        a.href = URL.createObjectURL(res);
        a.download = load.invoice.fileType.name;
        a.click();
      });
  }

}
