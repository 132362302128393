<div [class.p-4]="!inCheckout">
  <div class="d-flex fs-18">
    <div>Summary</div>
    <div class="ml-auto">{{ rateLabel }}</div>
  </div>
  <div class="bb-light mb-3 mt-1"></div>
  <!-- machine -->
  <div class="d-flex align-items-center">
    <app-thumbnail-with-x
      *ngIf="!onMachineDetails"
      [height]="inCheckout ? '125px' : '60px'"
      [width]="inCheckout ? '125px' : '60px'"
      borderRadius="5px"
      [imgSrc]="cartItem.imageThumbnail"
      [hideX]="!inCheckout"
      (xClicked)="confirmRemoveItem()"
    ></app-thumbnail-with-x>
    <div [class.ml-2]="!onMachineDetails" style="line-height: 26px">
      <ng-container *ngIf="!onMachineDetails">
        <div class="teko fs-26" style="font-weight: 500">{{ cartItem.inventoryItem.make }} {{ cartItem.inventoryItem.model }}</div>
        <div class="font-weight-light">{{ cartItem.inventoryItem.year }} {{ cartItem.inventoryItem.primaryType }}</div>
      </ng-container>

      <ng-container *ngIf="inCheckout">
        <ng-container *ngIf="isEstimate">
          <div class="bold">Rental Rate</div>
        </ng-container>
        <ng-container *ngIf="!isEstimate">
          <div class="font-weight-light">{{ cartItem.beginDate | date: "mediumDate" }}</div>
          <div class="font-weight-light">{{ cartItem.formattedDuration }}</div>
        </ng-container>
      </ng-container>
    </div>
    <div class="ml-auto">
      <div class="fs-20 bold" *ngIf="!machineRentBeforeDiscount">{{ rateSummary?.Rent | currency: "USD":"symbol":"1.0-0" }}</div>
      <div class="fs-20 bold" *ngIf="machineRentBeforeDiscount">{{ machineRentBeforeDiscount | currency: "USD":"symbol":"1.0-0" }}</div>
      <a *ngIf="inCheckout && !isEstimate && !onMachineDetails" class="float-right cursor-pointer ml-auto text-green" (click)="editClick()">Edit</a>
    </div>
  </div>
  <div class="bb-light my-3"></div>
  <!-- attachments -->
  <div *ngIf="cartItem.attachments.length">
    <p>Attachments</p>
    <ng-container *ngFor="let attachment of cartItem.attachments">
      <div class="d-flex mb-1">
        <div>
          <div>{{ attachment.title }}</div>
          <div class="font-weight-light mr-5">{{ attachment.description }}</div>
        </div>
        <div class="ml-auto">{{ attachment[attachmentRateProperty] | currency: "USD":"symbol":"1.0-0" }}</div>
      </div>
    </ng-container>
    <div class="bb-light my-3"></div>
  </div>

  <!-- services -->
  <div class="d-flex">
    <div><u class="cursor-pointer" (click)="servicesTooltip.open()">Rubbl Managed Services</u></div>
    <app-questionmark-tooltip
      class="ml-2"
      [displayLearnMore]="false"
      text="A fee of 5% of your rental rate. Includes damage coverage, preventative maintenance, transportation logistics, GPS tracking, and repair coordination."
      #servicesTooltip
    >
    </app-questionmark-tooltip>
    <div class="ml-auto">{{ rateSummary?.PremiumServices | currency: "USD":"symbol":"1.0-0" }}</div>
  </div>
  <!-- discount -->
  <ng-container>
    <div class="bb-light my-3"></div>
    <div class="d-flex">
      <div><u class="cursor-pointer" (click)="discountTooltip.open()">Discount</u></div>
      <app-questionmark-tooltip
        class="ml-2"
        mobilePlacement="right"
        [displayLearnMore]="false"
        text="A discount is applied if your rental duration is longer than 3 months."
        #discountTooltip
      >
      </app-questionmark-tooltip>
      <div class="ml-auto text-primary">({{ discount | currency: "USD":"symbol":"1.0-0" }})</div>
    </div>
  </ng-container>

  <div class="bb-light my-3"></div>
  <div class="d-flex fs-20">
    <div>Total</div>
    <div class="ml-auto">{{ total | currency: "USD":"symbol":"1.0-0" }}</div>
  </div>
</div>
