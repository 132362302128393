<button
  
  [attr.disabled]="disabled ? true : null"
  [ngClass]="classObj"
 
  (click)="btnClick.next()"
  class="btn btn-outline-secondary btn-mobile-lg w-100 d-flex justify-content-center align-items-center bg-transparent px-4"
>
  <i class="fs-20 text-green mr-2" [ngClass]="iconClass"></i>
  <span class="text-green">{{ buttonText }}</span>
</button>
