import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "../../forms/forms.module";

import { PaymentMethodCardComponent } from "./components/payment-method-card/payment-method-card.component";
import { PaymentMethodCardListComponent } from "./components/payment-method-card-list/payment-method-card-list.component";
import { CreatePaymentMethodFormComponent } from "./components/create-payment-method-form/create-payment-method-form.component";
import { ReactiveFormsModule } from "@angular/forms";
import { PaymentMethodIconsComponent } from "./components/payment-method-icons/payment-method-icons.component";
import { OrderTransactionsTableComponent } from "./components/order-transactions-table/order-transactions-table.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { RouterModule } from "@angular/router";
import { RubblSharedModule } from "../../rubbl-shared/rubbl-shared.module";

@NgModule({
  declarations: [
    PaymentMethodCardComponent,
    PaymentMethodCardListComponent,
    CreatePaymentMethodFormComponent,
    PaymentMethodIconsComponent,
    OrderTransactionsTableComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, NgxDatatableModule, RubblSharedModule],
  exports: [
    PaymentMethodCardComponent,
    PaymentMethodCardListComponent,
    CreatePaymentMethodFormComponent,
    PaymentMethodIconsComponent,
    OrderTransactionsTableComponent,
  ],
})
export class PaymentsModule {}
