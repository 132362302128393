import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchHighlightPipe } from './pipes/search-highlight/search-highlight.pipe';

@NgModule({
  declarations: [SearchHighlightPipe],
  imports: [
    CommonModule
  ],
  exports: [
    SearchHighlightPipe
  ]
})
export class AzureSearchModule { }
