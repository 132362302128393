<div class="container medium-container numbers-container text-center text-md-left">
  <div class="row">
    <div class="col-md-4" *ngFor="let marketingCard of marketingCards; index as i">
      <div class="bg-transparent">
        <div class="number" [style.opacity]="1 / (i + 1)">{{ i + 1 }}</div>
        <div class="card-body px-0">
          <h4 class="card-title">{{ marketingCard.title }}.</h4>
          <div class="red-separator mx-auto mx-md-0 mb-3"></div>
          <p class="paragraph-text text-grey">{{ marketingCard.body }}</p>
          <ul class="text-grey list-items" *ngIf="marketingCard.listItems">
            <li *ngFor="let listItem of marketingCard.listItems">{{ listItem }}</li>
          </ul>
          <a href="" class="card-link" *ngIf="marketingCard.linkText">{{ marketingCard.linkText }}</a>
        </div>

        <img class="card-image img-fluid br-5 mt-3 d-md-none" *ngIf="marketingCard.imagePath" [src]="baseImagePath + marketingCard.imagePath" alt="" />
      </div>
    </div>
  </div>
  <div class="row d-none d-md-flex">
    <div class="col-md-4" *ngFor="let marketingCard of marketingCards; index as i">
      <img class="card-image img-fluid br-5 mt-3" *ngIf="marketingCard.imagePath" [src]="baseImagePath + marketingCard.imagePath" alt="" />
    </div>
  </div>
</div>
