import { IStorableEntity } from "./storable-entity.model";

export class BaseStorableEntity implements IStorableEntity {
  id?: string;
  isActive?: boolean;
  itemType?: Array<string>;
  partitionKey?: string;
  partition?: string;
  constructor(items?: IStorableEntity) {
    this.id = items?.id;
    this.isActive = items?.isActive;
    this.itemType = items?.itemType;
    this.partitionKey = items?.partitionKey;
  }
}
