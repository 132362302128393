import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";

import { LogService, MarketTrackingEvents, ModalService } from "../../../core/core";
import { CartService } from "../../cart.service";
import { Cart, CartItem, CartSummary } from "../../models/cart.model";
import { AzureSearchService } from "../../../azure-search/azure-search";
import { MachineCardAddToCartComponent } from "../machine-card-add-to-cart/machine-card-add-to-cart.component";
import { InventoryItem } from "../../../inventory/inventory";
import { deepClone } from "fast-json-patch";

@Component({
  selector: "app-aside-cart-modal",
  templateUrl: "./aside-cart-modal.component.html",
  styleUrls: ["./aside-cart-modal.component.scss"],
})
export class AsideCartModalComponent implements OnInit, OnDestroy {
  cart: Cart = new Cart();
  cartSubscription: Subscription;
  invalidCartItems: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private cartService: CartService,
    private logService: LogService,
    private azureSearchService: AzureSearchService,
    private modalService: ModalService,
  ) {}

  ngOnInit() {
    this.logService.trackEvent(MarketTrackingEvents.Cart.Opened);

    this.cartSubscription = this.cartService.cartBSubject.subscribe(cart => {
      // if the cart status is not active we do not want to show it
      // a cart will be set to inactive once we convert it from a cart to an order
      this.cart = cart != null && cart.activeStatus === "Active" ? cart : new Cart();
      this.invalidCartItems = !this.cartItemsValid(this.cart.items);
      this.calculateDurationTotals(this.cart);
    });
  }

  ngOnDestroy() {
    this.logService.trackEvent(MarketTrackingEvents.Cart.Closed);

    if (this.cartSubscription.unsubscribe) {
      this.cartSubscription.unsubscribe();
    }
  }

  // could probably move this into cart service
  calculateDurationTotals(cart: Cart) {
    for (const cartItem of cart.items) {
      let rateSummary: CartSummary;
      switch (cartItem.duration) {
        case 3:
          cartItem.durationUnit = "day";
          rateSummary = cartItem.dailySummary;
          break;
        case 7:
        case 14:
          cartItem.durationUnit = "week";
          rateSummary = cartItem.weeklySummary;
          break;
        default:
          cartItem.durationUnit = "month";
          rateSummary = cartItem.monthlySummary;
          break;
      }

      let total = 0;
      for (const [key, value] of Object.entries(rateSummary)) {
        total += value as number;
      }

      cartItem.durationTotal = total;
    }
  }

  removeItemFromCart(itemId: string) {
    this.cartService.removeItemFromCart(itemId);
  }

  checkout() {
    this.logService.trackEvent(MarketTrackingEvents.Cart.CheckoutClick);
    this.router.navigate(["checkout"]);
  }

  edit(cartItem: CartItem) {
    this.activeModal.close();
    this.azureSearchService.getOne(cartItem.inventoryItem.id, "machines").subscribe((machine: InventoryItem) => {
      const modalRef = this.modalService.standardModal(MachineCardAddToCartComponent, true);
      modalRef.componentInstance.machine = machine;
      modalRef.componentInstance.machineInCart = true;
      modalRef.componentInstance.cartItem = deepClone(cartItem);
      modalRef.result.then(
        result => {},
        () => {
          // if user cancels then we need to pop open this modal
          setTimeout(() => {
            this.modalService.slideFromRight(AsideCartModalComponent, true);
          }, 250);
        },
      );
    });
  }

  cartItemsValid(cartItems: CartItem[]): boolean {
    let isValid = true;
    if (cartItems && cartItems.length) {
      cartItems.forEach(cartItem => {
        if (cartItem.status !== "Available") {
          isValid = false;
          return false;
        }
      });
    }
    return isValid;
  }
}
