import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { Validators, FormBuilder } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { FormUtils, DropDownItem, DropDownItems, EditItemFooterComponent } from "../../../../forms/forms";
import { BankAccountType } from "../../models/bank-account.model";
import { PaymentUsageType, PaymentMethodType } from "../../models/payment-method.model";
import { AccountService } from "../../../../account/account";
import { ActivatedRoute } from "@angular/router";
import { AzureSearchService } from "../../../../azure-search/azure-search";
import { mergeMap, catchError } from "rxjs/operators";
import { of, throwError } from "rxjs";

@Component({
  selector: "common-create-payment-method-form",
  templateUrl: "./create-payment-method-form.component.html",
  styleUrls: ["./create-payment-method-form.component.scss"],
})
export class CreatePaymentMethodFormComponent implements OnInit {
  @ViewChild("editItemFooter") editItemFooter: EditItemFooterComponent;

  @Input() accountId: string;
  @Input() showFooterSave: boolean = true;
  @Output() paymentMethodCreated = new EventEmitter<any>();

  paymentForm = this.formBuilder.group({
    paymentType: [PaymentMethodType.ACH, Validators.required],
    name: ["", Validators.required],
    accountType: ["", Validators.required],
    routingNumber: ["", Validators.required],
    accountNumber: ["", Validators.required],
    phoneNumber: ["", Validators.required],
    cardNumber: [""],
    usageType: [PaymentUsageType.Both],
    expiration: [""],
    cVV: [""],
    zipCode: [""],
  });

  paymentTypeDropDownItems: DropDownItem[] = [
    {
      label: "ACH",
      value: "ACH",
    },
    {
      label: "Credit Card",
      value: "CreditCard",
    },
  ];

  isOwner: boolean = null;
  accountHasPaymentMethods: boolean = false;

  backAccountTypeDropDownItems: DropDownItem[] = DropDownItems.createFromEnum(BankAccountType);
  formUtils = FormUtils;
  useForPayments: boolean = true;
  useForEarnings: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private azureSearchService: AzureSearchService,
  ) {}

  ngOnInit() {
    this.azureSearchService.getOne<any>(this.accountId, "accounts").subscribe(result => {
      this.isOwner = result.machines > 0;
      this.accountHasPaymentMethods = this.route.snapshot.queryParams.hasPayments === "true" || this.route.snapshot.queryParams.hasEarnings === "true";

      if (!this.isOwner) {
        this.useForEarnings = false;
        this.paymentUsageChange();
      }
    });
  }

  paymentTypeSelected(paymentType) {
    this.paymentForm.reset();
    this.paymentForm.patchValue({ paymentType });

    if (paymentType === PaymentMethodType.ACH) {
      if (this.isOwner) {
        this.useForEarnings = true;
      }
      this.useForPayments = true;

      this.paymentForm.controls.accountType.setValidators([Validators.required]);
      this.paymentForm.controls.routingNumber.setValidators([Validators.required]);
      this.paymentForm.controls.accountNumber.setValidators([Validators.required]);
      this.paymentForm.controls.phoneNumber.setValidators([Validators.required]);

      this.paymentForm.controls.cardNumber.setValidators(null);
      this.paymentForm.controls.expiration.setValidators(null);
      this.paymentForm.controls.cVV.setValidators(null);
      this.paymentForm.controls.zipCode.setValidators(null);

      this.accountTypeSelected(null);
    }

    if (paymentType === PaymentMethodType.CreditCard) {
      this.useForEarnings = false;
      this.useForPayments = true;

      this.paymentForm.controls.accountType.setValidators(null);
      this.paymentForm.controls.routingNumber.setValidators(null);
      this.paymentForm.controls.accountNumber.setValidators(null);
      this.paymentForm.controls.phoneNumber.setValidators(null);

      this.paymentForm.controls.cardNumber.setValidators([Validators.required]);
      this.paymentForm.controls.expiration.setValidators([Validators.required]);
      this.paymentForm.controls.cVV.setValidators([Validators.required]);
      this.paymentForm.controls.zipCode.setValidators([Validators.required]);
    }

    this.paymentUsageChange();
    FormUtils.updateValueAndValidity(this.paymentForm);
  }

  // bank account type
  accountTypeSelected(accountType) {
    this.paymentForm.patchValue({ accountType });
  }

  paymentUsageChange(usageType?: string | PaymentUsageType) {
    // even though a payment usage is required we are using checkboxes and not radios
    // so we had to implement this logic.........
    if (!this.useForPayments && !this.useForEarnings) {
      this.paymentForm.controls["usageType"].setErrors({ required: true });
      this.paymentForm.controls["usageType"].markAsDirty();
      this.paymentForm.controls["usageType"].markAsTouched();

      // had to do a set timeout since this interaction is stupid and not a standard interaction
      if (usageType === PaymentUsageType.Payments) {
        setTimeout(() => {
          this.useForPayments = true;
        }, 0);
      }
      if (usageType === PaymentUsageType.Earnings) {
        setTimeout(() => {
          this.useForEarnings = true;
        }, 0);
      }

      return;
    }

    let paymentUsageType = null as PaymentUsageType;

    if (this.useForPayments && this.useForEarnings) {
      paymentUsageType = PaymentUsageType.Both;
    }
    if (this.useForPayments && !this.useForEarnings) {
      paymentUsageType = PaymentUsageType.Payments;
    }
    if (!this.useForPayments && this.useForEarnings) {
      paymentUsageType = PaymentUsageType.Earnings;
    }

    this.paymentForm.patchValue({ usageType: paymentUsageType });
  }

  toggleDefault(isDefault) {
    this.paymentForm.patchValue({ isDefault });
  }

  onSubmit() {
    if (!this.paymentForm.valid) {
      FormUtils.markFormGroup(this.paymentForm);
      this.toastrService.error("errors found", null, { positionClass: "toast-top-center" });
      return;
    }

    this.toggleLoading(true);
    this.paymentForm.value.phoneNumber = this.paymentForm.value?.phoneNumber?.replace(/\D/g, "");

    this.accountService
      .createPaymentMethod(this.accountId, this.paymentForm.value)
      .pipe(
        mergeMap(result => {
          if (this.accountHasPaymentMethods) {
            return of(result);
          }

          return this.accountService.defaultPaymentMethod(this.accountId, result.id, this.paymentForm.value.usageType);
        }),
        catchError(e => this.onError(e)),
      )
      .subscribe(
        result => {
          this.paymentMethodCreated.emit(result);
          this.toggleLoading(false);
        },
        error => {
          this.toggleLoading(false);
        },
      );
  }

  toggleLoading(loading: boolean) {
    if (this.editItemFooter) {
      this.editItemFooter.loading = loading;
    }
  }

  onError(e) {
    try {
      const qbErrors = e.error[""];
      const qbError = qbErrors[0];

      const controlsForQbErrors = [];
      if (qbError.includes("routingNumber")) {
        controlsForQbErrors.push(this.paymentForm.controls["routingNumber"]);
      }
      if (qbError.includes("accountNumber")) {
        controlsForQbErrors.push(this.paymentForm.controls["accountNumber"]);
      }

      if (qbError.includes("card")) {
        controlsForQbErrors.push(this.paymentForm.controls["cardNumber"]);
      }
      if (qbError.includes("expyear")) {
        controlsForQbErrors.push(this.paymentForm.controls["expiration"]);
      }
      if (qbError.includes("cvc")) {
        controlsForQbErrors.push(this.paymentForm.controls["cVV"]);
      }

      controlsForQbErrors.forEach(d => d.setErrors({ qb: true }));

      if (controlsForQbErrors.length === 0) {
        this.toastrService.error(qbError, null, { positionClass: "toast-top-center" });
      } else {
        this.toastrService.error("errors found", null, { positionClass: "toast-top-center" });
      }
    } catch (e) {
      this.toastrService.error("Error occurred, contact support.", null, { positionClass: "toast-top-center" });
    }

    return throwError(e.message);
  }
}
