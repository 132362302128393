import { AuditMetadata } from './audit-metadata.model';
import { BaseStorableEntity } from './base-storable-entity.model';
import { IStorableEntity } from './storable-entity.model';

export interface IAuditableEntity extends IStorableEntity {
  auditMetadata?: AuditMetadata;
}

export interface IBaseAuditableEntity extends BaseStorableEntity {
  auditMetadata?: AuditMetadata;
}

export class BaseAuditableEntity extends BaseStorableEntity implements IAuditableEntity, IStorableEntity  {
  auditMetadata?: AuditMetadata;
  constructor(items?: IBaseAuditableEntity) {
    super(items);
    this.auditMetadata = items?.auditMetadata;
  }
}
