import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DisplayDataSection } from './display-data-section.model';

@Component({
  selector: 'common-display-data-section',
  templateUrl: './display-data-section.component.html'
})
export class DisplayDataSectionComponent {

  @Input() displayDataSections: DisplayDataSection[];
  @Input() newEntity: boolean;

  @Output() editClicked = new EventEmitter<any>();

  constructor() { }

  edit(editComponent: any) {
    this.editClicked.emit(editComponent);
  }
}
