import { NgModule, ErrorHandler } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PermissionAllDirective } from "./directives/permission-all.directive";
import { PermissionAnyDirective } from "./directives/permission-any.directive";
import { InfiniteScrollDirective } from "./directives/infinite-scroll.directive";
import { DisplayDataSectionComponent, ThousandSuffixPipe } from "./core";
import { JoinArrayPipe } from "./pipes/join-array.pipe";
import { TimeAgoPipe } from "./pipes/time-ago.pipe";
import { AddressPipe } from "./pipes/address-pipe";
import { LocationPipe } from "./pipes/location-pipe";
import { TruncateStringPipe } from "./pipes/truncate-string.pipe";
import { ErrorsHandler } from "./services/errors-handler";
import { ArrayWhereKeyReturnValue } from "./pipes/array-where-key-return-value.pipe";
import { RubblSharedModule } from "../rubbl-shared/rubbl-shared.module";
import { EventTrackerDirective } from "./directives/event-tracker-directive";
import { AppearDirective } from "./directives/appear.directive";
import { PwaInstallBannerComponent } from "./components/pwa-install-banner/pwa-install-banner.component";
import { FormsModule } from "../forms/forms.module";
import { VideoCaptureComponent } from "./components/video-capture/video-capture.component";
import { NgbProgressbarModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  declarations: [
    InfiniteScrollDirective,
    JoinArrayPipe,
    TimeAgoPipe,
    PermissionAllDirective,
    PermissionAnyDirective,
    ThousandSuffixPipe,
    AddressPipe,
    LocationPipe,
    TruncateStringPipe,
    ArrayWhereKeyReturnValue,
    EventTrackerDirective,
    AppearDirective,
    PwaInstallBannerComponent,
    DisplayDataSectionComponent,
    VideoCaptureComponent,
  ],
  imports: [CommonModule, RubblSharedModule, FormsModule, NgbProgressbarModule],
  exports: [
    InfiniteScrollDirective,
    JoinArrayPipe,
    TimeAgoPipe,
    PermissionAllDirective,
    PermissionAnyDirective,
    ThousandSuffixPipe,
    AddressPipe,
    LocationPipe,
    TruncateStringPipe,
    ArrayWhereKeyReturnValue,
    EventTrackerDirective,
    AppearDirective,
    PwaInstallBannerComponent,
    DisplayDataSectionComponent,
    VideoCaptureComponent,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler,
    },
  ],
})
export class CoreModule {}
