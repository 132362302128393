import { Component, Input } from "@angular/core";
import { EnvironmentConfig } from "../../../../core/core";
import { RentalRequestItem } from "../../models/rental-request-item.model";

declare var environment: EnvironmentConfig;

@Component({
  selector: "common-rental-item-header",
  templateUrl: "./rental-item-header.component.html",
})
export class RentalItemHeaderComponent {
  @Input() rentalItem: RentalRequestItem;

  baseImagePath: string = environment.baseImagePath;

  constructor() {}

  attachments() {
    if (this.rentalItem?.attachments.length) {
      return `| ${this.rentalItem.attachments.length} attachments`;
    }
  }

  attachmentsToolTip(): string {
    if (this.rentalItem && this.rentalItem.attachments) {
      const names = this.rentalItem.attachments.map((a) => {
        return a.title + (a.position != null ? " <span class='text-grey'>(" + a.position + ")</span>" : "");
      });
      return names.join("<br/>");
    }
    return "";
  }
}
