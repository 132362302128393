<ng-container *ngFor="let groupedItem of groupedRentalItems">
  <div class="d-flex py-4 cursor-pointer" (click)="groupedItem.show = !groupedItem.show">
    <div class="mr-auto">{{groupedItem.key}}s</div>
    <div class="mr-3">{{groupedItem.value.length}}</div>
    <app-rotating-caret [rotateCaret]="groupedItem.show" fontSize="13px"></app-rotating-caret>
  </div>

  <div *ngIf="groupedItem.show != true" [@fadeInOut]>
    <ng-container *ngFor="let rentalItem of groupedItem.value; last as isLast">
      <common-rental-item [rentalId]="rentalId" [rentalItem]="rentalItem"></common-rental-item>
      <div class="bb-light my-3" *ngIf="!isLast"></div>
    </ng-container>
  </div>

  <div class="bb-light"></div>
</ng-container>