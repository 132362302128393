import { InventoryItemSummary } from "../../../inventory/inventory";
import { RentalRequestUsage } from "./rental-request-usage.model";
import { Location } from "../../../core/core";
import { RentalConfiguration } from "./rental-configuration.model";
import { TransportationLoad } from "../../../transportation/transportation";

export class RentalItemState {
  completedDateTime?: Date;
  inventoryItem: InventoryItemSummary;
  rentalRequestId: string;
  rentalRequestNumber: string;
  renterAccountId: string;
  renterUserId: string;
  onRent: RentalItemStage;
  currentStage: RentalItemStage;
  location: Location;
  usage: RentalRequestUsage;
  estimatedAllottedUsage: number;
  stages: RentalItemStage[];
  id: string;
  partitionKey: string;
  configuration: RentalConfiguration;
  poNumber?: string;
  returnFulfillmentRequested?: boolean;
  transportationLoad: TransportationLoad;
  returnTransportationLoad: TransportationLoad;
  status: string;
}

export class RentalItemStage {
  name: string;
  code: string;
  associatedDocuments: any;
  status: string;
  startDateTime?: any;
  endDateTime?: any;
  timeInStage: string;
  daysInStage: number;
  startingLocation?: any;
  endingLocation?: any;
  notes: any[];
}

export enum RentalItemStageName {
  Confirmation = "Confirmation",
  Fulfillment = "Fulfillment",
  OnRent = "OnRent",
  ReturnFulfillment = "Return Fulfillment",
  Completed = "Completed"
}
